import axios from 'axios';
const Axioslib = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer 2RjTXIe4QifUppQtBDBY-F0T9p9vp2fhNKK0'
    }

});

export default Axioslib;