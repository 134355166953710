import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            localStorage.getItem('userDetails') 
                ?
                <Component {...props} />
                : 
                <Redirect to={{
                    pathname: '/',
                    state: { from: props.location }
                }} />
        )} />
    );
};


export default PrivateRoute;
