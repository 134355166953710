import React from "react";
import api from "../../service/api.service";
import { Modal } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from 'sweetalert2';
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export default class Language extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            languageDetails: [],
            languages: [],
            eventID: '',
            selectedLanguage: '',
            disableBtn: false,
            LanguageValidationMsg: "",
            languageId: ""
        }
    }

    componentDidMount() {
        this.splittedURL = this.props.location.pathname.split("/");
        if (this.splittedURL[2] !== "" && this.splittedURL[2] !== undefined) {
            this.eventID = this.splittedURL[2];
            this.getLanguages();
        }
    }

    getLanguages = () => {
        let params = {
            event_id: this.eventID
        }
        api.getLanguagesByEventID(params).then((data) => {
            if (data && data !== undefined && data !== null && data !== "" && data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                if (data.data.data !== "" && data.data.data !== undefined) {
                    this.setState({ languageDetails: data.data.data }, () => {
                        this.props.saveLanguageForInterpreter(this.state.languageDetails);
                    });

                }
            }
        });
    }

    openLanguageModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            selectedLanguage: '',
            disableBtn: false,
            LanguageValidationMsg: ""
        });
    }
    closeLanguageModal = () => {
        this.initialValues.language = "";
        this.initialValues.languageTitle = "";
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            selectedLanguage: '',
            disableBtn: false,
            LanguageValidationMsg: "",
            languageId: "",
            languageValueId: ""
        });
    }

    componentDidUpdate() {
        this.setState({ languages: this.props.languages });
        if (this.props.eventData._id !== undefined && this.props.activeTab === '4' && this.state.eventID !== this.props.eventData._id) {
            this.setState({ eventID: this.props.eventData._id });
        } else if (this.props.eventData._id === undefined && this.props.activeTab === '4') {
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please try again!"
            });
            this.props.stepToRoom();
        }
    }

    validationSchema = Yup.object().shape({
        language: Yup.string().trim().required("Language is required"),
        languageTitle: Yup.string().trim().required("Language Title is required")
    });

    submitLanguage = (values, { resetForm }) => {

        let languageExistingTitle = [];
        let languageExistingID = [];

        if (this.state.languageId !== undefined && this.state.languageId !== null && this.state.languageId !== "") {

            languageExistingTitle = this.state.languageDetails.filter(lng => (lng.title === values.languageTitle && lng.language_id !== this.state.languageValueId));
            languageExistingID = this.state.languageDetails.filter(lng => (lng.language_id === values.language.value && lng.language_id !== this.state.languageValueId));

        } else {

            languageExistingTitle = this.state.languageDetails.filter(lng => (lng.title === values.languageTitle));
            languageExistingID = this.state.languageDetails.filter(lng => (lng.language_id === values.language.value));

        }

        if (languageExistingTitle.length === 0 && languageExistingID.length === 0) {

            this.setState({ disableBtn: true });

            let languageDetails = {
                'title': values.languageTitle.trim(),
                'language_id': values.language.value,
                'event_id': this.state.eventID
            }

            if (this.state.languageId !== undefined && this.state.languageId !== null && this.state.languageId !== "") {

                languageDetails._id = this.state.languageId;

                api.updateLanguage(languageDetails).then((data) => {

                    this.setState({ disableBtn: false });

                    if (data && data !== undefined && data !== null && data !== "") {
                        if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                            if (data.data.status === true) {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Language updated successfully"
                                })

                                var index = this.state.languageDetails.findIndex(
                                    languages => languages._id === this.state.languageId
                                );

                                var languages = this.state.languageDetails;
                                languages[index] = data.data.result;

                                this.setState({ languageDetails: languages, languageId: "" }, () => {
                                    this.props.saveLanguageForInterpreter(this.state.languageDetails);
                                })
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again "
                                })
                            }
                            resetForm({});
                            this.closeLanguageModal();

                        } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                            Toast.fire({
                                icon: 'warning',
                                title: "Unauthorized Access"
                            })
                        } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                            Toast.fire({
                                icon: 'warning',
                                title: "Please revalidate the form and submit"
                            })
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "Something went wrong. Please try again!"
                            })
                        }
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "No response from the server. Please try again!"
                        })
                    }
                });

            } else {

                if (this.state.eventID !== "") {
                    api.addLanguage(languageDetails).then((data) => {
                        this.setState({ disableBtn: false });
                        if (data && data !== undefined && data !== null && data !== "") {
                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                if (data.data.status === true) {
                                    Toast.fire({
                                        icon: 'success',
                                        title: "Language added successfully"
                                    })
                                    this.setState({
                                        languageDetails: [...this.state.languageDetails, data.data.result]
                                    }, () => {
                                        this.props.saveLanguageForInterpreter(this.state.languageDetails);
                                    })
                                } else {
                                    Toast.fire({
                                        icon: 'warning',
                                        title: "Something went wrong. Please try again "
                                    })
                                }
                                resetForm({});
                                this.closeLanguageModal();

                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Unauthorized Access"
                                })
                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Please revalidate the form and submit"
                                })
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            }
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "No response from the server. Please try again!"
                            })
                        }
                    });
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please refresh page!"
                    });
                    this.closeLanguageModal();
                }
            }
        } else {

            if (languageExistingTitle.length !== 0 && languageExistingID.length !== 0) {
                this.setState({ LanguageValidationMsg: 'Already existing language and title' });
            } else if (languageExistingTitle.length !== 0) {
                this.setState({ LanguageValidationMsg: 'Already existing title' });
            } else if (languageExistingID.length !== 0) {
                this.setState({ LanguageValidationMsg: 'Already existing language' });
            }
        }

    }

    languageHandleChange = (language, setFieldValue) => {

        setFieldValue("language", language)
        this.setState({ selectedLanguage: language });
        setFieldValue("languageTitle", language.label)
        document.getElementById("languageTitle").focus();

    }

    editLanguage = (value) => {

        let language = ""
        if (this.state.languages.filter(lng => (lng.value === value.language_id)) !== undefined && this.state.languages.filter(lng => (lng.value === value.language_id))[0] !== undefined && this.state.languages.filter(lng => (lng.value === value.language_id))[0].label !== undefined) {
            language = this.state.languages.filter(lng => (lng.value === value.language_id))[0];

        }
        this.initialValues.language = language;
        this.initialValues.languageTitle = value.title;
        this.setState({ languageId: value._id, languageValueId: value.language_id });
        this.openLanguageModal();

    }

    deleteLanguage = (id, index) => {
        Swal.fire({
            title: 'Delete Language',
            text: "Are you sure you want to delete? This will delete the interpreters who are assigned to this language",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes, Proceed",
            cancelButtonText: 'No, cancel',
            confirmButtonColor: '#00d2a5',
            customClass: {
                confirmButton: 'green-bg-white-f-btn'
            },
            focusConfirm: false,
            focusCancel: true
        }).then((result) => {
            if (result.value) {
                try {
                    api.deleteLanguage(id).then((data) => {
                        if (data && data !== undefined && data !== null && data !== "") {
                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Language deleted successfully"
                                })
                                this.state.languageDetails.splice(index, 1);
                                this.setState({ languageDetails: [...this.state.languageDetails] }, () => {
                                    this.props.saveLanguageForInterpreter(this.state.languageDetails);
                                });

                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Unauthorized Access"
                                })
                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Please revalidate the form and submit"
                                })
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            }
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "No response from the server. Please try again!"
                            })
                        }
                    });
                } catch (e) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            }
        })

    }

    checkLanguageValidation = () => {
        if (this.state.languageDetails.length > 0) {
            if (this.state.languageDetails.length >= 2) {
                this.props.stepToAgenda();
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: "Please add minimum two language"
                })
            }
        } else {
            this.props.stepToAgenda();
        }

    }

    initialValues = {
        language: '',
        languageTitle: ""
    }

    languageForm = ({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        handleReset,
        setFieldValue,
    }) => {
        return (
            <React.Fragment>
                <Form className="form-signin" onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-end popup-close-btn" onClick={(e) => { this.closeLanguageModal(); handleReset(e); }}>&times;</div>
                    <div className="lang-header mt10">Add Language</div>
                    <div className="lang-blk">
                        <div className="lang-wraper">
                            <div className="room-name-label">Language<span className="star-imp">*</span></div>
                            <Select
                                name="language"
                                value={values.language}
                                options={this.state.languages}
                                noOptionsMessage={() => "No Languages found"}
                                onChange={(e) => this.languageHandleChange(e, setFieldValue)}
                                onBlur={handleBlur}
                                style={{ display: 'block' }}
                            />
                            <ErrorMessage name="language" component="div" className="validtxt_msg" />
                        </div>
                        <div className="lang-wraper">
                            <div className="room-name-label">Title<span className="star-imp">*</span></div>
                            <Field
                                type="text"
                                maxLength="100"
                                name="languageTitle"
                                id="languageTitle"
                                className="form-input modal-form-input"
                                value={values.languageTitle}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="off"
                            />
                        </div>
                        {/* <div name="languageTitle" className="validtxt_msg">{this.state.LanguagetitleValidationMsg}</div> */}
                        <ErrorMessage name="languageTitle" component="div" className="validtxt_msg" />
                        <div className="lang-wraper"><div className="room-name-label">NB: Title is editable</div></div>
                    </div>

                    {this.state.LanguageValidationMsg !== undefined && this.state.LanguageValidationMsg !== null && this.state.LanguageValidationMsg !== "" && (
                        <div className="validtxt_msg">{this.state.LanguageValidationMsg}</div>
                    )}

                    <div className="d-flex justify-content-center modal-btn-blk room-btn-blk">
                        <button type="button" className="modal-cancel-btn" onClick={(e) => { this.closeLanguageModal(); handleReset(e); }}>Cancel</button>
                        {
                            this.state.languageId !== undefined && this.state.languageId !== null && this.state.languageId !== ""
                                ?
                                <button type="submit" className="modal-save-btn" disabled={this.state.disableBtn}>
                                    {this.state.disableBtn === true ? 'Updating...' : 'Update'}
                                    {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                </button>
                                :
                                <button type="submit" className="modal-save-btn" disabled={this.state.disableBtn}>
                                    {this.state.disableBtn === true ? 'Saving...' : 'Save'}
                                    {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                </button>
                        }
                    </div>
                </Form>
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <div className="lang-schedule-blk">

                    <div className="add-room-wrapper" onClick={() => this.openLanguageModal()}>
                        <div className="d-flex align-items-center add-room-txt"><span className="add-room-plus">+</span>Add Language</div>
                    </div>

                    {this.state.languageDetails.map((value, key) => {
                        let language = ""
                        if (this.state.languages.filter(lng => (lng.value === value.language_id)) !== undefined &&
                            this.state.languages.filter(lng => (lng.value === value.language_id))[0] !== undefined
                            && this.state.languages.filter(lng => (lng.value === value.language_id))[0].label !== undefined) {
                            language = this.state.languages.filter(lng => (lng.value === value.language_id))[0].label

                        }
                        return (
                            <div className="d-flex lang-row" key={key}>
                                <div className="lang-col-wrapper">
                                    <div className="lang-container">
                                        <label className="formgrp-label">Language<span>*</span></label>
                                        <div className="form-input lang-title" placeholder="">
                                            {language}
                                        </div>
                                    </div>
                                    <div className="lang-container">
                                        <label className="formgrp-label">Title<span>*</span></label>
                                        <div className="form-input lang-title" placeholder=""> {value.title}</div>
                                    </div>
                                    <div className="agenda-del-blk">
                                        <div className="d-flex align-items-center justify-content-center room-del-btn" onClick={() => this.editLanguage(value)}><img src={require("../../img/edit-pencil.svg")} alt="edit language" /></div>
                                    </div>
                                    <div className="agenda-del-blk">
                                        <div className="d-flex align-items-center justify-content-center room-del-btn" onClick={() => this.deleteLanguage(value._id, key)}><img src={require("../../img/trash.svg")} alt="delete language" /></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
                <div className="d-flex align-items-center btn-acn-blk">
                    <button type="button" onClick={() => this.props.stepToRoom()} className="prev-btn">Prev</button>
                    <button type="button" onClick={() => this.checkLanguageValidation()} className="next-btn">Next</button>
                </div>
                <Modal isOpen={this.state.isModalOpen} fade={true} centered className={'single-modal-content'}>
                    <Formik
                        initialValues={this.initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.submitLanguage}
                    >
                        {this.languageForm}
                    </Formik>
                </Modal>
            </React.Fragment>
        );
    }
}