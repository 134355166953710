import React from "react";
import { Modal } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "../../service/api.service";
import Swal from 'sweetalert2';
import ClipLoader from "react-spinners/ClipLoader";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export default class Room extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            roomDetails: [],
            eventID: '',
            disableBtn: false,
        }
        this.eventID = "";
    }

    componentDidMount() {
        this.splittedURL = this.props.location.pathname.split("/");
        if (this.splittedURL[2] !== "" && this.splittedURL[2] !== undefined) {
            this.eventID = this.splittedURL[2];
            this.getRoomsByEventID();
        }
    }

    getRoomsByEventID = () => {
        let params = {
            event_id: this.eventID
        }
        api.getRoomsByEventID(params).then((data) => {

            if (data && data !== undefined && data !== null && data !== "" && data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                if (data.data.data !== "" && data.data.data !== undefined) {
                    this.setState({ roomDetails: data.data.data }, () => {
                        this.props.saveRooms(this.state.roomDetails);
                    });

                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "Event not available. Please try again!"
                    })
                }
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. Please try again!"
                })
            }
        });
    }

    openRoomModal = () => {
        if (this.state.eventID !== "") {
            this.setState({ isModalOpen: true });
        } else {
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please try again!"
            });
        }
    }

    closeRoomModal = () => {

        this.initialValues.roomName = "";
        this.setState({ roomId: "", isModalOpen: false })

    }

    componentDidUpdate() {
        if (this.props.eventData._id !== undefined && this.props.activeTab === '3' && this.state.eventID !== this.props.eventData._id) {
            this.setState({ eventID: this.props.eventData._id });
        } else if (this.props.eventData._id === undefined && this.props.activeTab === '3') {
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please try again!"
            });
            this.props.stepToEventFile();
        }
    }

    initialValues = {
        roomName: ""
    }

    validationSchema = Yup.object().shape({
        roomName: Yup.string().trim().required("Room Name is required")
    });

    submitRoom = async (values, { resetForm }) => {

        let roomDetails = {
            'name': values.roomName.trim(),
            'event_id': this.state.eventID
        }

        if (this.state.roomId !== undefined && this.state.roomId !== null && this.state.roomId !== "") {

            roomDetails._id = this.state.roomId;
            this.setState({ disableBtn: true });

            api.updateRoom(roomDetails).then((data) => {

                this.setState({ disableBtn: false });

                try {
                    if (data && data !== undefined && data !== null && data !== "") {
                        if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                            Toast.fire({
                                icon: 'success',
                                title: "Room updated successfully"
                            })

                            var index = this.state.roomDetails.findIndex(
                                rooms => rooms._id === this.state.roomId
                            );

                            var rooms = this.state.roomDetails;
                            rooms[index] = data.data.result;

                            this.setState({ roomDetails: rooms, roomId: "" }, () => {
                                this.props.saveRooms(this.state.roomDetails);
                            })

                            resetForm({});
                            this.closeRoomModal();

                        } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                            Toast.fire({
                                icon: 'warning',
                                title: "Unauthorized Access"
                            })
                        } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                            Toast.fire({
                                icon: 'warning',
                                title: "Please revalidate the form and submit"
                            })
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "Something went wrong. Please try again!"
                            })
                        }
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "No response from the server. Please try again!"
                        })
                    }
                } catch(e) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            });

        } else {

            if (this.state.eventID !== "") {
                
                this.setState({ disableBtn: true });

                api.addRoom(roomDetails).then((data) => {

                    this.setState({disableBtn: false});
                    if (data && data !== undefined && data !== null && data !== "") {
                        if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {                        
                            Toast.fire({
                                icon: 'success',
                                title: "Room added successfully"
                            })
                            this.setState({
                                roomDetails: [...this.state.roomDetails, data.data.result]
                            }, () => {
                                this.props.saveRooms(this.state.roomDetails);
                            })

                            resetForm({});
                            this.closeRoomModal();

                        } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                            Toast.fire({
                                icon: 'warning',
                                title: "Unauthorized Access"
                            })
                        } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                            Toast.fire({
                                icon: 'warning',
                                title: "Please revalidate the form and submit"
                            })
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "Something went wrong. Please try again!"
                            })
                        }
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "No response from the server. Please try again!"
                        })
                    }

                });
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. Please refresh page!"
                });
                this.closeRoomModal();
            }
        }
    }

    editRoom = (val) => {

        this.initialValues.roomName = val.name;        
        this.setState({ roomId: val._id });
        this.openRoomModal();

    }


    deleteRoom = (id, index) => {
        Swal.fire({
            title: 'Delete Room',
            text: "Are you sure you want to delete? This will delete the session corresponding to this room and interpreters corresponding to the session",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes, Proceed",
            cancelButtonText: 'No, cancel',
            confirmButtonColor: '#00d2a5',
            customClass: {
                confirmButton: 'green-bg-white-f-btn'
            },
            focusConfirm: false,
            focusCancel: true
        }).then((result) => {
            if (result.value) {
                try {
                    api.deleteRoom(id).then((data) => {
                        if (data && data !== undefined && data !== null && data !== "") {
                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Room deleted successfully"
                                })
                                this.state.roomDetails.splice(index, 1);
                                this.setState({ roomDetails: [...this.state.roomDetails] }, () => {
                                    this.props.saveRooms(this.state.roomDetails);
                                });

                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Unauthorized Access"
                                })
                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Please revalidate the form and submit"
                                })
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            }
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "No response from the server. Please try again!"
                            })
                        }
                    });
                } catch (e) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            }
        })
    }

    setroomName = (e) => {
        console.log(e)
    }

    roomForm = ({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        handleReset,
        setFieldValue,
    }) => {
        return (
            <React.Fragment>
                <Form className="form-signin" onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-end popup-close-btn" onClick={(e) => {this.closeRoomModal(e); handleReset(e);}}>&times;</div>
                    <div className="room-add-wrapper">
                        <span className="room-name-label">Enter Room Name</span>
                        <Field type="text" maxLength="100" name="roomName" id="roomName" className="form-input modal-form-input" />
                        <ErrorMessage name="roomName" component="div" className="validtxt_msg" />
                    </div>
                    <div className="d-flex justify-content-center modal-btn-blk room-btn-blk add-room-popup-btn">
                        <button type="button" className="modal-cancel-btn" onClick={(e) => { this.closeRoomModal(); handleReset(e); }}>Cancel</button>
                        {
                            this.state.roomId !== undefined && this.state.roomId !== null && this.state.roomId !== ""
                            ?
                            <button type="submit" className="modal-save-btn" disabled={this.state.disableBtn}>
                                {this.state.disableBtn === true ? 'Updating...' : 'Update'}
                                {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                            </button>
                            :
                            <button type="submit" className="modal-save-btn" disabled={this.state.disableBtn}>
                                {this.state.disableBtn === true ? 'Saving...' : 'Save'}
                                {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                            </button>
                        }                        
                    </div>
                </Form>
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <div className="room-schedl-blk">

                    <div className="add-room-blk">
                        <div className="add-room-wrapper" onClick={() => this.openRoomModal()}>
                            <div className="d-flex align-items-center add-room-txt" ><span className="add-room-plus">+</span>Add Room</div>
                        </div>
                    </div>
                    
                    <div className="room-schedul-wrapper">
                        {this.state.roomDetails.map((value, key) => {
                            return (
                                <div className="d-flex room-schedl-container" key={key}>
                                    <div className="formgrp-txt-wrapper room-name-wrap">
                                        <div className="form-input">{value.name}</div>
                                    </div>
                                    {this.eventID !== undefined && this.eventID !== null && this.eventID !== "" && (
                                        <div className="d-flex align-items-center justify-content-center room-del-btn" onClick={() => this.editRoom(value)}><img src={require("../../img/edit-pencil.svg")} alt="edit room" /></div>
                                    )}
                                    <div className="d-flex align-items-center justify-content-center room-del-btn" onClick={() => this.deleteRoom(value._id, key)}><img src={require("../../img/trash.svg")} alt="deleteImg" /></div>
                                </div>
                            )
                        })}
                    </div>
                    
                </div>
                <div className="d-flex align-items-center btn-acn-blk">
                    <button type="button" onClick={() => this.props.stepToEventFile()} className="prev-btn">Prev</button>
                    <button type="button" disabled={this.state.roomDetails.length > 0 ? false : true} onClick={() => this.props.stepToLanguage()} className="next-btn">Next</button>
                </div>


                <Modal isOpen={this.state.isModalOpen} fade={true} centered className={'single-modal-content'}>
                    <Formik
                        initialValues={this.initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.submitRoom}
                    >
                        {this.roomForm}
                    </Formik>                    
                </Modal>
            </React.Fragment>
        );
    }
}
