import React from "react";
import Swal from 'sweetalert2';
import ClipLoader from "react-spinners/ClipLoader";
import apiEventService from "../../service/api.eventService";
import Axioslib from "../../lib/Axioslib";
import { Formik, Form } from "formik";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export default class Eventfiles extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            eventFiles: [],
            selectedFile: "",
            selectedFilePPT: "",
            loaded: 0,
            loadedPPT: 0,
            errorMsg: '',
            errorUrlMsg: "",
            disableNextBtn: false
        }
        this.eventID = "";
    }

    componentDidMount() {
        this.splittedURL = this.props.location.pathname.split("/");
        if (this.splittedURL[2] !== "" && this.splittedURL[2] !== undefined) {
            this.eventID = this.splittedURL[2];
            this.getEventFilesByEventID();
        }
    }

    componentDidUpdate() {
        if (this.props.eventData._id !== undefined && this.props.activeTab === '2' && this.state.eventID !== this.props.eventData._id) {
            // this.setState({ eventID: this.props.eventData._id });
            this.eventID = this.props.eventData._id;
        } else if (this.props.eventData._id === undefined && this.props.activeTab === '2') {
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please try again!"
            });
            this.props.stepToEvent();
        }
        this.initialValues.streamOut = (this.props.eventData.streamOut !== undefined && this.props.eventData.streamOut !== null && this.props.eventData.streamOut !== "") ? this.props.eventData.streamOut : false;
        this.initialValues.signLanguageMode = (this.props.eventData.signLanguageMode !== undefined && this.props.eventData.signLanguageMode !== null && this.props.eventData.signLanguageMode !== "") ? this.props.eventData.signLanguageMode : false;
        this.initialValues.recording = (this.props.eventData.recording !== undefined && this.props.eventData.recording !== null && this.props.eventData.recording !== "") ? this.props.eventData.recording : false;
        this.initialValues.speakerUserList = (this.props.eventData.speakerUserList !== undefined && this.props.eventData.speakerUserList !== null && this.props.eventData.speakerUserList !== "") ? this.props.eventData.speakerUserList : false;
        this.initialValues.enableDownloadPpt = (this.props.eventData.enableDownloadPpt !== undefined && this.props.eventData.enableDownloadPpt !== null && this.props.eventData.enableDownloadPpt !== "") ? this.props.eventData.enableDownloadPpt : false;
        this.initialValues.enableHighResolution = (this.props.eventData.enableHighResolution !== undefined && this.props.eventData.enableHighResolution !== null && this.props.eventData.enableHighResolution !== "") ? this.props.eventData.enableHighResolution : false;
        this.initialValues.disablePublicCL = (this.props.eventData.disablePublicCL !== undefined && this.props.eventData.disablePublicCL !== null && this.props.eventData.disablePublicCL !== "") ? this.props.eventData.disablePublicCL : false;
        this.initialValues.disablePublicCS = (this.props.eventData.disablePublicCS !== undefined && this.props.eventData.disablePublicCS !== null && this.props.eventData.disablePublicCS !== "") ? this.props.eventData.disablePublicCS : false;
        this.initialValues.disablePrivateCL = (this.props.eventData.disablePrivateCL !== undefined && this.props.eventData.disablePrivateCL !== null && this.props.eventData.disablePrivateCL !== "") ? this.props.eventData.disablePrivateCL : false;
        this.initialValues.disablePrivateCS = (this.props.eventData.disablePrivateCS !== undefined && this.props.eventData.disablePrivateCS !== null && this.props.eventData.disablePrivateCS !== "") ? this.props.eventData.disablePrivateCS : false;
        this.initialValues.disableChat = (this.props.eventData.disableChat !== undefined && this.props.eventData.disableChat !== null && this.props.eventData.disableChat !== "") ? this.props.eventData.disableChat : false;
        this.initialValues.enablePopupNot = (this.props.eventData.enablePopupNot !== undefined && this.props.eventData.enablePopupNot !== null && this.props.eventData.enablePopupNot !== "") ? this.props.eventData.enablePopupNot : false;
        this.initialValues.enableSecondaryModerator = (this.props.eventData.enableSecondaryModerator !== undefined && this.props.eventData.enableSecondaryModerator !== null && this.props.eventData.enableSecondaryModerator !== "") ? this.props.eventData.enableSecondaryModerator : false;
        this.initialValues.enableMasterSpeaker = (this.props.eventData.enableMasterSpeaker !== undefined && this.props.eventData.enableMasterSpeaker !== null && this.props.eventData.enableMasterSpeaker !== "") ? this.props.eventData.enableMasterSpeaker : false;
        
        
    }



    getEventFilesByEventID = () => {
        let params = {
            event_id: this.eventID
        }
        apiEventService.getEventFilesByEventID(params).then((data) => {
            if (data && data !== undefined && data !== null && data !== "" && data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                if (data.data.data !== "" && data.data.data !== undefined) {
                    this.setState({ eventFiles: data.data.data });
                }
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. Please try again!"
                })
            }
        });
    }

    onChangeHandler = (event) => {

        if (this.state.selectedFile !== "") {
            Toast.fire({
                icon: 'warning',
                title: "Please wait. File is being uploading"
            })
        } else {
            this.setState({ errorMsg: '' });
            let types = [
                "video/mp4",
                "audio/mp3",
                "audio/mpeg"
            ];

            const selectedFile = event.target.files[0];

            if (selectedFile !== "") {
                if (types.every(type => selectedFile.type !== type)) {
                    this.setState({
                        errorMsg: selectedFile.name + " File format not supported"
                    });
                } else if (selectedFile['size'] > 524288000) {
                    this.setState({ errorMsg: '500 MB file size exceeds' })
                } else {
                    this.setState({
                        selectedFile: selectedFile,
                        loaded: 0
                    }, () => {
                        const data = new FormData();
                        data.append('event_file', this.state.selectedFile);
                        try {
                            Axioslib.post("event/upload_file/" + this.eventID, data, {
                                onUploadProgress: ProgressEvent => {
                                    this.setState({
                                        loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                                    })
                                },
                            }).then(response => {

                                document.getElementById("uploadeventfile").value = "";

                                if (response && response !== undefined && response !== null && response !== "" && response.status && response.status !== undefined && response.status !== null && response.status === 200) {
                                    
                                    if (response.data.status !== "" && response.data.status !== undefined && response.data.status === "success") {
                                        Toast.fire({
                                            icon: 'success',
                                            title: "Event file uploaded"
                                        })
                                        let filename = this.state.selectedFile.name;
                                        let saveEventFiles = {
                                            title: filename,
                                            url: response.data.result,
                                            type: this.state.selectedFile.type,
                                            event_id: this.eventID
                                        }
                                        this.saveEventFileDetails(saveEventFiles, "audiovideo");

                                    } else if (response.data.status !== "" && response.data.status !== undefined && response.data.status === "failed") {
                                        this.setState({ selectedFile: "" });
                                        Toast.fire({
                                            icon: 'warning',
                                            title: response.data.message
                                        })
                                    } else {
                                        this.setState({ selectedFile: "" });
                                        Toast.fire({
                                            icon: 'warning',
                                            title: "Upload failed. Please try again!"
                                        })
                                    }
                                } else {
                                    this.setState({ selectedFile: "" });
                                    Toast.fire({
                                        icon: 'warning',
                                        title: "Upload failed. Please try again!"
                                    })
                                }
                            }, error => {

                                document.getElementById("uploadeventfile").value = "";

                                this.setState({ selectedFile: "" });
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            });
                        } catch (e) {

                            document.getElementById("uploadeventfile").value = "";

                            this.setState({ selectedFile: "" });
                            Toast.fire({
                                icon: 'warning',
                                title: "Something went wrong. Please try again!"
                            })

                        }
                    });
                }
            } else {
                this.setState({
                    errorMsg: "Please upload file"
                })
            }
        }

    }

    onPPTChangeHandler = (event) => {

        if (this.state.selectedFilePPT !== "") {
            Toast.fire({
                icon: 'warning',
                title: "Please wait. File is being uploading"
            })
        } else {

            this.setState({ errorMsgPPT: '' });
            let types = [
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/vnd.ms-powerpoint",
                "application/powerpoint",
                "application/mspowerpoint",
                "application/x-mspowerpoint"
            ];

            console.log("event.target.files ===> ", event.target.files)

            const selectedFilePPT = event.target.files[0];

            console.log("selectedFilePPT ===> ", selectedFilePPT)

            if (selectedFilePPT !== "") {
                if (types.every(type => selectedFilePPT.type !== type)) {
                    this.setState({
                        errorMsgPPT: selectedFilePPT.name + " File format not supported"
                    });
                } else if (selectedFilePPT['size'] > 52428800) {
                    this.setState({ errorMsgPPT: '50 MB file size exceeds' })
                } else {
                    this.setState({
                        selectedFilePPT: selectedFilePPT,
                        loadedPPT: 0
                    }, () => {
                        const data = new FormData();
                        data.append('event_file', this.state.selectedFilePPT);
                        try {
                            Axioslib.post("event/upload_file/" + this.eventID, data, {
                                onUploadProgress: ProgressEvent => {
                                    this.setState({
                                        loadedPPT: (ProgressEvent.loaded / ProgressEvent.total * 100),
                                    })
                                },
                            }).then(response => {

                                document.getElementById("uploadeventfile_ppt").value = "";

                                if (response && response !== undefined && response !== null && response !== "" && response.status && response.status !== undefined && response.status !== null && response.status === 200) {

                                    if (response.data.status !== "" && response.data.status !== undefined && response.data.status === "success") {

                                        // let filename = this.state.selectedFilePPT.name;
                                        if (response.data.result.status === "success") {
                                            Toast.fire({
                                                icon: 'success',
                                                title: "Event file uploaded and Conversion is on progress"
                                            })
                                            this.setState({ selectedFilePPT: "", eventFiles: [response.data.result.result, ...this.state.eventFiles] });
                                        } else {
                                            this.setState({ selectedFilePPT: "" });
                                            Toast.fire({
                                                icon: 'warning',
                                                title: "Something went wrong. Please try again!"
                                            })
                                        }

                                        // let saveEventFiles = {
                                        //     title: filename,
                                        //     url: response.data.result,
                                        //     type: "ppt",
                                        //     event_id: this.eventID
                                        // }
                                        // this.saveEventFileDetails(saveEventFiles, "ppt");
                                        // this.getEventFilesByEventID();


                                    } else if (response.data.status !== "" && response.data.status !== undefined && response.data.status === "failed") {
                                        this.setState({ selectedFilePPT: "" });
                                        Toast.fire({
                                            icon: 'warning',
                                            title: response.data.message
                                        })
                                    } else {
                                        this.setState({ selectedFilePPT: "" });
                                        Toast.fire({
                                            icon: 'warning',
                                            title: "Upload failed. Please try again!"
                                        })
                                    }
                                } else {
                                    this.setState({ selectedFilePPT: "" });
                                    Toast.fire({
                                        icon: 'warning',
                                        title: "Upload failed. Please try again!"
                                    })
                                }
                            }, error => {

                                document.getElementById("uploadeventfile_ppt").value = "";

                                this.setState({ selectedFilePPT: "" });
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            }).catch(err => {
                                console.log('errrr', err);

                            });
                        } catch (e) {

                            document.getElementById("uploadeventfile_ppt").value = "";

                            this.setState({ selectedFilePPT: "" });
                            Toast.fire({
                                icon: 'warning',
                                title: "Something went wrong. Please try again!"
                            })

                        }
                    });
                }
            } else {
                this.setState({
                    errorMsgPPT: "Please upload file"
                })
            }
        }

    }


    saveEventFileDetails = (saveEventFiles, browsetype) => {
        if (saveEventFiles !== "" && saveEventFiles !== undefined) {
            apiEventService.saveEventFileDetails(saveEventFiles).then((data) => {

                if (data && data !== undefined && data !== null && data !== "" && data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                    if (data.data.status !== "" && data.data.status !== undefined && data.data.status === "success") {

                        if(browsetype === "audiovideo") {
                            this.setState({ selectedFile: "", eventFiles: [data.data.result, ...this.state.eventFiles] });
                        } else if(browsetype === "ppt") {
                            this.setState({ selectedFilePPT: "", eventFiles: [data.data.result, ...this.state.eventFiles] });
                        } else if(browsetype === "url") {
                            this.setState({ disableBtn: false, eventFiles: [data.data.result, ...this.state.eventFiles] });
                        }
                        
                        document.getElementById("mediaInputUrl").value = "";
                        Toast.fire({
                            icon: 'success',
                            title: "Event file saved"
                        })
                    } else if (data.data.status !== "" && data.data.status !== undefined && data.data.status === "error") {

                        document.getElementById("mediaInputUrl").value = "";

                        if (browsetype === "audiovideo") {
                            this.setState({ selectedFile: "" });
                        } else if (browsetype === "ppt") {
                            this.setState({ selectedFilePPT: "" });
                        } else if (browsetype === "url") {
                            this.setState({ disableBtn: false });
                        }

                        Toast.fire({
                            icon: 'warning',
                            title: (data.data.message.message !== undefined && data.data.message.message !== null && data.data.message.message !== "") ? data.data.message.message : "Please enter valid URL"
                        })
                    } else {

                        document.getElementById("mediaInputUrl").value = "";

                        if (browsetype === "audiovideo") {
                            this.setState({ selectedFile: "" });
                        } else if (browsetype === "ppt") {
                            this.setState({ selectedFilePPT: "" });
                        } else if (browsetype === "url") {
                            this.setState({ disableBtn: false });
                        }

                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong in Event file save"
                        })
                    }

                } else {

                    document.getElementById("mediaInputUrl").value = "";

                    if (browsetype === "audiovideo") {
                        this.setState({ selectedFile: "" });
                    } else if (browsetype === "ppt") {
                        this.setState({ selectedFilePPT: "" });
                    } else if (browsetype === "url") {
                        this.setState({ disableBtn: false });
                    }

                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            })
        }

    }

    deleteEventFile = (id, index) => {
        Swal.fire({
            title: 'Delete Event File',
            text: "Are you sure you want to delete?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes, Proceed",
            cancelButtonText: 'No, cancel',
            confirmButtonColor: '#00d2a5',
            customClass: {
                confirmButton: 'green-bg-white-f-btn'
            },
            focusConfirm: false,
            focusCancel: true
        }).then((result) => {
            if (result.value) {
                try {
                    let eventfileData = {
                        "event_file_id": id
                    }
                    apiEventService.deleteEventFiles(eventfileData).then((data) => {
                        if (data && data !== undefined && data !== null && data !== "") {
                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Event file deleted successfully"
                                })
                                this.state.eventFiles.splice(index, 1);
                                this.setState({ eventFiles: [...this.state.eventFiles] });

                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Unauthorized Access"
                                })
                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Please revalidate the form and submit"
                                })
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            }
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "No response from the server. Please try again!"
                            })
                        }
                    });
                } catch (e) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            }
        })
    }

    addFile = () => {
        this.setState({ disableBtn: false, errorUrlMsg: "" });
        let addMediaUrl = document.getElementById("mediaInputUrl").value;
        if (addMediaUrl !== "") {
            let title = addMediaUrl.substring(addMediaUrl.lastIndexOf('/') + 1)
            let saveEventFiles = {
                title: title !== "" ? title : 'Sample Document',
                url: addMediaUrl,
                type: "video",
                event_id: this.eventID
            }
            this.setState({ disableBtn: true, errorUrlMsg: "" });
            this.saveEventFileDetails(saveEventFiles, "url");
        } else {
            this.setState({ errorUrlMsg: 'Please enter a url' });
        }
    }
    initialValues = {
        streamOut: false,
        signLanguageMode: false,
        recording: false,
        speakerUserList: false,
        enableDownloadPpt: false,
        enableHighResolution: false,
        enableSecondaryModerator: false,
        enableMasterSpeaker:false,
        disablePublicCL: false,
        disablePublicCS: false,
        disablePrivateCL: false,
        disablePrivateCS: false,
        enablePopupNot: false,
        disableChat: false
    }

    goToPrevStep = () => {

        if (this.state.selectedFile !== "" || this.state.selectedFilePPT !== "") {
            Toast.fire({
                icon: 'warning',
                title: "Please wait. File is being uploading"
            });
        } else {
            this.props.stepToEvent()
        }
    }

    submitEventAdditionServices = (values) => {

        if(this.state.selectedFile !== "" || this.state.selectedFilePPT !== "") {
            Toast.fire({
                icon: 'warning',
                title: "Please wait. File is being uploading"
            });
        } else {
            let additionalService = {
                'streamOut': values.streamOut,
                'signLanguageMode': values.signLanguageMode,
                'recording': values.recording,
                'speakerUserList': values.speakerUserList,
                'enableDownloadPpt':values.enableDownloadPpt,
                'enableHighResolution':values.enableHighResolution,
                'enableSecondaryModerator':values.enableSecondaryModerator,
                'enableMasterSpeaker':values.enableMasterSpeaker,
                'disablePublicCL':values.disablePublicCL,
                'disablePublicCS':values.disablePublicCS,
                'disablePrivateCL':values.disablePrivateCL,
                'disablePrivateCS':values.disablePrivateCS,
                'enablePopupNot':values.enablePopupNot,
                
                'disableChat':values.disableChat,
                '_id': this.eventID
            }
            this.setState({ disableNextBtn: true });
            apiEventService.updateEventDetails(additionalService).then((data) => {
                this.setState({ disableNextBtn: false });

                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        if (data.data.status === true) {
                            this.props.saveEventDetails(data.data);
                        }
                        Toast.fire({
                            icon: 'success',
                            title: "Additional Services updated successfully"
                        });
                        this.props.stepToRoom();
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }
            });
        }
    }

    additionServices = ({
        values,
        handleSubmit,
        setFieldValue
    }) => {
        return (
            <Form onSubmit={handleSubmit}>

                <div className="event-detail-blk">
                    <div className="additional-sevice-blk floatleft">
                        <div className="half-box">
                            <div className="event-main-caption">Additional Services</div>
                            <div className="services-choosing-blk">
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="recording"
                                            value={values.recording}
                                            checked={values.recording === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('recording', !values.recording); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Enable Recording</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="signLanguageMode"
                                            value={values.signLanguageMode}
                                            checked={values.signLanguageMode === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('signLanguageMode', !values.signLanguageMode); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Enable Sign Language Mode</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="streamOut"
                                            value={values.streamOut}
                                            checked={values.streamOut === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('streamOut', !values.streamOut); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Stream Out</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="speakerUserList"
                                            value={values.speakerUserList}
                                            checked={values.speakerUserList === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('speakerUserList', !values.speakerUserList); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Allow Userlist For Speakers</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="enableDownloadPpt"
                                            value={values.enableDownloadPpt}
                                            checked={values.enableDownloadPpt === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('enableDownloadPpt', !values.enableDownloadPpt); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Enable Downloading Of PPT</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="enableHighResolution"
                                            value={values.enableHighResolution}
                                            checked={values.enableHighResolution === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('enableHighResolution', !values.enableHighResolution); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Enable High Resolution Webcam</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="enableSecondaryModerator"
                                            value={values.enableSecondaryModerator}
                                            checked={values.enableSecondaryModerator === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('enableSecondaryModerator', !values.enableSecondaryModerator); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Enable Secondary Moderator</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="enableMasterSpeaker"
                                            value={values.enableMasterSpeaker}
                                            checked={values.enableMasterSpeaker === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('enableMasterSpeaker', !values.enableMasterSpeaker); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Enable Master Speaker</div>
                                </div>
                            </div>
                        </div>
                        <div className="half-box">
                            <div className="event-main-caption">Chat Settings</div>
                            <div className="services-choosing-blk">
                                <div className="d-flex eventfile-checkbox-wrapper">
                                        <label className="custom-checkbox">
                                            <input type="checkbox"
                                                name="disableChat"
                                                value={values.disableChat}
                                                checked={values.disableChat === true ? "checked" : ""}
                                                onChange={() => { setFieldValue('disableChat', !values.disableChat); }} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <div className="checkbox-label">Disable Chat ( Both private and public chat )</div>
                                </div>
                                <span className={values.disableChat ? 'hide':''}>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="disablePublicCL"
                                            value={values.disablePublicCL}
                                            checked={values.disablePublicCL === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('disablePublicCL', !values.disablePublicCL); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Disable Public Chat For Listeners</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="disablePublicCS"
                                            value={values.disablePublicCS}
                                            checked={values.disablePublicCS === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('disablePublicCS', !values.disablePublicCS); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Disable Public Chat For Speakers</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="disablePrivateCL"
                                            value={values.disablePrivateCL}
                                            checked={values.disablePrivateCL === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('disablePrivateCL', !values.disablePrivateCL); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Disable Private Chat For Listeners</div>
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="disablePrivateCS"
                                            value={values.disablePrivateCS}
                                            checked={values.disablePrivateCS === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('disablePrivateCS', !values.disablePrivateCS); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Disable Private Chat For Speakers</div> 
                                </div>
                                <div className="d-flex eventfile-checkbox-wrapper">
                                    <label className="custom-checkbox">
                                        <input type="checkbox"
                                            name="enablePopupNot"
                                            value={values.enablePopupNot}
                                            checked={values.enablePopupNot === true ? "checked" : ""}
                                            onChange={() => { setFieldValue('enablePopupNot', !values.enablePopupNot); }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <div className="checkbox-label">Enable Popup Notification For Chat</div>
                                </div>
                                </span>
                            </div>
                        </div>
                      
                    </div>
                    <div className="media-upload-blk floatleft full-width">
                        <div className="event-main-caption">Upload & Share Video</div>
                        <div className="vdo-ppt-upload-blk">
                            <div className="vdo-upload-blk">
                                <div className="upload-vdo-caption">Upload File</div>
                                <div className="d-flex align-items-center justify-content-center vdo-file-uploader" style={{ "flexDirection": "column" }}>
                                    <label className="vdo-file-upload-btn">
                                        <input type="file" id="uploadeventfile" className="d-none" onChange={this.onChangeHandler} />Browse
                                    </label>
                                    <div className="notification-area" style={{ "marginTop": "1rem" }}>
                                        <div className="notification-text">Supported formats: <span>mp4, mp3</span></div>
                                        <div className="notification-text">Allowed Size: <span>500MB</span></div>
                                    </div>
                                </div>
                                <span className="text-danger">{this.state.errorMsg}</span>
                                <div className="media-url-copier-blk">
                                    <div className="media-label">Paste Video URL</div>
                                    <input type="text" placeholder="( Mp4 URL )" className="media-url-input" id="mediaInputUrl" />
                                    <button className="media-add-btn" onClick={this.addFile} type="button" disabled={this.state.disableBtn}>
                                        {this.state.disableBtn === true ? 'Adding File...' : 'Add File'}
                                        {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}</button>
                                    <div className="text-danger">{this.state.errorUrlMsg}</div>
                                </div>
                                <div className="media-file-list-blk">
                                    {this.state.selectedFile !== "" && (
                                        <div className="d-flex align-items-center file-list-container">
                                            <div className="filetype-icon-wrapper filter-img">
                                                <img src={require("../../img/vdo_icon.png")} alt="video-icon" />
                                            </div>
                                            <div className="filetype-desc">
                                                <div className="d-flex filename-desc">
                                                    <div className="filename" title={this.state.selectedFile['name'] !== undefined ? this.state.selectedFile['name'] : ""}>{this.state.selectedFile['name'] !== undefined ? this.state.selectedFile['name'] : ""}</div>
                                                </div>
                                                <div className="file-upload-progress">
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={this.state.loaded} style={{ "width": this.state.loaded + "%" }} aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="file-upload-close">&times;</div> */}
                                        </div>
                                    )}
                                    {this.state.eventFiles.length > 0 ?
                                        this.state.eventFiles.map((eventfiles, key) => {

                                            let types = [
                                                "video/mp4",
                                                "audio/mp3",
                                                "audio/mpeg",
                                                "mp4",
                                                "mp3",
                                                "MP4",
                                                "MP3",
                                                "audiovideo",
                                                "audio",
                                                "video"
                                            ];

                                            if (types.every(type => eventfiles.type !== type)) {
                                                return false;
                                            }
                                            return (
                                                <div className="d-flex align-items-center file-list-container" key={key}>
                                                    <div className="filetype-icon-wrapper">
                                                        <img src={require("../../img/vdo_icon.png")} alt="video-icon" />
                                                    </div>
                                                    <div className="filetype-desc">
                                                        <div className="d-flex filename-desc">
                                                            <div className="filename" title={eventfiles.title}>{eventfiles.title}</div>

                                                        </div>
                                                    </div>
                                                    <div className="file-delete-icon" onClick={() => this.deleteEventFile(eventfiles._id, key)}>
                                                        <img src={require("../../img/trash.svg")} alt="trash-icon" />
                                                    </div>
                                                </div>
                                            )
                                        })

                                        : ''}
                                </div>
                            </div>
                            <div className="ppt-upload-blk">
                                <div className="upload-vdo-caption">Upload PPT</div>
                                <div className="ppt-uploader">
                                    <div className="d-flex align-items-center justify-content-center vdo-file-uploader" style={{"flexDirection": "column"}}>
                                        <label className="vdo-file-upload-btn">
                                            <input type="file" id="uploadeventfile_ppt" className="d-none" onChange={this.onPPTChangeHandler} />Browse
                                        </label>
                                        <div className="notification-area" style={{ "marginTop": "1rem" }}>
                                            <div className="notification-text">Supported formats: <span>ppt, pptx</span></div>
                                            <div className="notification-text">Allowed Size: <span>50MB</span></div>
                                        </div>
                                    </div>
                                    <span className="text-danger">{this.state.errorMsgPPT}</span>
                                </div>
                                <div className="media-file-list-blk">
                                    {this.state.selectedFilePPT !== "" && (
                                        <div className="d-flex align-items-center file-list-container">
                                            <div className="filetype-icon-wrapper filter-img">
                                                <img src={require("../../img/mspowerpoint.png")} alt="video-icon" />
                                            </div>
                                            <div className="filetype-desc">
                                                <div className="d-flex filename-desc">
                                                    <div className="filename" title={this.state.selectedFilePPT['name'] !== undefined ? this.state.selectedFilePPT['name'] : ""}>{this.state.selectedFilePPT['name'] !== undefined ? this.state.selectedFilePPT['name'] : ""}</div>
                                                </div>
                                                <div className="file-upload-progress">
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={this.state.loadedPPT} style={{ "width": this.state.loadedPPT + "%" }} aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="file-upload-close">&times;</div> */}
                                        </div>
                                    )}
                                    {this.state.eventFiles.length > 0 ?
                                        this.state.eventFiles.map((eventfiles, key) => {
                                            let types = [
                                                "ppt",
                                                "pptx",
                                                "PPT",
                                                "PPTX",
                                                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                                "application/vnd.ms-powerpoint",
                                                "application/powerpoint",
                                                "application/mspowerpoint",
                                                "application/x-mspowerpoint"
                                            ];

                                            if (types.every(type => eventfiles.type !== type)) {
                                                return false;
                                            }
                                            return (
                                                <div className="d-flex align-items-center file-list-container" key={key}>
                                                    <div className="filetype-icon-wrapper">
                                                        <img src={require("../../img/mspowerpoint.png")} alt="video-icon" />
                                                    </div>
                                                    <div className="filetype-desc">
                                                        <div className="d-flex filename-desc">
                                                            <div className="filename" title={eventfiles.title}>{eventfiles.title}</div>

                                                        </div>
                                                    </div>
                                                    <div className="file-delete-icon" onClick={() => this.deleteEventFile(eventfiles._id, key)}>
                                                        <img src={require("../../img/trash.svg")} alt="trash-icon" />
                                                    </div>
                                                </div>
                                            )
                                        })

                                        : ''}
                                </div>
                                {/* <div className="media-file-list-blk">
                                    <div className="d-flex align-items-center file-list-container">
                                        <div className="filetype-icon-wrapper filter-img">
                                            <img src={require("../../img/mspowerpoint.png")} alt="powerpoint icon" />
                                        </div>
                                        <div className="filetype-desc">
                                            <div className="d-flex filename-desc"><div className="filename">Nulla curses nulla nec sap</div><span>.ppt</span></div>
                                            <div className="file-upload-progress">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="file-upload-close">&times;</div>
                                    </div>
                                    <div className="d-flex align-items-center file-list-container">
                                        <div className="filetype-icon-wrapper">
                                            <img src={require("../../img/mspowerpoint.png")} alt="powerpoint icon" />
                                        </div>
                                        <div className="filetype-desc">
                                            <div className="d-flex filename-desc"><div className="filename">Suspendisse sit amet</div><span>.ppt</span></div>
                                        </div>
                                        <div className="file-delete-icon">
                                            <img src={require("../../img/trash.svg")}  alt="trash icon"/>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center btn-acn-blk">
                    <button type="button" onClick={() => this.goToPrevStep()} className="prev-btn">Prev</button>
                    {/* <button type="submit" onClick={() => this.props.stepToRoom()} className="next-btn">Next</button> */}
                    <button type="submit" className="next-btn" disabled={this.state.disableNextBtn}>
                        Next {' '}
                        {this.state.disableNextBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                    </button>
                </div>
            </Form>
        )
    }

    render() {
        return (
            <React.Fragment>

                <Formik
                    enableReinitialize
                    initialValues={this.initialValues}
                    onSubmit={this.submitEventAdditionServices}
                >
                    {this.additionServices}
                </Formik>

            </React.Fragment>
        );
    }
}
