import Axioslib from "../lib/Axioslib";

const getInterpretersByEventID = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("interpreter/getInterpretersByEventID", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getEventMaterialsByEventID = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("interpreter/getEventMaterialsByEventID", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const updateAssignment = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("interpreter/updateAssignment", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const addAssignment = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("interpreter/AddAssignment", body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getAssignment = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.get("interpreter/getAssignment")
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const deleteAssignment = (id) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.delete("interpreter/deleteAssignment/"+id)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const uploadFile = (data, id) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("interpreter/uploadEventMaterial/"+id,data)
            .then(response => {                
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const addEventMaterials = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("interpreter/addEventMaterials", body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const getEventMaterials = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.get("interpreter/getEventMaterials")
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}


const deleteEventMaterial = (id) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.delete("interpreter/deleteEventMaterial/"+id)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
export const apiInterPreterService = {
    getInterpretersByEventID: getInterpretersByEventID,
    getEventMaterialsByEventID: getEventMaterialsByEventID,
    addAssignment : addAssignment,
    updateAssignment: updateAssignment,
    getAssignment : getAssignment,
    deleteAssignment : deleteAssignment,
    uploadFile : uploadFile,
    addEventMaterials : addEventMaterials,
    getEventMaterials : getEventMaterials,
    deleteEventMaterial:deleteEventMaterial
}

export default apiInterPreterService;