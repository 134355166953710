import React from "react";
import Header from "./includes/header";
import Event from "./eventModule/event";
import Room from "./eventModule/room";
import Language from "./eventModule/language";
import Session from "./eventModule/session";
import Interpreters from "./eventModule/interpreter";
import Participants from "./eventModule/participants";
import EventFiles from "./eventModule/eventfiles";
import { TabContent, TabPane, Nav, NavItem } from 'reactstrap';
import api from "../service/api.service";
import helper from '../service/helper';
import apiEventService from "../service/api.eventService";
// import Swal from 'sweetalert2';

// const Toast = Swal.mixin({
//     toast: true,
//     position: 'top-end',
//     showConfirmButton: false,
//     timer: 5000,
//     timerProgressBar: true,
//     onOpen: (toast) => {
//         toast.addEventListener('mouseenter', Swal.stopTimer)
//         toast.addEventListener('mouseleave', Swal.resumeTimer)
//     }
// });

export default class EventManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            savedEvent: false,
            eventDetails: [],
            sessionDetails: [],
            roomDetails: [],
            languages: [],
            interPreterLanguageDetails: [],
            editEventData: ""
        }
        this.userCredentials = helper.decodeEncodedItem(localStorage.getItem("userDetails"));
        this.eventID = ""
    }

    componentDidMount() {
        this.getLanguages();
       
        if (this.userCredentials !== "" && this.userCredentials !== undefined) {
            window.addEventListener("beforeunload", this.handleLeavePage);
        }

        this.splittedURL = this.props.location.pathname.split("/");
        if (this.splittedURL[2] !== "" && this.splittedURL[2] !== undefined) {
            this.eventID = this.splittedURL[2];
            this.getEventByID();
        }

    }

    getEventByID = () => {
        if (this.eventID !== "") {
            apiEventService.getEventByID(this.eventID).then((data) => {
                try {
                    if (data !== undefined && data !== null && data !== "" && data.status !== undefined && data.status !== null && data.status === 200) {
                        if (data.data.data !== undefined && data.data.data !== null && data.data.data !== "") {
                            this.setState({ editEventData: data.data.data });                        
                        }
                    }
                } catch(e) {
                    console.log(e.message);
                }
            });
        }
    }


    componentWillUnmount() {
        if (this.userCredentials !== "" && this.userCredentials !== undefined) {
            window.removeEventListener('beforeunload', this.handleLeavePage);
        }
    }

    handleLeavePage(e) {
        const confirmationMessage = 'Are you sure you want to leave?';
        e.returnValue = confirmationMessage; 
        return confirmationMessage;            
    }

    getLanguages = () => {
        api.getLanguages().then((data) => {
            if (data.status === 200) {
                if (data.data.result.length > 0) {
                    let language = data.data.result;
                    let langOptions = [];
                    language.map((lang) => {
                        return langOptions.push({ value: lang._id, label: lang.language })
                    })
                    this.setState({ languages: langOptions });
                } else {
                }
            }
        });
    }

    eventDetails = (events, type) => {
        
        if (events !== undefined && events !== null && events !== "") {

            if (type !== undefined && type !== null && type === "nomove") {
                this.setState({ eventDetails: events.data });
            } else {
                this.setState({
                    eventDetails: events.data
                }, () => this.setState({ activeTab: '2' }));
            }
        }
    }

    saveRooms = (roomData) => {
        let roomsArray = [];
        if (roomData !== "" && roomData !== undefined) {
            roomData.map((rooms) => {
                return roomsArray.push({ value: rooms._id, label: rooms.name });
            })
        }
        this.setState({
            roomDetails: roomsArray
        });
    }

    saveInterpreterLanguage = (interPreterLanguage) => {
        let interpreterLang = [];
        if (interPreterLanguage !== "" && interPreterLanguage !== undefined) {
            interPreterLanguage.map((lang) => {
                return interpreterLang.push({ value: lang._id, label: lang.title });
            })
        }
        this.setState({
            interPreterLanguageDetails: interpreterLang
        });
    }

    saveSessions = (sessionData) => {
        let sessionArray = [];
        if (sessionData !== "" && sessionData !== undefined) {
            sessionData.map((session) => {
                return sessionArray.push({ value: session._id, label: session.name });
            })
        }
        this.setState({
            sessionDetails: sessionArray
        });
    }


    stepToEvent = () => {
        this.setState({
            activeTab: '1'
        });
    }

    stepToEventFile = () => {
        this.setState({
            activeTab: '2'
        });
    }

    stepToRoom = () => {
        this.setState({
            activeTab: '3'
        });
    }

    stepToLanguage = () => {
        this.setState({
            activeTab: '4'
        });
    }

    stepToAgenda = () => {
        this.setState({
            activeTab: '5'
        });
    }

    stepToInterpreter = () => {
        this.setState({
            activeTab: '6'
        });
    }

    stepToParticipants = (flag) => {
        this.setState({
            activeTab: '7',
            callFn: flag
        });
    }

    toggleNextAndPrev = (nextAndPrev) => {
        if (nextAndPrev === 'prev') {
            switch (this.state.activeTab) {
                case '2':
                    this.setState({
                        activeTab: '1'
                    });
                    break;
                case '3':
                    this.setState({
                        activeTab: '2'
                    });
                    break;
                case '4':
                    this.setState({
                        activeTab: '3'
                    });
                    break;
                case '5':
                    this.setState({
                        activeTab: '4'
                    });
                    break;

                case '6':
                    this.setState({
                        activeTab: '5'
                    });
                    break;
                case '7':
                    this.setState({
                        activeTab: '6'
                    });
                    break;
                default:
                    break;
            }
        } else {
            switch (this.state.activeTab) {
                case '1':
                    this.setState({
                        activeTab: '2'
                    });
                    break;
                case '2':
                    this.setState({
                        activeTab: '3'
                    });
                    break;
                case '3':
                    this.setState({
                        activeTab: '4'
                    });
                    break;
                case '4':
                    this.setState({
                        activeTab: '5'
                    });
                    break;
                case '5':
                    this.setState({
                        activeTab: '6'
                    });
                    break;
                case '6':
                    this.setState({
                        activeTab: '7'
                    });
                    break;
                default:
                    break;
            }
        }

    }

    render() {
        return (
            <section className="scheduller-session">
                <Header history={this.props.history} />
                <div className="scheduler-nav-sel-blk ">
                    <div className="d-flex align-items-center justify-content-between scheduler-top-part">
                        {this.state.activeTab === "1" && <div className="active-nav-blk-mob">Event Details</div>}
                        {this.state.activeTab === "2" && <div className="active-nav-blk-mob">Event Files</div>}
                        {this.state.activeTab === "3" && <div className="active-nav-blk-mob">Rooms</div>}
                        {this.state.activeTab === "4" && <div className="active-nav-blk-mob">Languages</div>}
                        {this.state.activeTab === "5" && <div className="active-nav-blk-mob">Sessions</div>}
                        {this.state.activeTab === "6" && <div className="active-nav-blk-mob">Interpreters</div>}
                        {this.state.activeTab === "7" && <div className="active-nav-blk-mob">Participants</div>}
                        <Nav className="align-items-center schedule-navtabs">
                            <NavItem className="schedule-lst"> <span className={this.state.activeTab === '1' ? "active" : ""} >Event Details</span></NavItem>
                            <NavItem className="schedule-lst"> <span className={this.state.activeTab === '2' ? "active" : ""} >Event Files</span></NavItem>
                            <NavItem className="schedule-lst"> <span className={this.state.activeTab === '3' ? "active" : ""} >Rooms</span></NavItem>
                            <NavItem className="schedule-lst"> <span className={this.state.activeTab === '4' ? "active" : ""} >Languages</span></NavItem>
                            <NavItem className="schedule-lst"> <span className={this.state.activeTab === '5' ? "active" : ""} >Sessions</span></NavItem>
                            <NavItem className="schedule-lst"> <span className={this.state.activeTab === '6' ? "active" : ""} >Interpreters </span></NavItem>
                            <NavItem className="schedule-lst"> <span className={this.state.activeTab === '7' ? "active" : ""} >Participants</span></NavItem>
                        </Nav>
                        <div className="schedule-toggler-blk">
                            <div className="schedule-toggler"><img alt="settings" src={require("../img/vdots.svg")} /></div>
                        </div>
                        <div className="sidenav-blk">
                            <div className="d-flex align-items-center justify-content-between sidebar-top-part">
                                <span className="sidebar-settings-txt">Settings</span>
                                <span className="close">&times;</span>
                            </div>
                            <ul className="sidenav-menu-wrap">
                                <li><span>Schedule Meeting</span></li>
                            </ul>
                        </div>

                    </div>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {this.eventID !== undefined && this.eventID !== null && this.eventID !== "" && this.state.editEventData !== undefined && this.state.editEventData !== null && this.state.editEventData !== "" && (
                                <Event activeTab={this.state.activeTab} location={this.props.location} history={this.props.history} saveEventDetails={this.eventDetails} stepToEventFile={this.stepToEventFile} editEventData={this.state.editEventData} />
                            )}

                            {this.eventID === "" && (
                                <Event activeTab={this.state.activeTab} location={this.props.location} history={this.props.history} saveEventDetails={this.eventDetails} stepToEventFile={this.stepToEventFile} />
                            )}
                            
                        </TabPane>
                        <TabPane tabId="2">
                            <EventFiles activeTab={this.state.activeTab} stepToEvent={this.stepToEvent} stepToRoom={this.stepToRoom} saveEventDetails={this.eventDetails} eventData={this.state.eventDetails} location={this.props.location} />
                        </TabPane>
                        <TabPane tabId="3">
                            <Room activeTab={this.state.activeTab} stepToEventFile={this.stepToEventFile} stepToLanguage={this.stepToLanguage} saveRooms={this.saveRooms} eventData={this.state.eventDetails} location={this.props.location}/>
                        </TabPane>
                        <TabPane tabId="4">
                            <Language saveLanguageForInterpreter={this.saveInterpreterLanguage} activeTab={this.state.activeTab} stepToRoom={this.stepToRoom} stepToAgenda={this.stepToAgenda} languages={this.state.languages} eventData={this.state.eventDetails} location={this.props.location}/>
                        </TabPane>
                        <TabPane tabId="5">
                            <Session activeTab={this.state.activeTab} stepToLanguage={this.stepToLanguage} stepToInterpreter={this.stepToInterpreter} saveSessions={this.saveSessions} rooms={this.state.roomDetails} eventData={this.state.eventDetails} location={this.props.location}/>
                        </TabPane>
                        <TabPane tabId="6">
                            <Interpreters activeTab={this.state.activeTab} stepToParticipants={this.stepToParticipants} stepToAgenda={this.stepToAgenda} session={this.state.sessionDetails} languages={this.state.interPreterLanguageDetails} eventData={this.state.eventDetails} location={this.props.location}/>
                        </TabPane>
                        <TabPane tabId="7">
                            <Participants history={this.props.history} activeTab={this.state.activeTab} stepToInterpreter={this.stepToInterpreter} eventData={this.state.eventDetails} location={this.props.location} flag={this.state.callFn}/>
                        </TabPane>
                    </TabContent>
                </div>

            </section>
        );
    }
}