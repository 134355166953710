import Axioslib from "../lib/Axioslib";

const addEvent = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/addEvent", body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const uploadCoverImg = async (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/uploadCoverImg/"+id, data)
            .then(response => {                
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const uploadLogoImg = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/uploadLogoImg/"+id, data)
            .then(response => {                
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const uploadlobbyResource = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/uploadLobbyResource/"+id, data)
            .then(response => {                
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const uploadLoginPageBg = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/uploadLoginPageBg/"+id, data)
            .then(response => {                
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const uploadLandingPageBg = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/uploadLandingPageBg/"+id, data)
            .then(response => {                
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const uploadConferencePageBg = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/uploadConferencePageBg/"+id, data)
            .then(response => {                
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const checkEventCode = (code) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.get("event/checkEventCode/"+code)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}


const updateEventDetails = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/updateEventDetails",body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const getEvents = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/getAllEvents",body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getSessionsForEvent = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/getSessions", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getPollsForEvent = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("polls/listPollsForEvent", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getPollReport = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("polls/getreport", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getUserStreamReport = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/getUserStreamReport", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getEventRooms = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/getEventRooms",body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getEventAgenda = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/getEventAgenda",body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getImage = (id) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.get("getFiles/getImage/"+id)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}


const getEventByID = (id) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.get("event/getEventByID/"+id)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const deleteEvent = (id) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.delete("event/deleteEvent/" + id)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const checkEmailID = (param) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/checkEmailID",param)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const saveEventUserDetails = (param) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/saveEventUserDetails", param)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}


const getRoomDetails = (param) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/getRoomDetails",param)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const saveEventFileDetails = (param) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/save_event_file", param)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const deleteEventFiles = (param) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/delete_event_file", param)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getEventFilesByEventID = (param) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/event_files_list", param)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const updateSelectedLanguage = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/updateSelectedLanguage", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const deleteEventUser = (id) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.delete("event/deleteEventUser/" + id)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const uploadEventUsers = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/uploadEventUsers", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const checkEventUserDetailsExists = (param) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/checkEventUserDetailsExists", param)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const updateEventUserDetails = (param) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/updateEventUserDetails", param)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

export const apiEventService = {
    addEvent : addEvent,
    checkEventCode : checkEventCode,
    uploadCoverImg  : uploadCoverImg,
    uploadLogoImg   : uploadLogoImg,
    uploadlobbyResource   : uploadlobbyResource,
    uploadLoginPageBg : uploadLoginPageBg,
    uploadLandingPageBg : uploadLandingPageBg,
    uploadConferencePageBg: uploadConferencePageBg,
    updateEventDetails : updateEventDetails,
    getEvents:getEvents,
    getEventRooms:getEventRooms,
    getEventAgenda:getEventAgenda,
    getImage:getImage,
    getEventByID : getEventByID,
    checkEmailID : checkEmailID,
    checkEventUserDetailsExists: checkEventUserDetailsExists,
    saveEventUserDetails: saveEventUserDetails,
    getRoomDetails : getRoomDetails,
    deleteEvent: deleteEvent,
    deleteEventUser: deleteEventUser,
    getSessionsForEvent: getSessionsForEvent,
    getPollsForEvent: getPollsForEvent,
    getPollReport: getPollReport,
    getUserStreamReport: getUserStreamReport,
    saveEventFileDetails: saveEventFileDetails,
    deleteEventFiles: deleteEventFiles,
    getEventFilesByEventID: getEventFilesByEventID,
    updateSelectedLanguage: updateSelectedLanguage,
    uploadEventUsers: uploadEventUsers,
    updateEventUserDetails: updateEventUserDetails
}
export default apiEventService;