import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';
import apiEventService from "../../service/api.eventService";
import jwt from "jwt-simple";
import helper from '../../service/helper';
// import logoIMAGE from "../../img/logo.png";
import api from "../../service/api.service";
import Select from "react-select";
import logoIMAGEWITHTEXT from "../../img/logo-2-01.png";


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
export default class LoginWithUrl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventList: [],
            errorMessage: "",
            coverImage: [],
            logoImage: "",
            user_role: "",
            meeting_code: '',
            languageDetails: [{ value: "floor", label: "Original" }],
            selectedOption: { value: "floor", label: "Original" },
            passwordEnabled: false,
            speakerUsers: [],
            learnerUsers: [],
            apiresponse:false
        }
        this.splittedURL = this.props.location.pathname.split("/");
        this.meetingCode = '';
        if (this.splittedURL[1] !== "" && this.splittedURL[1] !== undefined) {
            this.meetingCode = this.splittedURL[1];
        }
        this.speakers = []
        this.learners = []

    }

    componentDidMount() {
        let userRole = helper.getUserRole(this.meetingCode.charAt(0));
        if (userRole !== undefined && userRole !== false) {
            this.setState({ user_role: userRole })
            let meetingCode = this.meetingCode.substr(this.meetingCode.length - process.env.REACT_APP_MEETINGCODE_LENGTH);
            if (meetingCode !== undefined && meetingCode.length === parseInt(process.env.REACT_APP_MEETINGCODE_LENGTH)) {
                this.setState({ meeting_code: meetingCode })
                this.getEventByEventCode(meetingCode);
            }else{
                setTimeout(() => {
                    this.setState({apiresponse:true})
                }, 1000); 
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: "Not a valid meeting link."
            });
            setTimeout(() => {
                this.setState({apiresponse:true})
            }, 1000); 
        }

        localStorage.removeItem("userDetails");
    }

    getEventByEventCode = (meetingCode) => {
        if (meetingCode && meetingCode !== null &&
            meetingCode !== "" && meetingCode !== undefined) {
            let getEventDetails = {
                event_code: meetingCode,
                finish: true
            }
            apiEventService.getEvents(getEventDetails).then((data) => {
                setTimeout(() => {
                    this.setState({apiresponse:true})
                }, 1000); 
                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        if (data.data.status && data.data.status === true && data.data.data && data.data.data.length > 0) {
                            let passwordEnabled = false;
                            if (data.data.data[0].password !== undefined && data.data.data[0].password !== "" && data.data.data[0].password !== null) {
                                passwordEnabled = true;
                            }

                            this.setState({ eventList: data.data.data, passwordEnabled: passwordEnabled });
                            let getSpeakerDetails = {
                                event_id: data.data.data[0]._id,
                                speaker_status: true,
                                back_end_user: true,
                                role: 'speaker'
                            }
                            apiEventService.checkEventUserDetailsExists(getSpeakerDetails).then((data) => {
                                if (data && data !== undefined && data !== null && data !== "") {
                                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                        if (data.data.status && data.data.status === 'success' && data.data.data && data.data.data.length > 0) {
                                            let speakerEmail = []
                                            data.data.data.map((users) => {
                                                return speakerEmail.push(users.email)
                                            })
                                            this.speakers = data.data.data;
                                            this.setState({ speakerUsers: speakerEmail });
                                        }
                                    }
                                }
                            });
                            let getListnerDetails = {
                                event_id: data.data.data[0]._id,
                                listener_status: true,
                                back_end_user: true,
                                role: 'listener'
                            }
                            apiEventService.checkEventUserDetailsExists(getListnerDetails).then((data) => {
                                if (data && data !== undefined && data !== null && data !== "") {
                                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                        if (data.data.status && data.data.status === 'success' && data.data.data && data.data.data.length > 0) {
                                            let listenerEmail = []
                                            data.data.data.map((users) => {
                                                return listenerEmail.push(users.email)
                                            })
                                            this.learners = data.data.data;
                                            this.setState({ learnerUsers: listenerEmail });
                                        }
                                    }
                                }
                            });
                            this.getEventLanguages(data.data.data[0]._id);
                            if (data.data.data[0] !== undefined && data.data.data[0].useDefault !== undefined && data.data.data[0].useDefault === false) {
                                let logo = process.env.REACT_APP_API_IMAGE_URL + data.data.data[0].logo_image;

                                let cover = [];

                                if (data.data.data[0].cover_image !== undefined && data.data.data[0].cover_image !== null && data.data.data[0].cover_image !== "" && data.data.data[0].cover_image.length > 0) {
                                    cover = data.data.data[0].cover_image;
                                    cover = cover.filter(function (el) {
                                        return (el !== undefined && el !== null && el !== "");
                                    });
                                }
                                this.setState({
                                    logoImage: logo,
                                    coverImage: cover
                                })
                            } else {
                                this.setState({
                                    logoImage: logoIMAGEWITHTEXT,
                                    coverImage: []
                                })
                            }
                            if (data.data.data[0] !== undefined) {
                                
                                if (data.data.data[0].login_page_bg !== undefined && data.data.data[0].login_page_bg !== null && data.data.data[0].login_page_bg !== "") {
                                    if(document.getElementById("loginwrap")){
                                        document.getElementById("loginwrap").style.backgroundImage = "url('"+process.env.REACT_APP_API_IMAGE_URL+data.data.data[0].login_page_bg+"')";
                                        //document.getElementById("loginwrap").style.background = "url('"+process.env.REACT_APP_API_IMAGE_URL+data.data.data[0].login_page_bg+"')";
                                        document.getElementById("loginwrap").style.backgroundPosition = "center";
                                        document.getElementById("loginwrap").style.backgroundRepeat = " no-repeat";
                                        document.getElementById("loginwrap").style.backgroundSize = "cover";
                                        document.getElementById("loginwrap").classList.add('loginwrap')
                                        document.getElementById("defaultimg").classList.add('hide')
                                        
                                    }
                                }
                            }
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "Meeting is not existing."
                            });
                        }
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }
            });
        } else {
            setTimeout(() => {
                this.setState({apiresponse:true})
            }, 1000); 
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please try again!"
            })
        }

    }

    getEventLanguages = (eventID) => {

        if (eventID !== "" && eventID !== undefined) {
            let params = {
                event_id: eventID
            }
            api.getLanguagesByEventID(params).then((data) => {
                if (data && data !== undefined && data !== null && data !== "" && data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                    if (data.data.data !== "" && data.data.data !== undefined) {
                        let language = data.data.data
                        let langOptions = this.state.languageDetails;
                        language.map((lang) => {
                            return langOptions.push({ value: lang._id, label: lang.title })
                        })
                        this.setState({ languageDetails: langOptions });
                    }
                }
            });
        }
    }

    submitJoin = () => {
        if (this.state.eventList !== "" && this.state.eventList.length > 0) {
            let userName = document.getElementById("rafiky-user-name").value;
            userName = userName.trim();
            let emailID = document.getElementById("rafiky-user-mail").value;
            emailID = emailID.trim();
            emailID = emailID.toLowerCase();

            
            this.setState({ errorMessage: '' });

            if (userName !== undefined && userName !== null && userName !== "" && emailID !== undefined && emailID !== null && emailID !== "") {

                let lastAtPos = emailID.lastIndexOf('@');
                let lastDotPos = emailID.lastIndexOf('.');
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && emailID.indexOf('@@') === -1 && lastDotPos > 2 && (emailID.length - lastDotPos) > 2)) {
                    this.setState({
                        errorMessage: 'Please enter valid email id'
                    })
                } else {
                    if (this.state.user_role === 'speaker') {
                        if (this.meetingCode.substring(0, 2) !== 'ss') {
                            if (this.state.speakerUsers.length > 0) {
                                if (this.state.speakerUsers.includes(emailID) === false) {
                                    this.setState({
                                        errorMessage: 'You are not an assigned speaker'
                                    })
                                    return false;
                                }
                            }
                        }
                    }

                    if (this.state.user_role === 'listener') {
                        if (this.meetingCode.substring(0, 2) !== 'ls') {
                            if (this.state.learnerUsers.length > 0) {
                                if (this.state.learnerUsers.includes(emailID) === false) {
                                    this.setState({
                                        errorMessage: 'You are not an assigned listener'
                                    })
                                    return false;
                                }
                            }
                        }
                    }
                    if (this.state.user_role !== 'listener' || this.meetingCode.substring(0, 2) === 'ls') {
                        if (this.state.learnerUsers.length > 0) {
                            if (this.state.learnerUsers.includes(emailID) === true) {
                                this.setState({
                                    errorMessage: 'Email address belongs to viewer role. Please use different email address'
                                })
                                return false;
                            }
                        }
                    }

                    if (this.state.user_role !== 'speaker' || this.meetingCode.substring(0, 2) === 'ss') {
                        if (this.state.speakerUsers.length > 0) {
                            if (this.state.speakerUsers.includes(emailID) === true) {
                                this.setState({
                                    errorMessage: 'Email address belongs to speaker role. Please use different email address'
                                })
                                return false;
                            }
                        }
                    }
                    this.setState({ errorMessage: '' });
                    let userDetails = {
                        name: userName,
                        mail: emailID,
                        user_selected_language: (this.meetingCode !== undefined && this.meetingCode !== null && this.meetingCode !== "" && (this.meetingCode.substring(0, 2) === "s-" || this.meetingCode.substring(0, 2) === "l-" || this.meetingCode.substring(0, 2) === "ss")) ? (this.state.selectedOption.value !== undefined && this.state.selectedOption.value !== null && this.state.selectedOption.value !== "") ? this.state.selectedOption.label : "" : "",
                        eventDetails: this.state.eventList,
                        user_role: this.state.user_role,
                        logo: this.state.logoImage,
                        meetingCode: this.meetingCode
                    }
                    let emailData = {
                        email: emailID,
                        event_id: this.state.eventList[0]._id
                    }

                    apiEventService.checkEmailID(emailData).then((data) => {
                        if (data !== undefined && data !== null && data !== "") {
                            if (data.status !== undefined && data.status !== null && data.status === 200) {
                                if (data.data.status !== undefined && data.data.status !== null && data.data.status === true && data.data.data !== undefined && data.data.data !== null && data.data.data !== "") {
                                    if (this.meetingCode.charAt(0) === 'i') {
                                        userDetails.sessionDetails = data.data.data.session;
                                        userDetails.loggedInUserId = data.data.data._id;
                                        userDetails = jwt.encode(userDetails, process.env.REACT_APP_JWT_SECRET, 'HS512');
                                        window.localStorage.setItem('eventCodeUser', JSON.stringify(userDetails));
                                        this.props.history.push("/events");
                                    } else {
                                        this.setState({ errorMessage: 'Email address belongs to interpreter' });
                                    }
                                } else {

                                    if (this.meetingCode !== undefined && this.meetingCode !== null && this.meetingCode !== "" && this.meetingCode.charAt(0) === 'i') {
                                        this.setState({ errorMessage: 'Your are not an assigned interpreter' });
                                    } else if (this.state.passwordEnabled === true && this.meetingCode !== undefined && this.meetingCode !== null && this.meetingCode !== "" && this.meetingCode.substring(0, 2) === "m-") {
                                        let password = document.getElementById("rafiky-user-password").value;
                                        password = password.trim();
                                        if (password !== "" || password !== null || password !== undefined) {
                                            let eventPassword = this.state.eventList[0].password;
                                            if (eventPassword === password) {
                                                userDetails = jwt.encode(userDetails, process.env.REACT_APP_JWT_SECRET, 'HS512');
                                                window.localStorage.setItem('eventCodeUser', JSON.stringify(userDetails));
                                                this.props.history.push("/events");
                                            } else {
                                                this.setState({ errorMessage: 'Incorrect Password' });
                                            }

                                        } else {
                                            this.setState({ errorMessage: 'Event Password is mandatory' });
                                        }
                                    } else {
                                        if (this.state.user_role === 'listener' || this.state.user_role === 'speaker') {
                                            if (this.state.learnerUsers.length > 0 || this.state.speakerUsers.length > 0) {
                                                this.updateEventUserDetails(userDetails);
                                            }
                                        }
                                        userDetails = jwt.encode(userDetails, process.env.REACT_APP_JWT_SECRET, 'HS512');
                                        window.localStorage.setItem('eventCodeUser', JSON.stringify(userDetails));
                                        this.props.history.push("/events");
                                    }

                                    // if (this.meetingCode.charAt(0) === 'i') {
                                    //     this.setState({ errorMessage: 'Your are not an assigned interpreter' });
                                    // } else {
                                    //     userDetails = jwt.encode(userDetails, process.env.REACT_APP_JWT_SECRET, 'HS512');
                                    //     window.localStorage.setItem('eventCodeUser', JSON.stringify(userDetails));
                                    //     this.props.history.push("/events");
                                    // }
                                }
                            }
                        }

                    });
                }
            } else {
                if (userName === "" || userName === null || userName === undefined) {
                    this.setState({ errorMessage: 'Your Name is mandatory' });
                } else if (emailID === "" || emailID === null || emailID === undefined) {
                    this.setState({ errorMessage: 'Your Email is mandatory' });
                }
            }

        } else {
            Toast.fire({
                icon: 'warning',
                title: "Meeting is not existing."
            });
        }
    }

    updateEventUserDetails = (userData) => {
        let user = []
        if (this.state.user_role === 'speaker') {
            this.speakers.map((speaker) => {
                if (speaker.email === userData.mail) {
                    user = speaker;
                }
                return true;
            })
        } else {
            this.learners.map((learner) => {
                if (learner.email === userData.mail) {
                    user = learner;
                }
                return true;
            })
        }
        if (user.back_end_user === true && user.name === "") {
            let userDetails = {
                name: userData.name,
                id: user._id
            }
            apiEventService.updateEventUserDetails(userDetails).then((data) => {
                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                    }
                }
            })
        }

    }
    
    render() {
        return (
            <section  class="main-wrapper">
            <div className={this.state.apiresponse ? "hide":"apiloader"}><div className="apiloader-spinner"></div></div>
            <div class="login-wrap">
                <div class="img-wrap" id="loginwrap">
                    
                    <div id="defaultimg" class="flex-column">
                        <img src="/login-.jpg" alt="loginjpg"/>
                        <div class="text-center mt-3">
                            <a class="small-text white-text text-decoration-none" href="https://www.youtube.com/watch?v=Fo_GHVw_GoA" target="_blank ">Instructions/Tutorial</a>
                        </div>
                    </div>
                </div>
                <div class="content-wrap">
                    <div class="object"><span></span></div>
                    <div class="object-left"><span></span></div>
                    <div class="form-wrap">
                        <div class="form-inner">
                            <div class="main-logo">
                                {this.state.logoImage !== "" && <img src={this.state.logoImage} alt="logo" className="logo-holder"/>}
                                <br/> Meeting Code : {this.state.meeting_code}
                            </div>
                            <form className="mt-3"> 
                                <div class="form-list">
                                    <label for="exampleInputEmail1" class="form-label mb-2">Name</label>
                                    <div class="input-wrap">
                                        <input name="user-name" id="rafiky-user-name" placeholder="Name" type="text" className='form-control' />                                  
                                        <span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path></svg>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-list">
                                    <label for="exampleInputPassword1" class="form-label mb-2">Email</label>
                                    <div class="input-wrap">
                                        <input name="user-mail" placeholder="Email" id="rafiky-user-mail" type="text" className='form-control' />
                                        <span class="icon">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                viewBox="0 0 330.001 330.001">
                                                <g id="XMLID_348_">
                                                    <path id="XMLID_350_" d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602
                                                        L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z"
                                                        />
                                                    <polygon id="XMLID_351_" points="165.001,146.4 310.087,40.001 19.911,40 	"/>
                                                </g>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                {(this.state.passwordEnabled === true && this.meetingCode !== undefined && this.meetingCode !== null && this.meetingCode !== "" && this.meetingCode.substring(0, 2) === "m-" &&
                                <div class="form-list">
                                    <label for="exampleInputEmail1" class="form-label">Password</label>
                                    <div class="input-wrap">
                                        <input name="password" id="rafiky-user-password" placeholder="Password" type="password" className='form-control' />                                  
                                        <span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path></svg>
                                        </span>
                                    </div>
                                </div>
                                )}
                                
                                {(this.meetingCode !== undefined && this.meetingCode !== null && this.meetingCode !== "" && (this.meetingCode.substring(0, 2) === "s-" || this.meetingCode.substring(0, 2) === "l-" || this.meetingCode.substring(0, 2) === "ss")) && (
                                    <div class="form-list">
                                        <label for="exampleInputPassword1" class="form-label">Default Language</label>
                                        <div class="input-wrap input-select">
                                            <span class="icon">
                                                <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19.753 10.909c-.624-1.707-2.366-2.726-4.661-2.726-.09 0-.176.002-.262.006l-.016-2.063 3.525-.607c.115-.019.133-.119.109-.231-.023-.111-.167-.883-.188-.976-.027-.131-.102-.127-.207-.109-.104.018-3.25.461-3.25.461l-.013-2.078c-.001-.125-.069-.158-.194-.156l-1.025.016c-.105.002-.164.049-.162.148l.033 2.307s-3.061.527-3.144.543c-.084.014-.17.053-.151.143s.19 1.094.208 1.172c.018.08.072.129.188.107l2.924-.504.035 2.018c-1.077.281-1.801.824-2.256 1.303-.768.807-1.207 1.887-1.207 2.963 0 1.586.971 2.529 2.328 2.695 3.162.387 5.119-3.06 5.769-4.715 1.097 1.506.256 4.354-2.094 5.98-.043.029-.098.129-.033.207l.619.756c.08.096.206.059.256.023 2.51-1.73 3.661-4.515 2.869-6.683zm-7.386 3.188c-.966-.121-.944-.914-.944-1.453 0-.773.327-1.58.876-2.156a3.21 3.21 0 0 1 1.229-.799l.082 4.277c-.385.131-.799.185-1.243.131zm2.427-.553l.046-4.109c.084-.004.166-.01.252-.01.773 0 1.494.145 1.885.361.391.217-1.023 2.713-2.183 3.758zm-8.95-7.668a.196.196 0 0 0-.196-.145h-1.95a.194.194 0 0 0-.194.144L.008 16.916c-.017.051-.011.076.062.076h1.733c.075 0 .099-.023.114-.072l1.008-3.318h3.496l1.008 3.318c.016.049.039.072.113.072h1.734c.072 0 .078-.025.062-.076-.014-.05-3.083-9.741-3.494-11.04zm-2.618 6.318l1.447-5.25 1.447 5.25H3.226z"/></svg>
                                            </span>         
                                            <Select
                                                name="language"
                                                value={this.state.selectedOption}
                                                options={this.state.languageDetails}
                                                noOptionsMessage={() => "No Languages found"}
                                                onChange={(e) => this.setState({ selectedOption: e }, () => {
                                                    console.log(this.state.selectedOption);
                                                })}
                                                style={{ display: 'block' }}
                                            />                               
                                        </div>
                                    </div>
                                )}
                                
                               
                                {this.state.errorMessage !== "" &&
                                    <div className="validtxt_msg">{this.state.errorMessage}</div>
                                }
                                <div class="text-center">
                                    <button type="button" onClick={() => this.submitJoin()} className="btn btn-primary my-2" disabled={this.state.disableBtn}>
                                        {this.state.disableBtn === true ? 'Logging in...' : 'Login'}
                                        {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                    </button>
                                </div>
                                <p class="small-text text-center mb-4">By signing in, I agree to the Rafiky's Privacy Statement and Terms of Service.</p>
                            </form>
                            {this.state.coverImage !== "" && this.state.coverImage.length > 0 && (
                                <div className="login-cover-img-wrapper">
                                    {this.state.coverImage.map((value, index) => {
                                        return (
                                            <div className="login-cover-img" key={"coverimage_" + index}>
                                                <img src={process.env.REACT_APP_API_IMAGE_URL + value} className="object-fill" alt="Sponsor Logo" />
                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                        {this.state.coverImage === "" || this.state.coverImage.length === 0 || true && (
                             <div class="social-info">
                                <p class=""><span>Follow Us </span></p>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/Rafiky.net" target="_blank">
                                            <img src="/facebook.svg"/>                                                
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/rafikynet/" target="_blank">    
                                            <img src="/instagram.svg"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/rafiky_net" target="_blank">
                                            <img src="/twitter.svg"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/rafiky/?viewAsMember=true" target="_blank">
                                            <img src="/linkedin.svg"/>
                                        </a>
                                    </li>
                                </ul>
                                <div class="text-center">
                                    <a class="term-conditions" href="https://www.rafiky.net/en/terms-conditions/" target="_blank"> Terms and conditions</a>
                                </div>      
                                <div class="text-center mt-2 instruction d-md-none">
                                    <a class="small-text grey-text text-decoration-none" href="https://www.youtube.com/watch?v=Fo_GHVw_GoA" target="_blank ">Instructions/Tutorial</a>
                                </div>                          
                            </div>
                        )}
                            
                        </div>
                    </div>
                    <div class="object-leftbottom"><span></span></div>
                    <div class="object-bottom "><span></span></div>
                    <div class="object-bottomright"><span></span></div>
                </div>
            </div>
        </section>
        )
    }

}
