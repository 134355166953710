import React from "react";
import { Modal } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "../../service/api.service";
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
// import OpenTok from "opentok";
import { setMinutes, setHours } from "date-fns";


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export default class Agenda extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            rooms: [],
            agendaDetails: [],
            eventID: '',
            disableBtn: false,
            agendaId: "",
            streamOut: false
        }
        this.rooms = []
    }

    componentDidMount() {
        this.splittedURL = this.props.location.pathname.split("/");
        if (this.splittedURL[2] !== "" && this.splittedURL[2] !== undefined) {
            this.eventID = this.splittedURL[2];
            this.getSessionsByEventID();
        }
    }

    getSessionsByEventID = () => {
        let params = {
            event_id: this.eventID
        }
        api.getSessionsByEventID(params).then((data) => {

            if (data && data !== undefined && data !== null && data !== "" && data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                if (data.data.data !== "" && data.data.data !== undefined) {
                    this.setState({ agendaDetails: data.data.data }, () => {
                        this.props.saveSessions(this.state.agendaDetails);
                    });

                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "Event not available. Please try again!"
                    })
                }
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. Please try again!"
                })
            }
        });
    }

    componentDidUpdate() {

        this.setState({
            eventDate: this.props.eventData.date,
            eventStartTime: this.props.eventData.start_time,
            eventEndTime: this.props.eventData.end_time,
            streamOut: this.props.eventData.streamOut
        });
        
        if (this.props.eventData._id !== undefined && this.props.activeTab === '5' && this.state.eventID !== this.props.eventData._id) {
            this.setState({ 
                eventID: this.props.eventData._id
            });
        } else if (this.props.eventData._id === undefined && this.props.activeTab === '5') {
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please try again!"
            });
            this.props.stepToLanguage();
        }
        if (this.props.rooms !== undefined && this.props.rooms.length > 0) {
            this.rooms = this.props.rooms;
            this.updateSessionDetails();
        }
    }

    updateSessionDetails = () => {
        if (this.state.agendaDetails.length > 0) {
            let newSession = [];
            let sessionDetails = this.state.agendaDetails;
            sessionDetails.map((session) => {
                if (this.rooms.filter(room => (room.value === session.room)) !== undefined
                    && this.rooms.filter(room => (room.value === session.room))[0] !== undefined
                    && this.rooms.filter(room => (room.value === session.room))[0].label !== undefined) {
                    let roomName = this.rooms.filter(room => (room.value === session.room))[0].label;
                    if (roomName !== undefined && roomName !== "") {
                        newSession.push(session);
                    }
                }
                return true;
            });
            let newArray = JSON.stringify(newSession);
            let oldArray = JSON.stringify(this.state.agendaDetails);
            if (newArray !== oldArray) {
                this.setState({agendaDetails: newSession}); 
                this.props.saveSessions(this.state.agendaDetails);
            }          
        }
    }


    openSessionModal = () => {
        if (this.rooms.length > 0) {
            this.setState({ isModalOpen: true, disableBtn: false });
        } else {
            Toast.fire({
                icon: 'warning',
                title: "No Rooms available. Please refresh page"
            });
        }
    }

    closeSessionModal = () => {
        this.setState({ isModalOpen: false, disableBtn: false, agendaId: "" }, () => {
            this.initialValues.sessionName = "";
            this.initialValues.session_date = "";
            this.initialValues.session_start_time = "";
            this.initialValues.session_end_time = "";
            this.initialValues.rooms = "";
            this.initialValues.privateRoom = false;
            this.initialValues.description = "";
            this.initialValues.sessionfloorTitle = "";
        });
    }

    initialValues = {
        sessionName: '',
        session_date: '',
        session_start_time: '',
        session_end_time: '',
        rooms: '',
        privateRoom: false,
        description: '',
        sessionfloorTitle: ''
    }

    validationSchema = Yup.object().shape({
        sessionName: Yup.string()
            .trim()
            .required("Session Name is required"),
        session_date: Yup.date()
            .required('Start date is required'),
        session_start_time: Yup.date()
            .required('Start Time is required'),
        session_end_time: Yup.date()
            .required('End Time is required').min(Yup.ref('session_start_time'),
                () => 'End time must be greater than start time'),
        rooms: Yup.string().trim().required('Room is required!'),
        sessionfloorTitle: Yup.string()
            .trim()
            .required('Floor title is required!'),
        description: Yup.string()
            .trim()
            .required('Description is required!')
    });

    addZero = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    submitSession = (values) => {

        let sessionStartDateTime = values.session_date;
        sessionStartDateTime = new Date(sessionStartDateTime).setHours(this.addZero(new Date(values.session_start_time).getHours()))
        sessionStartDateTime = new Date(sessionStartDateTime).setMinutes(this.addZero(new Date(values.session_start_time).getMinutes()))
        sessionStartDateTime = new Date(sessionStartDateTime).setSeconds(this.addZero(new Date(values.session_start_time).getSeconds()))
        sessionStartDateTime = new Date(sessionStartDateTime);

        let sessionEndDateTime = values.session_date;
        sessionEndDateTime = new Date(sessionEndDateTime).setHours(this.addZero(new Date(values.session_end_time).getHours()))
        sessionEndDateTime = new Date(sessionEndDateTime).setMinutes(this.addZero(new Date(values.session_end_time).getMinutes()))
        sessionEndDateTime = new Date(sessionEndDateTime).setSeconds(this.addZero(new Date(values.session_end_time).getSeconds()))
        sessionEndDateTime = new Date(sessionEndDateTime);

        let agendaDetails = {
            'name': values.sessionName.trim(),
            'date': values.session_date,
            'start_time': sessionStartDateTime,
            'end_time': sessionEndDateTime,
            'start_date_time': sessionStartDateTime,
            'end_date_time': sessionEndDateTime,
            'room': values.rooms.value,
            'private': values.privateRoom,
            'description': values.description,
            'floor_title': values.sessionfloorTitle,
            'event_id': this.state.eventID
        }
        this.setState({ disableBtn: true });

        if (this.state.agendaId !== undefined && this.state.agendaId !== null && this.state.agendaId !== "") {

            agendaDetails._id = this.state.agendaId;

            api.updateAgenda(agendaDetails).then((data) => {
                this.setState({ disableBtn: false });
                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: "Session updated successfully"
                        })

                        var index = this.state.agendaDetails.findIndex(
                            agendas => agendas._id === this.state.agendaId
                        );
                        
                        var agendas = this.state.agendaDetails;
                        agendas[index] = data.data.result;

                        this.setState({ agendaDetails: agendas, agendaId: "" }, () => {
                            this.props.saveSessions(this.state.agendaDetails);
                        })

                        this.closeSessionModal();
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }

            });

        } else {
            if (this.state.eventID !== "") {
                // var opentok = new OpenTok(process.env.REACT_APP_OPENTOK_KEY, process.env.REACT_APP_OPENTOK_SECRET);
                // opentok.createSession({ mediaMode: "routed" }, (error, session) => {
                //     if (error) {
                //         console.log(error)
                //         Toast.fire({
                //             icon: 'warning',
                //             title: "Unable to create Event. Please try again!"
                //         });
                //         this.setState({ disableBtn: false });
                //     } else {
                //         agendaDetails.session_id = session.sessionId;
                //         agendaDetails.publisher_token = session.generateToken({
                //             role: 'moderator',
                //             expireTime: (new Date().getTime() / 1000) + (30 * 24 * 60 * 60),
                //             initialLayoutClassList: ['focus']
                //         });
                //         agendaDetails.listener_token = session.generateToken({
                //             role: 'subscriber',
                //             expireTime: (new Date().getTime() / 1000) + (30 * 24 * 60 * 60),
                //             initialLayoutClassList: ['focus']
                //         });
                        api.addAgenda(agendaDetails).then((data) => {
                            this.setState({disableBtn: false});
                            if (data && data !== undefined && data !== null && data !== "") {
                                if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                    Toast.fire({
                                        icon: 'success',
                                        title: "Session Added Successfully"
                                    })
                                    this.setState({
                                        agendaDetails: [...this.state.agendaDetails, data.data.result]
                                    }, () => {
                                        this.props.saveSessions(this.state.agendaDetails);
                                    })
                                    this.closeSessionModal();
                                } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                    Toast.fire({
                                        icon: 'warning',
                                        title: "Unauthorized Access"
                                    })
                                } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                    Toast.fire({
                                        icon: 'warning',
                                        title: "Please revalidate the form and submit"
                                    })
                                } else {
                                    Toast.fire({
                                        icon: 'warning',
                                        title: "Something went wrong. Please try again!"
                                    })
                                }
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "No response from the server. Please try again!"
                                })
                            }

                        });
            //         }
            //     });
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. Please refresh page!"
                });
                this.closeSessionModal();
            }
        }

    }

    sessionForm = ({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        handleReset,
        setFieldValue,
    }) => {
        return (
            <React.Fragment>
                <Form onSubmit={handleSubmit}>
                    <div className="">
                        <div className=" agenda-popup">
                            <div className="d-flex justify-content-end popup-close-btn" onClick={() => this.closeSessionModal()}>&times;</div>
                            <div className="popup-content">
                                <div className="lang-header mt10">Add Session</div>
                                <div className="d-flex interpre-blk">
                                    <div className="agenda-wrapper mr15">
                                        <div className="room-name-label">Session Name<span className="star-imp">*</span></div>
                                        <Field type="text" maxLength="1500" name="sessionName" className="form-input modal-form-input" />
                                        <ErrorMessage name="sessionName" component="div" className="validtxt_msg" />
                                    </div>
                                    <div className="agenda-wrapper" style={{ 'marginTop': '0px' }}>
                                        <div className="room-name-label">Date<span className="star-imp">*</span></div>
                                        <DatePicker
                                            selected={values.session_date}
                                            onChange={(e) => {
                                                setFieldValue('session_date', e);
                                            }}
                                            onChangeRaw={(e) => { e.preventDefault() }}
                                            excludeOut
                                            className="form-input modal-form-input"
                                            timeIntervals={10}
                                            dateFormat="MMMM d, yyyy "
                                            timeCaption="time"
                                            placeholderText=""
                                            minDate={moment(this.state.eventDate).toDate()}
                                            maxDate={moment(this.state.eventDate).toDate()}
                                        />
                                        <ErrorMessage name="session_date" component="div" className="validtxt_msg" />
                                    </div>

                                </div>
                                <div className="d-flex interpre-blk">
                                    <div className="agenda-wrapper mr15">
                                        <div className="room-name-label">Start Time<span className="star-imp">*</span></div>
                                        <DatePicker
                                            selected={values.session_start_time}
                                            onChange={(e) => {
                                                setFieldValue('session_start_time', e);
                                            }}
                                            onChangeRaw={(e) => { e.preventDefault() }}
                                            className="form-control text-input"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText=""
                                            minTime={setHours(setMinutes(new Date(), new Date(this.state.eventStartTime).getMinutes()), new Date(this.state.eventStartTime).getHours())}
                                            maxTime={setHours(setMinutes(new Date(), new Date(this.state.eventEndTime).getMinutes()), new Date(this.state.eventEndTime).getHours())}
                                        />
                                        <ErrorMessage name="session_start_time" component="div" className="validtxt_msg" />
                                    </div>

                                    <div className="agenda-wrapper">
                                        <div className="room-name-label">End Time<span className="star-imp">*</span></div>
                                        <DatePicker
                                            selected={values.session_end_time}
                                            onChange={(e) => {
                                                setFieldValue('session_end_time', e);
                                            }}
                                            onChangeRaw={(e) => { e.preventDefault() }}
                                            className="form-control text-input"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText=""
                                            minTime={setHours(setMinutes(new Date(), new Date(this.state.eventStartTime).getMinutes()), new Date(this.state.eventStartTime).getHours())}
                                            maxTime={setHours(setMinutes(new Date(), new Date(this.state.eventEndTime).getMinutes()), new Date(this.state.eventEndTime).getHours())}
                                        />
                                        <ErrorMessage name="session_end_time" component="div" className="validtxt_msg" />
                                    </div>

                                </div>

                                <div className="d-flex interpre-blk">
                                    <div className="agenda-wrapper mr15">
                                        <div className="room-name-label">Room<span className="star-imp">*</span></div>
                                        <Select
                                            name="rooms"
                                            value={values.rooms}
                                            options={this.rooms}
                                            // className="custom-droplst custom-chev-down"
                                            onChange={e => setFieldValue('rooms', e)}
                                            onBlur={handleBlur}
                                            style={{ display: 'block' }}
                                        />
                                        <ErrorMessage name="rooms" component="div" className="validtxt_msg" />
                                    </div>


                                    <div className="agenda-wrapper">
                                         <div className="room-name-label">Floor Title<span className="star-imp">*</span></div>
                                        <Field type="text" maxLength="1500" name="sessionfloorTitle" className="form-input modal-form-input" />
                                        <ErrorMessage name="sessionfloorTitle" component="div" className="validtxt_msg" />
                                       
                                    </div>

                                </div>

                                <div className="d-flex interpre-blk">
                                    <div className="agenda-wrapper mr15">
                                    <div className="room-name-label">Description<span className="star-imp">*</span></div>
                                        <textarea
                                            className="form-input modal-form-input txt-area-desc" 
                                            type="text"
                                            name="description"
                                            onChange={e => setFieldValue('description', e.currentTarget.value)}
                                            maxLength="1500"
                                            value={values.description}
                                        ></textarea>
                                        <ErrorMessage name="description" component="div" className="validtxt_msg" />
                                    </div>
                                    <div className="agenda-wrapper">
                                        <div className="agenda-type" style={{ "marginTop": "40px" }}>
                                            <div className="mod-checkbox-wrapper">
                                                <label className="mod-custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="privateRoom"
                                                        value={values.privateRoom}
                                                        checked={values.privateRoom === true ? "checked": ""}
                                                        onChange={() => { setFieldValue('privateRoom', !values.privateRoom); }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <div className="mod-checkbox-label">Private</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center modal-btn-blk room-btn-blk">
                                    <button type="button" className="modal-cancel-btn" onClick={() => this.closeSessionModal()}>Cancel</button>
                                    {
                                        this.state.agendaId !== undefined && this.state.agendaId !== null && this.state.agendaId !== ""
                                            ?
                                            <button type="submit" className="modal-save-btn" disabled={this.state.disableBtn}>
                                                {this.state.disableBtn === true ? 'Updating...' : 'Update'}
                                                {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                            </button>
                                            :
                                            <button type="submit" className="modal-save-btn" disabled={this.state.disableBtn}>
                                                {this.state.disableBtn === true ? 'Submitting...' : 'Submit'}
                                                {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                            </button>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                </Form>
            </React.Fragment>
        )
    }

    editAgenda = (val) => {

        let room = this.rooms.filter(room => (room.value === val.room)) !== undefined && this.rooms.filter(room => (room.value === val.room))[0] !== undefined && this.rooms.filter(room => (room.value === val.room))[0].label !== undefined ? this.rooms.filter(room => (room.value === val.room))[0] : '';

        this.initialValues.sessionName = val.name;
        this.initialValues.session_date = new Date(moment(val.start_date_time).format("YYYY-MM-DD HH:mm"));
        this.initialValues.session_start_time = new Date(moment(val.start_time).format("YYYY-MM-DD HH:mm"));
        this.initialValues.session_end_time = new Date(moment(val.end_time).format("YYYY-MM-DD HH:mm"));
        this.initialValues.rooms = room;
        this.initialValues.privateRoom = val.private;
        this.initialValues.description = val.description;
        this.initialValues.sessionfloorTitle = val.floor_title;

        this.setState({ agendaId: val._id });

        this.openSessionModal();

    }

    deleteAgenda = (id, index) => {
        Swal.fire({
            title: 'Delete Session',
            text: "Are you sure you want to delete? This will delete the interpreters corresponding to this session",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes, Proceed",
            cancelButtonText: 'No, cancel',
            confirmButtonColor: '#00d2a5',
            customClass: {
                confirmButton: 'green-bg-white-f-btn'
            },
            focusConfirm: false,
            focusCancel: true
        }).then((result) => {
            if (result.value) {
                try {
                    api.deleteAgenda(id).then((data) => {
                        if (data && data !== undefined && data !== null && data !== "") {
                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Session deleted successfully"
                                })
                                this.state.agendaDetails.splice(index, 1);
                                this.setState({ agendaDetails: [...this.state.agendaDetails] }, () => {
                                    this.props.saveSessions(this.state.agendaDetails);
                                });

                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Unauthorized Access"
                                })
                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Please revalidate the form and submit"
                                })
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            }
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "No response from the server. Please try again!"
                            })
                        }
                    });
                } catch (e) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            }
        })

    }

    render() {
        return (
            <React.Fragment>

                <div className="agenda-blk">

                    <div className="add-room-wrapper">
                        <div className="d-flex align-items-center add-room-txt" onClick={() => this.openSessionModal()}><span className="add-room-plus">+</span>Add Session</div>
                    </div>

                    {this.state.agendaDetails.map((value, key) => {
                        let room = this.rooms.filter(room => (room.value === value.room)) !== undefined && this.rooms.filter(room => (room.value === value.room))[0] !== undefined  && this.rooms.filter(room => (room.value === value.room))[0].label !== undefined ? this.rooms.filter(room => (room.value === value.room))[0].label: '';

                        return (
                            <div className="session-block" key={"sessions_"+key}>
                                <div className="d-flex agenda-wraper">
                                    <div className="agenda-container">
                                        <label className="formgrp-label">Session Name</label>
                                        <div className="form-input" placeholder="" title={value.name}> {value.name} </div>
                                    </div>
                                    <div className="agenda-container">
                                        <label className="formgrp-label">Date</label>
                                        <div className="form-input" placeholder=""> {moment(value.start_date_time).format("MMMM DD, yyyy")} </div>
                                    </div>
                                    <div className="agenda-container">
                                        <label className="formgrp-label">Start Time</label>
                                        <div className="form-input" placeholder="" >{moment(value.start_time).format("hh:mm A")} </div>
                                    </div>
                                    <div className="agenda-container">
                                        <label className="formgrp-label">End Time</label>
                                        <div className="form-input" placeholder="" > {moment(value.end_time).format("hh:mm A")} </div>
                                    </div>
                                    <div className="agenda-container">
                                        <label className="formgrp-label">Room</label>
                                        <div className="form-input" title={room}>{room}</div>
                                    </div>
                                    <div className="agenda-container">
                                        <label className="formgrp-label">Description</label>
                                        <div className="form-input" placeholder="" title={value.description}> {value.description}</div>
                                    </div>
                                    <div className="agenda-container">
                                        <label className="formgrp-label">Floor Title</label>
                                        <div className="form-input" placeholder="" title={value.floor_title}> {value.floor_title} </div>
                                    </div>
                                    <div className="mobile-icon-single">
                                        <div className="d-flex aganta-type">
                                            <label className="custom-checkbox">
                                                <input type="checkbox" checked={value.private} readOnly />
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className="checkbox-label">Private</div>
                                        </div>
                                        
                                        {this.eventID !== undefined && this.eventID !== null && this.eventID !== "" && (
                                            <div className="agenda-del-blk">
                                                <div className="d-flex align-items-center justify-content-center room-del-btn" onClick={() => this.editAgenda(value)}><img src={require("../../img/edit-pencil.svg")} alt="edit room" /></div>
                                            </div>
                                        )}
                                        <div className="agenda-del-blk">
                                            <div className="d-flex align-items-center justify-content-center room-del-btn" onClick={() => this.deleteAgenda(value._id, key)}><img src={require("../../img/trash.svg")} alt="deleteImg" /></div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.streamOut && (
                                    <div className="rtmp-stream-info">
                                        <div className="stream-info-text info">
                                            <span className="stream-info-heading"><span>INFO</span><span>:</span></span>
                                            <div className="stream-info-desc">
                                                Download and install OBS software from <a className="rtmp-url-val" href="https://obsproject.com/download" target="_blank" rel="noopener noreferrer">https://obsproject.com/download</a> and put the following details on streaming tab to start the external streaming.
                                        </div>
                                        </div>
                                        <div className="stream-info-text">
                                            <div className="stream-info-heading"><span>RTMP URL</span><span>:</span></div><div className="rtmp-url-val">rtmp://wowza.rafikyconnect.net/rafky</div> </div>
                                        <div className="stream-info-text">
                                            <div className="stream-info-heading"><span>STREAM KEY</span><span>:</span></div> Floor_{value._id}
                                        </div>
                                        <div className="rtmp-note-txt"><span>NB :</span>If multiple languages are added, language key will be the stream key</div>
                                    </div>
                                )}                                    
                            </div>
                        )
                    })}
                    
                </div>
                <div className="d-flex align-items-center btn-acn-blk">
                    <button type="button" onClick={() => this.props.stepToLanguage()} className="prev-btn">Prev</button>
                    <button type="button" disabled={this.state.agendaDetails.length > 0 ? false : true} onClick={() => this.props.stepToInterpreter()} className="next-btn">Next</button>
                </div>

                <Modal isOpen={this.state.isModalOpen} fade={true} centered className={'double-modal-dialog'}>
                    <Formik
                        initialValues={this.initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.submitSession}
                    >
                        {this.sessionForm}
                    </Formik>
                </Modal>
            </React.Fragment>
        );
    }
}