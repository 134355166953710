
import React from "react";
import helper from '../../service/helper';
import avatarIcon from "../../assets/img/avatar.webp";
import ReactImageFallback from "react-image-fallback";
import jwt from "jwt-simple";


export default class Header extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userRole : "",
            meetingCode: ''
        }
        this.userCredentials = helper.decodeEncodedItem(localStorage.getItem("userDetails"));
    }

    componentDidMount() {
        if (localStorage.getItem('eventCodeUser') !== null && localStorage.getItem('eventCodeUser') !== "") {
            let eventDetails = jwt.decode(localStorage.getItem('eventCodeUser'), process.env.REACT_APP_JWT_SECRET, 'HS512');
            if (eventDetails !== undefined && eventDetails !== null && eventDetails !== "") {
                if (eventDetails.user_role !== undefined) {
                    this.setState({ userRole : eventDetails.user_role })
                }

                if (eventDetails.meetingCode !== undefined && eventDetails.meetingCode !== null && eventDetails.meetingCode !== '') {
                    this.setState({ meetingCode: eventDetails.meetingCode })
                }
            }
        }
    }

    logout = () => {
        this.props.history.push('/logout');
    }

    render() {
        return (
            <div className="justify-content-between vdo-top-part event-header-mobile meet-room-header">
                <div className="d-flex align-items-center">
                    <div className="logoo">
                        {this.state.meetingCode.substring(0, 2) !== 'l-' && (
                            this.props.logoIMG !== "" && this.props.logoIMG !== undefined
                                ?
                                <img style={{ "width": 'unset', "maxWidth": '100%', "height": '60px' }} src={this.props.logoIMG} alt="logo" />
                                :
                                <img alt="logo" src={require("../../img/logo-2-01.png")} style={{ "width": "150px" }} />
                            
                        )}
                        
                    </div>
                </div>
                {this.userCredentials && this.userCredentials !== "" && this.userCredentials !== null &&
                    this.userCredentials !== undefined ? <>
                        <div className="vdo-top-right-part">
                            <div className="event-avatar-blk">
                                {/* <img alt="avatar" src={require("../../img/massey.png")} className="object-cover" /> */}
                                {
                                    (this.userCredentials && this.userCredentials.profile_pic && this.userCredentials.profile_pic !== undefined && this.userCredentials.profile_pic !== "")
                                        ?
                                        <ReactImageFallback src={(this.userCredentials.profile_pic !== undefined && this.userCredentials.profile_pic !== "") ? this.userCredentials.profile_pic : ""} fallbackImage={avatarIcon} initialImage={avatarIcon} alt="profile pics" className="avatar" />
                                        :
                                        <div className="avatar_initial">
                                            <div className="avatar_initial_text">
                                                {helper.Capitalize((this.userCredentials.name).charAt(0))}
                                            </div>
                                        </div>
                                }
                            </div>
                            <span className="username" >{this.userCredentials.name}</span>
                            <div className="event-avatar-blk" title='logout' onClick={() => this.logout()} >
                                <img alt="avatar" src={require("../../img/logout.svg")} className="object-cover" />
                            </div>
                        </div>
                        <div className="schedule-toggler-blk">
                            <div className="schedule-toggler"><img alt="dots" src={require("../../img/vdots.svg")} /></div>
                        </div> </> :
                    this.state.userRole === "listener" && (
                        <a href="https://conference.rafikyconnect.net/teams-login" className="event-avatar-blk" title='logout' >
                            <img alt="avatar" src={require("../../img/logout.svg")} className="object-cover" />
                        </a>
                    )

                }
            </div>
        );
    }
}



