import React from "react";
import apiEventService from "../service/api.eventService";
import Swal from 'sweetalert2';
import Header from "./includes/header";
import moment from "moment";
import 'moment-timezone';
import helper from '../service/helper';
import jwt from "jwt-simple";
import { Modal } from 'reactstrap';
import Loader from 'react-loader-spinner';

import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import jsPDF from 'jspdf';
import 'jspdf-autotable'
import html2canvas from 'html2canvas';
import ClipLoader from "react-spinners/ClipLoader";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";

// import { isMobile, isMobileSafari } from "react-device-detect";

// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;




// import Pagination from "react-js-pagination";


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export default class EventList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // activePage: 1,
            // itemsCountPerPage: 10,
            eventList: [],
            eventRooms: [],
            eventAgenda: [],
            eventCode: '',
            fetchEventId: "",
            eventStatus: '', //2 upcoming event, 1 current event, 0 past event
            pastEvents: [],
            currentEvents: [],
            upcomingEvents: [],
            moderator: false,
            eventInfo: "",
            isModalOpen: false,
            logoIMG: '',
            interpreterRoomDetails: [],
            fetchEvents: false,
            fetchRooms: false,
            fetchSessions: false,
            fetchPollReport: false,
            fetchUserReport: false,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            eventRepeatStatus: false,
            showSessions: false,
            showPollReports: false,
            pollsList: [],
            pollreports: [],
            userstreamreports: [],
            originalStreamReport:[],
            allowUserReportDisplay: false,
            showEventUserStreamReport: false,
            accordionPanel: "panel1a",
            exportUserStreamReports: [],
            fetchedEventDetails: "",
            exportingPollReport: false,
            exportingStreamReport: false,
            joiningMeeting: false,
            archives: [],
            showEventRecordings: false,
            selectedDateFilter: new Date(),
            dateFilterActive:false,
            csvStreamRecordData:[],
            apiresponse:false
        }
        this.userCredentials = helper.decodeEncodedItem(localStorage.getItem("userDetails"));
        this.eventData = '';
        this.handleAccordionChange = this.handleAccordionChange.bind(this);

    }

    handleAccordionChange = (panel) => (event, isExpanded) => {
        this.setState({ accordionPanel: isExpanded ? panel : false })
    }

    componentDidMount() {
        if (localStorage.getItem('eventCodeUser') !== null && localStorage.getItem('eventCodeUser') !== "") {
            setTimeout(() => {
                this.setState({apiresponse:true})
            }, 1000); 
            let eventDetails = jwt.decode(localStorage.getItem('eventCodeUser'), process.env.REACT_APP_JWT_SECRET, 'HS512');

            if (eventDetails.eventDetails !== undefined && eventDetails.eventDetails !== "") {
                this.eventData = eventDetails.eventDetails[0];

                let dt1 = new Date(this.eventData.start_time);
                let dt2 = new Date(this.eventData.end_time);

                let updatedEventStartTime;
                if (this.eventData.start_date_time !== undefined && this.eventData.start_date_time !== null && this.eventData.start_date_time !== "") {
                    updatedEventStartTime = moment(this.eventData.start_date_time).tz(this.state.timezone).format();
                } else {
                    updatedEventStartTime = moment(this.eventData.date).tz(this.state.timezone).format();
                }

                updatedEventStartTime = new Date(updatedEventStartTime).setHours(this.addZero(new Date(this.eventData.start_time).getHours()))
                updatedEventStartTime = new Date(updatedEventStartTime).setMinutes(this.addZero(new Date(this.eventData.start_time).getMinutes()))
                updatedEventStartTime = new Date(updatedEventStartTime).setSeconds(this.addZero(new Date(this.eventData.start_time).getSeconds()))
                updatedEventStartTime = new Date(updatedEventStartTime);

                let updatedEventEndTime = new Date(updatedEventStartTime);
                updatedEventEndTime.setMinutes(updatedEventEndTime.getMinutes() + this.diff_minutes(dt1, dt2));
                updatedEventEndTime = moment(updatedEventEndTime).format('YYYY-MM-DD HH:mm:ss');

                this.eventData.event_start_time = updatedEventStartTime;
                this.eventData.event_end_time = updatedEventEndTime;
                this.eventData.loggedin_user_id = eventDetails.loggedInUserId;

                if (eventDetails.user_role === 'interpreter') {
                    this.sessionDataDetails = (eventDetails.sessionDetails !== undefined && eventDetails.sessionDetails !== null) ? eventDetails.sessionDetails : "";
                }
                this.eventData.user_role = eventDetails.user_role;
                this.eventData.user_name = eventDetails.name;
                this.eventData.user_email = eventDetails.mail;
                this.eventData.eventId = this.eventData._id;
                this.eventData.meetingCode = eventDetails.meetingCode;
                this.eventData.user_selected_language = eventDetails.user_selected_language;

                this.setState({ logoIMG: eventDetails.logo, moderator: false })
                if (eventDetails.user_role !== undefined && eventDetails.user_role !== "") {
                    if (eventDetails.user_role === 'moderator') {
                        this.setState({ moderator: true });
                    }

                    if (eventDetails.user_role === 'interpreter') {
                        let getRoomDetails = {
                            session_id: eventDetails.sessionDetails
                        }
                        apiEventService.getRoomDetails(getRoomDetails).then((data) => {
                            if (data && data !== undefined && data !== null && data !== "") {
                                if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                    if (data.data.status && data.data.status === true && data.data.data && data.data.data.length > 0) {
                                        this.setState({ interpreterRoomDetails: data.data.data });
                                    }
                                }
                            }
                        });
                    }

                }
                this.sortEventList(eventDetails.eventDetails, true);
                if (eventDetails.eventDetails.length > 0) {
                    if(document.getElementById("landingbg")){
                        document.getElementById("landingbg").style.backgroundImage = "url('"+process.env.REACT_APP_API_IMAGE_URL+eventDetails.eventDetails[0].landing_page_bg+"')";
                        document.getElementById("landingbg").style.backgroundPosition = "center";
                        document.getElementById("landingbg").style.backgroundRepeat = " no-repeat";
                        document.getElementById("landingbg").style.backgroundSize = "cover";
                        document.getElementById("landingbg").style.margin = "0px";
                        document.getElementById("landingbg").style.borderRadius = "0px";
                        document.getElementById("landingbg").classList.add('landingbg');
                        document.getElementById("landingbg").classList.add('shadowtxt');
                        
                        
                    }
                }
                
            }
        } else {
            this.setState({ moderator: true });
            if (localStorage.getItem('userDetails') !== null && localStorage.getItem('userDetails') !== "") {
                this.getEventDetails();
            } else {
                this.props.history.push('/');
            }
        }
    }

    diff_minutes = (dt2, dt1) => {

        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));

    }

    diff_hours = (dt2, dt1) => {

        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs((diff));

    }

    diff_weeks = (dt2, dt1) => {

        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7);
        return Math.abs(Math.ceil(diff));

    }


    sortEventList = (eventlist, flag = false) => {
        this.setState({
            pastEvents: [],
            currentEvents: [],
            upcomingEvents: []
        });
        let currentDate = moment(helper.getDate(new Date())).format('YYYY-MM-DD');
        // let currentTime = moment(new Date()).format('HH:mm');
        // let currentDay = moment(new Date()).day();
        let currentEventList = [];
        let pastEventList = [];
        let upcomingEventList = [];
        if (eventlist !== "" && eventlist !== undefined && eventlist.length > 0) {

            eventlist.map((event, key) => {                

                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let meetingDate;
                if (event.start_date_time !== undefined && event.start_date_time !== null && event.start_date_time !== "") {
                    meetingDate = moment(event.start_date_time).tz(timezone).format('YYYY-MM-DD');
                } else {
                    meetingDate = moment(event.date).tz(timezone).format('YYYY-MM-DD');
                }

                let startDateTime = (event.start_date_time !== undefined && event.start_date_time !== null && event.start_date_time !== "") ? new Date(event.start_date_time) : "";
                let eventDate = new Date(event.date);

                if (event.repeatWeekly === true) {

                    if (meetingDate < currentDate) {

                        let numOfWeeksForEventDate = this.diff_weeks(new Date(), eventDate);

                        if (startDateTime !== undefined && startDateTime !== null && startDateTime !== "") {

                            let numOfWeeksForEventStartDateTime = this.diff_weeks(new Date(), startDateTime);

                            startDateTime = startDateTime.setDate(startDateTime.getDate() + numOfWeeksForEventStartDateTime * 7);
                        }

                        eventDate = eventDate.setDate(eventDate.getDate() + numOfWeeksForEventDate * 7);

                    }
                }

                // let meetingDay = moment(helper.getDate(event.date)).day();
                let updatedCurrentTime = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');

                let eventStartDateTime;
                if (startDateTime !== undefined && startDateTime !== null && startDateTime !== "") {
                    eventStartDateTime = moment(startDateTime).format('MM/DD/YYYY HH:mm:ss');
                } else {
                    eventStartDateTime = moment(eventDate).format('MM/DD/YYYY HH:mm:ss');
                }

                let eventStartTime = new Date(eventStartDateTime);
                eventStartTime = new Date(eventStartTime).setHours(this.addZero(new Date(event.start_time).getHours()))
                eventStartTime = new Date(eventStartTime).setMinutes(this.addZero(new Date(event.start_time).getMinutes()))
                eventStartTime = new Date(eventStartTime).setSeconds(this.addZero(new Date(event.start_time).getSeconds()))
                eventStartTime = new Date(eventStartTime);


                let updatedEventStartTime = moment(eventStartTime).tz(timezone).format('MM/DD/YYYY HH:mm:ss');
                let dts1 = new Date(event.start_time);
                let dts2 = new Date(event.end_time);

                var updatedEventEndTime = new Date(eventStartTime);
                updatedEventEndTime.setMinutes(updatedEventEndTime.getMinutes() + this.diff_minutes(dts1, dts2));
                updatedEventEndTime = moment(updatedEventEndTime).format('MM/DD/YYYY HH:mm:ss')

                if (new Date(updatedEventStartTime) <= new Date(updatedCurrentTime) && new Date(updatedEventEndTime) >= new Date(updatedCurrentTime)) {
                    if (flag === true) {
                        this.viewRooms(event._id, event.event_code, 1, event.repeatWeekly);
                    }
                    currentEventList.push(event);
                } else if (new Date(updatedEventStartTime) > new Date(updatedCurrentTime)) {
                    if (flag === true) {
                        this.viewRooms(event._id, event.event_code, 1, event.repeatWeekly);
                    }
                    currentEventList.push(event);
                } else {
                    if (flag === true) {
                        this.viewRooms(event._id, event.event_code, 0, event.repeatWeekly);
                    }
                    pastEventList.push(event);
                }

                // if (meetingDate > currentDate) {
                //     if (flag === true) {
                //         this.viewRooms(event._id, event.event_code, 1);
                //     }
                //     currentEventList.push(event);
                // } else if (meetingDate === currentDate) {

                //     if (new Date(updatedEventStartTime) <= new Date(updatedCurrentTime) && new Date(updatedEventEndTime) >= new Date(updatedCurrentTime)) {
                //         if (flag === true) {
                //             this.viewRooms(event._id, event.event_code, 1);
                //         }
                //         currentEventList.push(event);
                //     } else if (new Date(updatedEventStartTime) > new Date(updatedCurrentTime)) {
                //         if (flag === true) {
                //             this.viewRooms(event._id, event.event_code, 1);
                //         }
                //         currentEventList.push(event);
                //     } else {
                //         if (flag === true) {
                //             this.viewRooms(event._id, event.event_code, 0);
                //         }
                //         pastEventList.push(event);
                //     }
                // } else if (meetingDate < currentDate) {
                //     if (event.repeatWeekly === true && meetingDay === currentDay) {
                //         if (flag === true) {
                //             this.viewRooms(event._id, event.event_code, 1);
                //         }
                //         currentEventList.push(event);
                //     } else {
                //         if (flag === true) {
                //             this.viewRooms(event._id, event.event_code, 0);
                //         }
                //         pastEventList.push(event);
                //     }
                // }
                return true;
            })

            this.setState({
                pastEvents: pastEventList,
                currentEvents: currentEventList,
                upcomingEvents: upcomingEventList
            })
        }
    }

    getEventDetails = () => {
        if (this.userCredentials && this.userCredentials !== null &&
            this.userCredentials !== "" && this.userCredentials !== undefined && this.userCredentials.id && this.userCredentials.id !== null &&
            this.userCredentials.id !== "" && this.userCredentials.id !== undefined) {
            let getEventDetails = {
                user_id: this.userCredentials.id,
                finish: true
            }

            this.setState({ fetchEvents: true });

            apiEventService.getEvents(getEventDetails).then((data) => {
                setTimeout(() => {
                    this.setState({apiresponse:true})
                }, 1000); 
                this.setState({ fetchEvents: false });

                try {
                    if (data && data !== undefined && data !== null && data !== "") {
                        if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                            if (data.data.status && data.data.status === true && data.data.data && data.data.data.length > 0) {
                                this.sortEventList(data.data.data);
                                this.setState({ eventList: data.data.data })
                            }
                        } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                            Toast.fire({
                                icon: 'warning',
                                title: "Unauthorized Access"
                            })
                        } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                            Toast.fire({
                                icon: 'warning',
                                title: "Please revalidate the form and submit"
                            })
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "Something went wrong. Please try again!"
                            })
                        }
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "No response from the server. Please try again!"
                        })
                    }
                } catch(e) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    }) 
                }
            });
        } else {
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please try again!"
            })
            setTimeout(() => {
                this.setState({apiresponse:true})
            }, 1000); 
        }

    }

    viewRooms = (eventID, eventCode, flag, repeeatWeekly = false, showreport = false, archives = []) => {

        this.setState({
            fetchEventId: eventID,
            eventRooms: [],
            eventAgenda: [],
            pollreports: [],
            userstreamreports: [],
            showSessions: false,
            showPollReports: false,
            showEventUserStreamReport: false,
            allowUserReportDisplay: showreport,
            eventCode: '',
            eventStatus: flag,
            eventRepeatStatus: repeeatWeekly,
            joiningMeeting: false,
            archives: archives,
            showEventRecordings: false
        });
        if (showreport === true && this.userCredentials && this.userCredentials !== null && this.userCredentials !== "" && this.userCredentials !== undefined) {
            let meetingListClass = document.querySelectorAll(".meeting_list");
            for (let i = 0; i < meetingListClass.length; i++) {
                meetingListClass[i].classList.remove('active');
            }
            let addActiveClass = document.getElementsByClassName("meeting_" + eventID)
            addActiveClass[0].className += " active";
        }
        if (eventID !== null && eventID !== "" && eventID !== undefined) {

            this.setState({ fetchRooms: true, pollsList: [] });

            let eventDetails = {
                event_id: eventID
            }
            apiEventService.getEventRooms(eventDetails).then((data) => {

                this.setState({ fetchRooms: false });

                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        let eventRoomDetails = data.data.data;
                        let roomArray = [];

                        if (this.eventData.user_role === 'interpreter') {

                            let getRoomDetails = {
                                session_id: this.sessionDataDetails
                            }
                            apiEventService.getRoomDetails(getRoomDetails).then((data) => {
                                if (data && data !== undefined && data !== null && data !== "") {
                                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                        if (data.data.status && data.data.status === true && data.data.data && data.data.data.length > 0) {
                                            this.setState({ interpreterRoomDetails: data.data.data }, () => {

                                                if (this.state.interpreterRoomDetails.length > 0) {
                                                    this.state.interpreterRoomDetails.map((roomDetails) => {
                                                        let roomData = eventRoomDetails.filter(eventRoom => (eventRoom._id === roomDetails.room));
                                                        if (roomData !== "" && roomData.length > 0) {
                                                            let alreadyExisting = roomArray.filter((rooms) => JSON.stringify(rooms) === JSON.stringify(roomData[0]));
                                                            if (alreadyExisting.length === 0) {
                                                                roomArray.push(roomData[0]);
                                                            }
                                                        }
                                                        return true;
                                                    });
                                                    this.setState({ eventRooms: roomArray, eventCode: eventCode });
                                                }
                                            });
                                        }
                                    }
                                }
                            });



                        } else {
                            if (data.data.status && data.data.status === true) {
                                this.setState({ eventRooms: data.data.data, eventCode: eventCode });
                            }
                        }


                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }
            });

            if (showreport === true) {
                apiEventService.getSessionsForEvent(eventDetails).then((data) => {
                    
                    if(data !== undefined && data !== null && data !== "") {
                        if(data.status !== undefined && data.status !== null && data.status === 200) {
                            if (data.data.data !== undefined && data.data.data !== null && data.data.data !== "") {
                                var sessionsList = data.data.data;

                                if (sessionsList !== undefined && sessionsList !== null && sessionsList !== "" && sessionsList.length > 0) {
                                    let sessionIdArray = [];

                                    for (var s = 0; s < sessionsList.length; s++) {
                                        var sessionId = sessionsList[s]['_id'];
                                        sessionIdArray.push(sessionId)
                                    }

                                    if (sessionIdArray !== undefined && sessionIdArray !== null && sessionIdArray !== "" && sessionIdArray.length > 0) {

                                        let pollFilter = {
                                            session_id: sessionIdArray
                                        }

                                        apiEventService.getPollsForEvent(pollFilter).then((data) => {

                                            if (data !== undefined && data !== null && data !== "") {
                                                if (data.status !== undefined && data.status !== null && data.status === 200) {
                                                    if (data.data.data !== undefined && data.data.data !== null && data.data.data !== "") {
                                                        var pollsList = data.data.data;
                                                        pollsList['event_id'] = eventID;
                                                        if (pollsList !== undefined && pollsList !== null && pollsList !== "" && pollsList.length > 0) {
                                                            this.setState({ pollsList: pollsList });
                                                        }
                                                    }
                                                }
                                            }

                                        })

                                    }
                                }
                            }
                        }
                    }
                })
            }

        }
    }

    addZero = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    viewRecordings = () => {

        let pollListClass = document.querySelectorAll(".poll_list");
        for (let i = 0; i < pollListClass.length; i++) {
            pollListClass[i].classList.remove('active');
        }

        let roomListClass = document.querySelectorAll(".room_list");
        for (let i = 0; i < roomListClass.length; i++) {
            roomListClass[i].classList.remove('active');
        }

        this.setState({ showSessions: false, showEventUserStreamReport: false, showPollReports: false, showEventRecordings: true });
    }

    viewUserReport = () => {

        let pollListClass = document.querySelectorAll(".poll_list");
        for (let i = 0; i < pollListClass.length; i++) {
            pollListClass[i].classList.remove('active');
        }

        let roomListClass = document.querySelectorAll(".room_list");
        for (let i = 0; i < roomListClass.length; i++) {
            roomListClass[i].classList.remove('active');
        }

        if (this.state.fetchEventId !== undefined && this.state.fetchEventId !== null && this.state.fetchEventId !== "") {
            let eventDetails = this.state.eventList.filter(event => (event._id === this.state.fetchEventId))[0];
            this.setState({
                fetchedEventDetails: eventDetails
            })
        }

        this.setState({ fetchUserReport: true, showSessions: false, showEventUserStreamReport: true, showPollReports: false, showEventRecordings: false });

        let eventDetails = {
            event_id: this.state.fetchEventId
        }
        apiEventService.getUserStreamReport(eventDetails).then((data) => {

            this.setState({ fetchUserReport: false });

            if (data !== undefined && data !== null && data !== "") {
                if (data.status !== undefined && data.status !== null && data.status === 200) {
                    if (data.data.data !== undefined && data.data.data !== null && data.data.data !== "") {
                        this.setState({originalStreamReport:data.data.data})
                        this.onProcesUserStreamReport(data.data.data)
                       
                    }
                }
            }
        });

        this.setState({ showEventUserStreamReport: true });
    }
    onProcesUserStreamReport = (dataRecived) =>{
        if(this.state.dateFilterActive){
            let userstreamreports = [];
            for(let key in dataRecived){
                let dataval = dataRecived[key]
                const date1 = new Date(dataval.start_time);
                const date2 = this.state.selectedDateFilter;    
                    
                if (
                    date1.getFullYear() === date2.getFullYear() &&
                    date1.getMonth() === date2.getMonth() &&
                    date1.getDate() === date2.getDate()
                ) {
                    userstreamreports.push(dataval)
                }
            }
            this.setState({ userstreamreports: userstreamreports }, this.setStreamReport)
        }else{
            this.setState({ userstreamreports: dataRecived }, this.setStreamReport)
        }

        
    }
    setStreamReport = () => {
        let streamreports = this.state.userstreamreports;
        let completeStreamReport = [];
        if (streamreports !== undefined && streamreports !== null && streamreports !== "" && streamreports.length > 0) {

            
            for (var sr = 0; sr < streamreports.length; sr++) {
                let individualStreamReport = {};

                let username = streamreports[sr]['name'];
                let useremail = streamreports[sr]['email'];
                let userrole = streamreports[sr]['role'];
                let startTime = (streamreports[sr]['start_time'] !== undefined && streamreports[sr]['start_time'] !== null && streamreports[sr]['start_time'] !== "") ? streamreports[sr]['start_time'] : "";
                let endTime = (streamreports[sr]['end_time'] !== undefined && streamreports[sr]['end_time'] !== null && streamreports[sr]['end_time'] !== "") ? streamreports[sr]['end_time'] : "";

                let streamedDuration = 0;

                if (startTime !== "" && endTime !== "") {
                    let dt1 = new Date(startTime);
                    let dt2 = new Date(endTime);

                    // streamedDuration = this.diff_minutes(dt1, dt2)
                    streamedDuration = Math.abs(dt1 - dt2) / 1000;
                }

                let displayStartTime = "-";
                if (startTime !== "") {
                    displayStartTime = moment(startTime).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a')
                }
                let displayEndTime = "-";
                if (endTime !== "") {
                    displayEndTime = moment(endTime).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a')
                }

                individualStreamReport.name = username
                individualStreamReport.email = useremail
                individualStreamReport.role = userrole[0].toUpperCase() + userrole.slice(1)
                individualStreamReport.start_time = displayStartTime
                individualStreamReport.end_time = displayEndTime
                individualStreamReport.duration = this.timeConvert(streamedDuration)

                completeStreamReport.push(individualStreamReport)
            }
        }
        this.setState({ exportUserStreamReports: completeStreamReport },()=>{
            this.generateExcelExportData();
        })

    }
    viewPollReport = (pollId, eventID, pollTitle) => {

        if (eventID !== undefined && eventID !== null && eventID !== "") {
            let eventDetails = this.state.eventList.filter(event => (event._id === eventID))[0];
            eventDetails['poll_title'] = pollTitle;
            this.setState({
                fetchedEventDetails: eventDetails
            })
        }

        if (pollId !== undefined && pollId !== null && pollId !== "") {

            this.setState({ fetchPollReport: true, showSessions: false, showEventUserStreamReport: false, showPollReports: true });

            let roomListClass = document.querySelectorAll(".room_list");
            for (let i = 0; i < roomListClass.length; i++) {
                roomListClass[i].classList.remove('active');
            }

            let pollListClass = document.querySelectorAll(".poll_list");
            for (let i = 0; i < pollListClass.length; i++) {
                pollListClass[i].classList.remove('active');
            }
            let addActiveClass = document.getElementsByClassName("poll_" + pollId)
            addActiveClass[0].className += " active";

            let pollDetails = {
                poll_id: pollId
            }
            apiEventService.getPollReport(pollDetails).then((data) => {

                this.setState({ fetchPollReport: false });

                if (data !== undefined && data !== null && data !== "") {
                    if (data.status !== undefined && data.status !== null && data.status === 200) {
                        if (data.data.data !== undefined && data.data.data !== null && data.data.data !== "") {
                            this.setState({ pollreports: data.data.data })
                        }
                    }
                }
            });

        }
    }

    viewAgenda = (roomID) => {
        this.setState({ eventAgenda: [], joiningMeeting: false });

        if (roomID !== null && roomID !== "" && roomID !== undefined) {

            this.setState({ fetchSessions: true, showSessions: true, showPollReports: false, showEventUserStreamReport: false });

            let pollListClass = document.querySelectorAll(".poll_list");
            for (let i = 0; i < pollListClass.length; i++) {
                pollListClass[i].classList.remove('active');
            }

            let roomListClass = document.querySelectorAll(".room_list");
            for (let i = 0; i < roomListClass.length; i++) {
                roomListClass[i].classList.remove('active');
            }
            let addActiveClass = document.getElementsByClassName("room_" + roomID)
            addActiveClass[0].className += " active";

            let roomDetails = {
                room: roomID
            }

            if (this.eventData.user_role === 'interpreter' || this.eventData.user_role === 'listener') {
                roomDetails['private'] = false;
            }

            // let roomDetails = {
            //     room: roomID
            // }
            apiEventService.getEventAgenda(roomDetails).then((data) => {

                this.setState({ fetchSessions: false });

                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        if (data.data.status && data.data.status === true) {
                            if (data.data.data.length > 0) {

                                let eventDetails = [];
                                eventDetails = data.data.data;

                                for (var e = 0; e < eventDetails.length; e++) {

                                    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                                    let currentDate = moment(helper.getDate(new Date())).format('YYYY-MM-DD');

                                    let agendaMainDate;
                                    if (eventDetails[e]['start_date_time'] !== undefined && eventDetails[e]['start_date_time'] !== null && eventDetails[e]['start_date_time'] !== "") {
                                        agendaMainDate = moment(eventDetails[e]['start_date_time']).tz(timezone).format('YYYY-MM-DD');
                                    } else {
                                        agendaMainDate = moment(eventDetails[e]['date']).tz(timezone).format('YYYY-MM-DD');
                                    }

                                    let agendaStartDateTime = (eventDetails[e]['start_date_time'] !== undefined && eventDetails[e]['start_date_time'] !== null && eventDetails[e]['start_date_time'] !== "") ? new Date(eventDetails[e]['start_date_time']) : "";
                                    let agendaDate = new Date(eventDetails[e]['date']);

                                    if (this.state.eventRepeatStatus !== undefined && this.state.eventRepeatStatus !== null && this.state.eventRepeatStatus === true) {

                                        if (agendaMainDate < currentDate) {                                            

                                            let numOfWeeksForAgendaDate = this.diff_weeks(new Date(), agendaDate);

                                            if (agendaStartDateTime !== undefined && agendaStartDateTime !== null && agendaStartDateTime !== "") {

                                                let numOfWeeksForAgendaStartDateTime = this.diff_weeks(new Date(), agendaStartDateTime);
                                                agendaStartDateTime = agendaStartDateTime.setDate(agendaStartDateTime.getDate() + numOfWeeksForAgendaStartDateTime * 7);

                                            }

                                            agendaDate = agendaDate.setDate(agendaDate.getDate() + numOfWeeksForAgendaDate * 7);

                                        }                                        
                                    }                                    

                                    let sessionStartDateTime;
                                    if (agendaStartDateTime !== undefined && agendaStartDateTime !== null && agendaStartDateTime !== "") {
                                        sessionStartDateTime = moment(agendaStartDateTime).format('MM/DD/YYYY HH:mm:ss');
                                    } else {
                                        sessionStartDateTime = moment(agendaDate).format('MM/DD/YYYY HH:mm:ss');
                                    }

                                    let sessionStartTime = new Date(sessionStartDateTime);
                                    sessionStartTime = new Date(sessionStartTime).setHours(this.addZero(new Date(eventDetails[e]['start_time']).getHours()))
                                    sessionStartTime = new Date(sessionStartTime).setMinutes(this.addZero(new Date(eventDetails[e]['start_time']).getMinutes()))
                                    sessionStartTime = new Date(sessionStartTime).setSeconds(this.addZero(new Date(eventDetails[e]['start_time']).getSeconds()))
                                    sessionStartTime = new Date(sessionStartTime);

                                    let sessionEndTime = sessionStartDateTime;
                                    sessionEndTime = new Date(sessionEndTime).setHours(this.addZero(new Date(eventDetails[e]['end_time']).getHours()))
                                    sessionEndTime = new Date(sessionEndTime).setMinutes(this.addZero(new Date(eventDetails[e]['end_time']).getMinutes()))
                                    sessionEndTime = new Date(sessionEndTime).setSeconds(this.addZero(new Date(eventDetails[e]['end_time']).getSeconds()))
                                    sessionEndTime = new Date(sessionEndTime);

                                    eventDetails[e]['session_start_time'] = moment(sessionStartTime).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
                                    eventDetails[e]['session_end_time'] = sessionEndTime;

                                    let dts1 = new Date(eventDetails[e]['start_time']);
                                    let dts2 = new Date(eventDetails[e]['end_time']);

                                    let updatedSessionStartTime = moment(sessionStartTime).tz(timezone).format('MM/DD/YYYY HH:mm:ss');
                                    let updatedSessionCurrentTime = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');
                                    var updatedSessionEndTime = new Date(sessionStartTime);
                                    updatedSessionEndTime.setMinutes(updatedSessionEndTime.getMinutes() + this.diff_minutes(dts1, dts2));
                                    updatedSessionEndTime = moment(updatedSessionEndTime).format('MM/DD/YYYY HH:mm:ss')

                                    eventDetails[e]['session_end_time'] = new Date(updatedSessionEndTime);

                                    if (new Date(updatedSessionStartTime) <= new Date(updatedSessionCurrentTime) && new Date(updatedSessionEndTime) >= new Date(updatedSessionCurrentTime)) {
                                        eventDetails[e]['type'] = "current";
                                    } else if (new Date(updatedSessionStartTime) > new Date(updatedSessionCurrentTime)) {
                                        eventDetails[e]['type'] = "current";
                                    } else {
                                        eventDetails[e]['type'] = "past";
                                    }
                                }

                                if (this.eventData.user_role === 'interpreter') {
                                    if (this.state.interpreterRoomDetails.length > 0) {
                                        let sessionArray = [];
                                        this.state.interpreterRoomDetails.map((sessionDetails) => {
                                            let sessionData = eventDetails.filter(eventRoom => (eventRoom._id === sessionDetails._id));
                                            if (sessionData !== "" && sessionData !== undefined && sessionData.length > 0) {
                                                let alreadyExisting = sessionArray.filter((rooms) => JSON.stringify(rooms) === JSON.stringify(sessionData[0]));
                                                if (alreadyExisting.length === 0) {
                                                    sessionArray.push(sessionData[0]);
                                                }
                                            }                                            
                                            return true;
                                        });

                                        if(sessionArray.length > 0) {
                                            sessionArray = sessionArray.sort((a, b) => new Date(a.start_date_time).getTime() - new Date(b.start_date_time).getTime())
                                        }
                                        this.setState({ eventAgenda: sessionArray });
                                    }
                                } else {
                                    this.setState({ eventAgenda: eventDetails })

                                }
                            }
                        }
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }
            });
        }

    }

    createMeeting = () => {
        this.props.history.push("/schedule");
    }

    copyLink = (link) => {
        navigator.clipboard.writeText(link);
        Toast.fire({
            icon: 'success',
            title: 'Link copied successfully'
        })
    }

    editEvent = (id) => {
        if (id !== undefined && id !== null) {
            this.props.history.push("/events/"+id);
        }

    }

    timeConvert = (value) => {
        // const sec = parseInt(value * 60, 10); // convert value to number if it's string
        // let hours = Math.floor(sec / 3600); // get hours
        // let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        // let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds

        var hours = Math.floor(value / 60 / 60);
        var minutes = Math.floor(value / 60) - (hours * 60);
        var seconds = Math.floor(value % 60);
        
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ' : ' + minutes + ' : ' + seconds; // Return is HH : MM : SS
    }

    deleteEvent = (id, index, type) => {
        Swal.fire({
            title: '',
            text: "Are you sure you want to delete this event?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes, Proceed",
            cancelButtonText: 'No, cancel',
            confirmButtonColor: '#00d2a5',
            customClass: {
                confirmButton: 'green-bg-white-f-btn'
            },
            focusConfirm: false,
            focusCancel: true
        }).then((result) => {
            if (result.value) {
                try {
                    apiEventService.deleteEvent(id).then((data) => {
                        if (data && data !== undefined && data !== null && data !== "") {
                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Event deleted successfully"
                                })

                                if (type === "current") {
                                    this.state.currentEvents.splice(index, 1);
                                    this.setState({ currentEvents: [...this.state.currentEvents] });
                                } else if (type === "past") {
                                    this.state.pastEvents.splice(index, 1);
                                    this.setState({ pastEvents: [...this.state.pastEvents] });
                                }

                                // this.setState({ agendaDetails: [...this.state.agendaDetails] }, () => {
                                //     this.props.saveSessions(this.state.agendaDetails);
                                // });

                                // this.getEventDetails();

                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Unauthorized Access"
                                })
                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Please revalidate the form and submit"
                                })
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            }
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "No response from the server. Please try again!"
                            })
                        }
                    });
                } catch (e) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            }
        })
    }

    goForEvents = (session) => {

        if (session !== undefined) {

            this.setState({ joiningMeeting: false }, () => {
                let checkEventJoiningUserDetails = {
                    event_id: this.eventData.eventId
                }

                let loggedInUserRole = "";

                if (this.userCredentials !== undefined && this.userCredentials !== null && this.userCredentials !== "" && this.userCredentials.name !== undefined && this.userCredentials.name !== null && this.userCredentials.name !== "" && this.userCredentials.id !== undefined && this.userCredentials.id !== null && this.userCredentials.id !== "" && this.userCredentials.email !== undefined && this.userCredentials.email !== null && this.userCredentials.name !== "") {
                    checkEventJoiningUserDetails.email = this.userCredentials.email.toLowerCase();
                    loggedInUserRole = "moderator";
                } else {
                    checkEventJoiningUserDetails.email = this.eventData.user_email.toLowerCase();
                    loggedInUserRole = this.eventData.user_role;
                }

                if (this.state.fetchEventId !== undefined && this.state.fetchEventId !== null && this.state.fetchEventId !== "") {
                    checkEventJoiningUserDetails.event_id = this.state.fetchEventId
                }

                apiEventService.checkEventUserDetailsExists(checkEventJoiningUserDetails).then((data) => {

                    try {

                        if (data && data !== undefined && data !== null && data !== "") {
                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                if (data.data.status !== undefined && data.data.status !== null && data.data.status === "success" && data.data.data !== undefined && data.data.data !== null && data.data.data !== "" && data.data.data.length > 0) {

                                    var eventUsersAllData = data.data.data;

                                    if (eventUsersAllData.length > 0) {

                                        let joiningFlag = 1;
                                        let currentTime = moment(new Date()).tz(this.state.timezone).format('MM/DD/YYYY HH:mm:ss');;
                                        for (var e = 0; e < eventUsersAllData.length; e++) {

                                            let streamEndTime = eventUsersAllData[e]['end_time'];

                                            streamEndTime = moment(streamEndTime).add(60, 's').tz(this.state.timezone).format('MM/DD/YYYY HH:mm:ss');
                                            streamEndTime = new Date(streamEndTime);
                                            currentTime = new Date(currentTime);

                                            if (currentTime < streamEndTime) {
                                                joiningFlag = 0;
                                            }

                                            if (e === (eventUsersAllData.length - 1)) {

                                                if (joiningFlag === 1) {

                                                    let eventUserData = eventUsersAllData.filter(eventuser => (eventuser.role === loggedInUserRole))[0];

                                                    if (eventUserData !== undefined && eventUserData !== null && eventUserData !== "") {

                                                        if ((loggedInUserRole === "interpreter" && eventUserData._id === this.eventData.loggedin_user_id) || loggedInUserRole !== "interpreter") {
                                                            if (this.eventData.user_name !== undefined && this.eventData.user_name !== "" && this.eventData.user_name!== "" && this.eventData.user_name !== eventUserData.name && loggedInUserRole !== "interpreter") {
                                                                let userDetails = {
                                                                    name: this.eventData.user_name,
                                                                    id: eventUserData._id
                                                                }
                                                                apiEventService.updateEventUserDetails(userDetails).then((data) => {
                                                                    if (data && data !== undefined && data !== null && data !== "") {
                                                                        if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            this.setState({ joiningMeeting: false });

                                                            if (this.eventData.user_selected_language !== eventUserData.language) {
                                                                this.updateSelectedLanguage(eventUserData);
                                                            }

                                                            let session_id = session._id;
                                                            let url;

                                                            if (this.eventData.streamOut !== undefined && this.eventData.streamOut !== null && this.eventData.streamOut === true && this.eventData.meetingCode !== undefined && this.eventData.meetingCode !== null && this.eventData.meetingCode.substring(0, 2) === "ls") {

                                                                url = process.env.REACT_APP_MEETING_URL + session_id + '/listenerhls/' + this.eventData.user_name + '/' + eventUserData._id;

                                                            } else if (this.eventData.signLanguageMode !== undefined && this.eventData.signLanguageMode !== null && this.eventData.signLanguageMode === true && this.eventData.meetingCode !== undefined && this.eventData.meetingCode !== null && this.eventData.meetingCode.substring(0, 2) === "ss") {

                                                                url = process.env.REACT_APP_MEETING_URL + session_id + '/speakerslm/' + this.eventData.user_name + '/' + eventUserData._id;

                                                            }else if (this.eventData.meetingCode !== undefined && this.eventData.meetingCode !== null && this.eventData.meetingCode.substring(0, 1) === "a") {

                                                                url = process.env.REACT_APP_MEETING_URL + session_id + '/moderator-s/' + this.eventData.user_name + '/' + eventUserData._id;

                                                            } else {

                                                                url = process.env.REACT_APP_MEETING_URL + session_id + '/' + eventUserData.role + '/' + this.eventData.user_name + '/' + eventUserData._id;

                                                            }

                                                            window.location.href = url;
                                                            // var popUp = window.open(url, '_blank');
                                                            // if (popUp == null || typeof (popUp) == 'undefined') {
                                                            //     window.location.href = url;
                                                            // } else {
                                                            //     popUp.focus();
                                                            // }
                                                        } else {

                                                            apiEventService.deleteEventUser(eventUserData._id).then((data) => {
                                                                if (data && data !== undefined && data !== null && data !== "") {
                                                                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {

                                                                        let eventJoiningUserDetails = {
                                                                            event_id: this.eventData.eventId,
                                                                            role: loggedInUserRole
                                                                        }

                                                                        if (loggedInUserRole === "interpreter") {
                                                                            eventJoiningUserDetails._id = this.eventData.loggedin_user_id;
                                                                        }

                                                                        if (this.userCredentials !== undefined && this.userCredentials !== null && this.userCredentials !== "" && this.userCredentials.name !== undefined && this.userCredentials.name !== null && this.userCredentials.name !== "" && this.userCredentials.id !== undefined && this.userCredentials.id !== null && this.userCredentials.id !== "" && this.userCredentials.email !== undefined && this.userCredentials.email !== null && this.userCredentials.email !== "") {
                                                                            eventJoiningUserDetails.name = this.userCredentials.name;
                                                                            eventJoiningUserDetails.email = this.userCredentials.email;
                                                                            eventJoiningUserDetails.role = "moderator";
                                                                            // eventJoiningUserDetails._id = this.userCredentials.id;
                                                                        } else {
                                                                            eventJoiningUserDetails.name = this.eventData.user_name;
                                                                            eventJoiningUserDetails.email = this.eventData.user_email.toLowerCase();
                                                                        }

                                                                        if (this.state.fetchEventId !== undefined && this.state.fetchEventId !== null && this.state.fetchEventId !== "") {
                                                                            eventJoiningUserDetails.event_id = this.state.fetchEventId
                                                                        }
                                                                        eventJoiningUserDetails.language = this.eventData.user_selected_language

                                                                        apiEventService.saveEventUserDetails(eventJoiningUserDetails).then((data) => {
                                                                            if (data && data !== undefined && data !== null && data !== "") {
                                                                                if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                                                                    if (data.data.status !== undefined && data.data.status !== null && data.data.status === "success" && data.data.data !== undefined && data.data.data !== null && data.data.data !== "") {

                                                                                        this.setState({ joiningMeeting: false });

                                                                                        let session_id = session._id;
                                                                                        let url;
                                                                                        if (this.eventData.streamOut !== undefined && this.eventData.streamOut !== null && this.eventData.streamOut === true && this.eventData.meetingCode !== undefined && this.eventData.meetingCode !== null && this.eventData.meetingCode.substring(0, 2) === "ls") {

                                                                                            url = process.env.REACT_APP_MEETING_URL + session_id + '/listenerhls/' + data.data.data.name + '/' + data.data.data._id;

                                                                                        } else if (this.eventData.streamOut !== undefined && this.eventData.streamOut !== null && this.eventData.streamOut === true && this.eventData.meetingCode !== undefined && this.eventData.meetingCode !== null && this.eventData.meetingCode.substring(0, 2) === "ss") {

                                                                                            url = process.env.REACT_APP_MEETING_URL + session_id + '/speakerslm/' + data.data.data.name + '/' + data.data.data._id;

                                                                                        } else {

                                                                                            url = process.env.REACT_APP_MEETING_URL + session_id + '/' + data.data.data.role + '/' + data.data.data.name + '/' + data.data.data._id;

                                                                                        }

                                                                                        window.location.href = url;
                                                                                        // var popUp = window.open(url, '_blank');
                                                                                        // if (popUp == null || typeof (popUp) == 'undefined') {
                                                                                        //     window.location.href = url;
                                                                                        // } else {
                                                                                        //     popUp.focus();
                                                                                        // }

                                                                                    } else {
                                                                                        this.setState({ joiningMeeting: false });
                                                                                        Toast.fire({
                                                                                            icon: 'warning',
                                                                                            title: "Something went wrong. Please try again"
                                                                                        })
                                                                                    }
                                                                                } else {
                                                                                    this.setState({ joiningMeeting: false });
                                                                                    Toast.fire({
                                                                                        icon: 'warning',
                                                                                        title: "Something went wrong. Please try again"
                                                                                    })
                                                                                }
                                                                            } else {
                                                                                this.setState({ joiningMeeting: false });
                                                                                Toast.fire({
                                                                                    icon: 'warning',
                                                                                    title: "Something went wrong. Please try again"
                                                                                })
                                                                            }
                                                                        });

                                                                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                                                        Toast.fire({
                                                                            icon: 'warning',
                                                                            title: "Unauthorized Access"
                                                                        })
                                                                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                                                        Toast.fire({
                                                                            icon: 'warning',
                                                                            title: "Please revalidate the form and submit"
                                                                        })
                                                                    } else {
                                                                        Toast.fire({
                                                                            icon: 'warning',
                                                                            title: "Something went wrong. Please try again!"
                                                                        })
                                                                    }
                                                                } else {
                                                                    Toast.fire({
                                                                        icon: 'warning',
                                                                        title: "No response from the server. Please try again!"
                                                                    })
                                                                }
                                                            });
                                                        }

                                                    } else {

                                                        let eventJoiningUserDetails = {
                                                            event_id: this.eventData.eventId,
                                                            role: loggedInUserRole
                                                        }

                                                        if (loggedInUserRole === "interpreter") {
                                                            eventJoiningUserDetails._id = this.eventData.loggedin_user_id;
                                                        }

                                                        if (this.userCredentials !== undefined && this.userCredentials !== null && this.userCredentials !== "" && this.userCredentials.name !== undefined && this.userCredentials.name !== null && this.userCredentials.name !== "" && this.userCredentials.id !== undefined && this.userCredentials.id !== null && this.userCredentials.id !== "" && this.userCredentials.email !== undefined && this.userCredentials.email !== null && this.userCredentials.email !== "") {
                                                            eventJoiningUserDetails.name = this.userCredentials.name;
                                                            eventJoiningUserDetails.email = this.userCredentials.email;
                                                            eventJoiningUserDetails.role = "moderator";
                                                            // eventJoiningUserDetails._id = this.userCredentials.id;
                                                        } else {
                                                            eventJoiningUserDetails.name = this.eventData.user_name;
                                                            eventJoiningUserDetails.email = this.eventData.user_email.toLowerCase();
                                                        }

                                                        if (this.state.fetchEventId !== undefined && this.state.fetchEventId !== null && this.state.fetchEventId !== "") {
                                                            eventJoiningUserDetails.event_id = this.state.fetchEventId
                                                        }
                                                        eventJoiningUserDetails.language = this.eventData.user_selected_language

                                                        apiEventService.saveEventUserDetails(eventJoiningUserDetails).then((data) => {
                                                            if (data && data !== undefined && data !== null && data !== "") {
                                                                if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                                                    if (data.data.status !== undefined && data.data.status !== null && data.data.status === "success" && data.data.data !== undefined && data.data.data !== null && data.data.data !== "") {

                                                                        this.setState({ joiningMeeting: false });

                                                                        let session_id = session._id;
                                                                        let url;
                                                                        if (this.eventData.streamOut !== undefined && this.eventData.streamOut !== null && this.eventData.streamOut === true && this.eventData.meetingCode !== undefined && this.eventData.meetingCode !== null && this.eventData.meetingCode.substring(0, 2) === "ls") {

                                                                            url = process.env.REACT_APP_MEETING_URL + session_id + '/listenerhls/' + data.data.data.name + '/' + data.data.data._id;

                                                                        } else if (this.eventData.streamOut !== undefined && this.eventData.streamOut !== null && this.eventData.streamOut === true && this.eventData.meetingCode !== undefined && this.eventData.meetingCode !== null && this.eventData.meetingCode.substring(0, 2) === "ss") {

                                                                            url = process.env.REACT_APP_MEETING_URL + session_id + '/speakerslm/' + data.data.data.name + '/' + data.data.data._id;

                                                                        } else {

                                                                            url = process.env.REACT_APP_MEETING_URL + session_id + '/' + data.data.data.role + '/' + data.data.data.name + '/' + data.data.data._id;

                                                                        }

                                                                        window.location.href = url;
                                                                        // var popUp = window.open(url, '_blank');
                                                                        // if (popUp == null || typeof (popUp) == 'undefined') {
                                                                        //     window.location.href = url;
                                                                        // } else {
                                                                        //     popUp.focus();
                                                                        // }

                                                                    } else {
                                                                        this.setState({ joiningMeeting: false });
                                                                        Toast.fire({
                                                                            icon: 'warning',
                                                                            title: "Something went wrong. Please try again"
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({ joiningMeeting: false });
                                                                    Toast.fire({
                                                                        icon: 'warning',
                                                                        title: "Something went wrong. Please try again"
                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({ joiningMeeting: false });
                                                                Toast.fire({
                                                                    icon: 'warning',
                                                                    title: "Something went wrong. Please try again"
                                                                })
                                                            }
                                                        });
                                                    }

                                                } else {
                                                    this.setState({ joiningMeeting: false });
                                                    Toast.fire({
                                                        icon: 'warning',
                                                        title: "You have tried login with the same email ID, Please try after 30 sec or login with a different email."
                                                    })
                                                }
                                            }
                                        }
                                    }

                                } else {

                                    let eventJoiningUserDetails = {
                                        event_id: this.eventData.eventId,
                                        role: loggedInUserRole
                                    }

                                    if (loggedInUserRole === "interpreter") {
                                        eventJoiningUserDetails._id = this.eventData.loggedin_user_id;
                                    }

                                    if (this.userCredentials !== undefined && this.userCredentials !== null && this.userCredentials !== "" && this.userCredentials.name !== undefined && this.userCredentials.name !== null && this.userCredentials.name !== "" && this.userCredentials.id !== undefined && this.userCredentials.id !== null && this.userCredentials.id !== "" && this.userCredentials.email !== undefined && this.userCredentials.email !== null && this.userCredentials.email !== "") {
                                        eventJoiningUserDetails.name = this.userCredentials.name;
                                        eventJoiningUserDetails.email = this.userCredentials.email.toLowerCase();
                                        eventJoiningUserDetails.role = "moderator";
                                        // eventJoiningUserDetails._id = this.userCredentials.id;
                                    } else {
                                        eventJoiningUserDetails.name = this.eventData.user_name;
                                        eventJoiningUserDetails.email = this.eventData.user_email.toLowerCase();
                                    }

                                    if (this.state.fetchEventId !== undefined && this.state.fetchEventId !== null && this.state.fetchEventId !== "") {
                                        eventJoiningUserDetails.event_id = this.state.fetchEventId
                                    }
                                    eventJoiningUserDetails.language = this.eventData.user_selected_language

                                    apiEventService.saveEventUserDetails(eventJoiningUserDetails).then((data) => {
                                        if (data && data !== undefined && data !== null && data !== "") {
                                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                                if (data.data.status !== undefined && data.data.status !== null && data.data.status === "success" && data.data.data !== undefined && data.data.data !== null && data.data.data !== "") {

                                                    // userDetails.loggedInUserId = data.data.data._id;
                                                    // userDetails = jwt.encode(userDetails, process.env.REACT_APP_JWT_SECRET, 'HS512');
                                                    // window.localStorage.setItem('eventCodeUser', JSON.stringify(userDetails));
                                                    // this.props.history.push("/events");

                                                    this.setState({ joiningMeeting: false });

                                                    let session_id = session._id;
                                                    let url;
                                                    if (this.eventData.streamOut !== undefined && this.eventData.streamOut !== null && this.eventData.streamOut === true && this.eventData.meetingCode !== undefined && this.eventData.meetingCode !== null && this.eventData.meetingCode.substring(0, 2) === "ls") {

                                                        url = process.env.REACT_APP_MEETING_URL + session_id + '/listenerhls/' + data.data.data.name + '/' + data.data.data._id;

                                                    } else if (this.eventData.signLanguageMode !== undefined && this.eventData.signLanguageMode !== null && this.eventData.signLanguageMode === true && this.eventData.meetingCode !== undefined && this.eventData.meetingCode !== null && this.eventData.meetingCode.substring(0, 2) === "ss") {

                                                        url = process.env.REACT_APP_MEETING_URL + session_id + '/speakerslm/' + data.data.data.name + '/' + data.data.data._id;

                                                    } else {

                                                        url = process.env.REACT_APP_MEETING_URL + session_id + '/' + data.data.data.role + '/' + data.data.data.name + '/' + data.data.data._id;

                                                    }

                                                    window.location.href = url;
                                                    // var popUp = window.open(url, '_blank');
                                                    // if (popUp == null || typeof (popUp) == 'undefined') {
                                                    //     window.location.href = url;
                                                    // } else {
                                                    //     popUp.focus();
                                                    // }

                                                } else {
                                                    // this.setState({ errorMessage: 'Please try again later' });
                                                    this.setState({ joiningMeeting: false });
                                                    Toast.fire({
                                                        icon: 'warning',
                                                        title: "Something went wrong. Please try again"
                                                    })
                                                }
                                            } else {
                                                // this.setState({ errorMessage: 'Please try again later' });
                                                this.setState({ joiningMeeting: false });
                                                Toast.fire({
                                                    icon: 'warning',
                                                    title: "Something went wrong. Please try again"
                                                })
                                            }
                                        } else {
                                            // this.setState({ errorMessage: 'Please try again later' });
                                            this.setState({ joiningMeeting: false });
                                            Toast.fire({
                                                icon: 'warning',
                                                title: "Something went wrong. Please try again"
                                            })
                                        }
                                    });
                                }
                            } else {
                                this.setState({ joiningMeeting: false });
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again"
                                })
                            }
                        } else {
                            this.setState({ joiningMeeting: false });
                            Toast.fire({
                                icon: 'warning',
                                title: "Something went wrong. Please try again"
                            })
                        }
                    } catch (e) {
                        this.setState({ joiningMeeting: false });
                        console.log(e.message)
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again"
                        })
                    }
                })
            })
        }
    }

    updateSelectedLanguage = (eventData) => {
        if (eventData !== "" && eventData !== undefined) {
            let params = {
                user_id: eventData._id,
                language: this.eventData.user_selected_language,
            }
            try {
                apiEventService.updateSelectedLanguage(params).then((data) => {
                    if (data && data !== undefined && data !== null && data !== "" && data.status && data.status !== undefined && data.status !== null && data.status === 200
                        && data.data && data.data !== undefined && data.data !== null && data.data !== "" && data.data.status && data.data.status !== undefined && data.data.status !== null && data.data.status === "success") {
                        Toast.fire({
                            icon: 'success',
                            title: "Updated Language Successfully"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again"
                        })
                    }
                });
            } catch (error) {
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. Please try again"
                })
            }

        }

    }

    showMeetingInfo = (event) => {
        this.setState({ eventInfo: event })
        this.toggleInfoModal();
    }
    toggleInfoModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen })
    }

    format_poll_percentage = (n) => {
        var result = (n - Math.floor(n)) !== 0;

        if (result)
            return n.toFixed(2);
        else
            return n;

    }
    
    exportsUserStreamRecords = () => {

        this.setState({ exportingStreamReport: true }, () => {

            let fileName = "StreamReport.pdf"

            let pdfOptions = {
                orientation: 'l',
                putOnlyUsedFonts: true
            }
            const doc = new jsPDF(pdfOptions)
            const marginLeft = 15;

            var img = new Image()
            img.src = process.env.REACT_APP_URL +"logo-2-01.png";
            doc.addImage(img, 'png', 100, 10, 110, 40)

            const data = this.state.exportUserStreamReports.map(elt => [elt.name, elt.email, elt.role, elt.start_time, elt.end_time, elt.duration]);

            var userStreamDatas = this.state.exportUserStreamReports;

            let moderatorCount = 0;
            let speakerCount = 0;
            let listenerCount = 0;
            let interpreterCount = 0;

            if (userStreamDatas !== undefined && userStreamDatas !== null && userStreamDatas !== "" && userStreamDatas.length > 0) {
                let moderators = userStreamDatas.filter(userStreamData => (userStreamData.role === "Moderator"));
                let speakers = userStreamDatas.filter(userStreamData => (userStreamData.role === "Speaker"));
                let listeners = userStreamDatas.filter(userStreamData => (userStreamData.role === "Listener"));
                let interpreters = userStreamDatas.filter(userStreamData => (userStreamData.role === "Interpreter"));
                moderatorCount = moderators.length;
                speakerCount = speakers.length;
                listenerCount = listeners.length;
                interpreterCount = interpreters.length;
            }

            if (this.state.fetchedEventDetails !== undefined && this.state.fetchedEventDetails !== null && this.state.fetchedEventDetails !== "") {

                let eventName = this.state.fetchedEventDetails.name;
                // let eventDescription = this.state.fetchedEventDetails.description;
                let eventCode = this.state.fetchedEventDetails.event_code;
                let eventStartTime = moment(this.state.fetchedEventDetails.start_time).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a');
                let eventEndTime = moment(this.state.fetchedEventDetails.end_time).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a');

                doc.setFontSize(11);
                doc.setFont(undefined, "normal", "bold");
                doc.text("Name:", marginLeft, 50);
                doc.setFont(undefined, "italic", "normal");
                doc.text(eventName, 30, 50);
                // doc.setFontType("bold");
                // doc.text("Description:", marginLeft, 45);
                // doc.setFontType("normal");
                // doc.text(eventDescription, 60, 45);
                doc.setFont(undefined, "normal", "bold");
                doc.text("Code:", marginLeft, 60);
                doc.setFont(undefined, "italic", "normal");
                doc.text(eventCode, 30, 60);
                doc.setFont(undefined, "normal", "bold");
                doc.text("Time:", marginLeft, 70);
                doc.setFont(undefined, "italic", "normal");
                doc.text(eventStartTime + " - " + eventEndTime, 30, 70);
                doc.setFont(undefined, "normal", "normal");
                // doc.setFontType("bold");
                // doc.text("End Time:", marginLeft, 70);
                // doc.setFontType("normal");
                // doc.text(eventEndTime, 40, 70);
            }

            doc.setFontSize(20);
            doc.text("Moderators: " + moderatorCount, marginLeft, 85);
            doc.text("Speakers: " + speakerCount, 100, 85);
            doc.text("Interpreters: " + interpreterCount, marginLeft, 95);
            doc.text("Listeners: " + listenerCount, 100, 95);

            doc.autoTable({
                startY: 105,
                head: [['User', 'Email', 'Role', 'Start Time', 'End Time', 'Duration']],
                body: data
            })

            doc.save(fileName)
            this.setState({ exportingStreamReport: false })
        })
    }
    generateExcelExportData = () =>{
        var userStreamDatas = this.state.exportUserStreamReports;
        let moderatorCount = 0;
            let speakerCount = 0;
            let listenerCount = 0;
            let interpreterCount = 0;

            if (userStreamDatas !== undefined && userStreamDatas !== null && userStreamDatas !== "" && userStreamDatas.length > 0) {
                let moderators = userStreamDatas.filter(userStreamData => (userStreamData.role === "Moderator"));
                let speakers = userStreamDatas.filter(userStreamData => (userStreamData.role === "Speaker"));
                let listeners = userStreamDatas.filter(userStreamData => (userStreamData.role === "Listener"));
                let interpreters = userStreamDatas.filter(userStreamData => (userStreamData.role === "Interpreter"));
                moderatorCount = moderators.length;
                speakerCount = speakers.length;
                listenerCount = listeners.length;
                interpreterCount = interpreters.length;
            }
            let eventName = this.state.fetchedEventDetails.name;
            // let eventDescription = this.state.fetchedEventDetails.description;
            let eventCode = this.state.fetchedEventDetails.event_code;
            let eventStartTime = moment(this.state.fetchedEventDetails.start_time).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a');
            let eventEndTime = moment(this.state.fetchedEventDetails.end_time).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a');

        //['User', 'Email', 'Role', 'Start Time', 'End Time', 'Duration']
        let data = this.state.exportUserStreamReports.map(elt => [elt.name, elt.email, elt.role, elt.start_time, elt.end_time, elt.duration]);
        if(this.state.exportUserStreamReports.length === 0){
            data.unshift(['', '', 'No Reports Found', '', '', '']);
        }
        data.unshift(['User', 'Email', 'Role', 'Start Time', 'End Time', 'Duration']);
        data.unshift(['', '', '', '', '', '']);
        data.unshift(['', '', '', '', '', '']);
        data.unshift(['Listeners : '+listenerCount, 'Interpreters : '+interpreterCount, '', '', '', '']);
        data.unshift(['Moderators : '+moderatorCount, 'Speakers : '+speakerCount, '', '', '', '']);
        data.unshift(['Time: '+eventStartTime+' to '+eventEndTime, '', '', '', '', '']);
        data.unshift(['Code: '+eventCode, '', '', '', '', '']);
        data.unshift(['Name: '+eventName, '', '', '', '', '']);
        this.setState({csvStreamRecordData:data})
    }
    exportsPollsRecords = () => {

        this.setState({ exportingPollReport: true}, () => {

            document.getElementById("exportPollsRecordsTopDiv").style.marginTop = "1000px";

            var pollQuestionDiv = document.getElementsByClassName('polls-ques-whole-blk')[0];
            pollQuestionDiv.classList.add("hidescroll")

            var exportPollsRecords = document.getElementById("exportPollsRecords");
            exportPollsRecords.hidden = false;
            
            this.OPTIONS = {
                'jsPDF': {
                    'orientation': 'p',
                    'unit': 'px',
                    'format': 'a4',
                    'putOnlyUsedFonts': false,
                    'compress': false,
                    'precision': 2,
                    'userUnit': 1.0,
                },
                'html2canvas': {
                    'allowTaint': false,
                    'backgroundColor': '#ffffff',
                    'canvas': null,
                    'foreignObjectRendering': false,
                    'imageTimeout': 15000,
                    'logging': false,
                    'onclone': null,
                    'proxy': null,
                    'removeContainer': true,
                    'scale': window.devicePixelRatio,
                    'useCORS': false,
                },
            }

            let totalHeight = exportPollsRecords.offsetHeight;
            const pdf = new jsPDF(this.OPTIONS.jsPDF.orientation, this.OPTIONS.jsPDF.unit, this.OPTIONS.jsPDF.format);
            const pdfWidth = pdf.internal.pageSize.width;
            const pdfHeight = pdf.internal.pageSize.height;
            html2canvas(exportPollsRecords, this.OPTIONS.html2canvas).then((canvas) => {
                document.getElementById("exportPollsRecordsTopDiv").style.marginTop = "0px";
                pollQuestionDiv.classList.remove("hidescroll")
                exportPollsRecords.hidden = true;
                const widthRatio = pdfWidth / canvas.width;
                const sX = 0;
                let sWidth = canvas.width;
                let sHeight = pdfHeight + ((pdfHeight - pdfHeight * widthRatio) / widthRatio);
                const dX = 0;
                const dY = 0;
                const dWidth = sWidth;
                const dHeight = sHeight;
                let pageCnt = 1;

                while (totalHeight > 0) {
                    totalHeight -= sHeight;
                    let sY = sHeight * (pageCnt - 1);
                    const childCanvas = document.createElement('CANVAS');
                    childCanvas.setAttribute('width', sWidth);
                    childCanvas.setAttribute('height', sHeight);
                    const childCanvasCtx = childCanvas.getContext('2d');
                    childCanvasCtx.drawImage(canvas, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
                    if (pageCnt > 1) {
                        pdf.addPage();
                    }
                    pdf.setPage(pageCnt);
                    pdf.addImage(childCanvas.toDataURL('image/png'), 'PNG', 0, 0, canvas.width * widthRatio, 0);
                    pageCnt++;
                }
                let fileName = 'pollsReport.pdf';
                pdf.save(fileName);
                this.setState({ exportingPollReport: false })
            });
        })

        
    }
    filterBasedOnDate = (e) =>{
        this.setState({selectedDateFilter:e},()=>{
            this.onProcesUserStreamReport(this.state.originalStreamReport)
        })
    }
    setDateCheckBox = (value) =>{
        this.setState({dateFilterActive:value},()=>{
            this.onProcesUserStreamReport(this.state.originalStreamReport)
        })
    }
    render() {
        return (
            <React.Fragment>
                <section className="scheduller-session">
                   <div className={this.state.apiresponse ? "hide":"apiloader"}><div className="apiloader-spinner"></div></div>
                    <Header history={this.props.history} logoIMG={this.state.logoIMG} />

                    {/* <div className="align-items-center justify-content-between rooms-schedule-mobile-header">
                        <div className="rooms-currently-active">Techies conference</div>
                        <div className="schedule-toggler-blk">
                            <div className="schedule-toggler"><img src={require("../img/vdots.svg")} alt="settings" /></div>
                        </div>
                    </div> */}
                    <div id="landingbg" className="d-flex meeting-lst-complete-row">
                        {this.eventData !== "" &&
                            <div className="meet-schedule-col  mod-meet-lst-blk">
                                <div className="meet-schedul-caption meet-schedul-underline">{this.eventData['name']}</div>
                                <div className="meeting-listing-whole-blk">

                                    {/* <div className="meeting-lst-desc-wrap">
                                        <div className="rooms-lst-label">Date</div>
                                        <div className="meeting-event-desc">
                                        {moment(this.eventData['date']).tz(this.state.timezone).format('Do MMM YYYY')}
                                        </div>
                                    </div> */}

                                    <div className="meeting-lst-desc-wrap">
                                        <div className="rooms-lst-label">Event Start Time</div>
                                        <div className="meeting-event-desc">
                                            {moment(this.eventData['event_start_time']).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a')}
                                        </div>
                                    </div>

                                    <div className="meeting-lst-desc-wrap">
                                        <div className="rooms-lst-label">Event End Time</div>
                                        <div className="meeting-event-desc">
                                            {moment(this.eventData['event_end_time']).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a')}
                                        </div>
                                    </div>

                                    <div className="meeting-lst-desc-wrap">
                                        <div className="rooms-lst-label">Description</div>
                                        <div className="meeting-event-desc">
                                            {this.eventData['description']}
                                        </div>
                                    </div>
                                    {this.state.moderator === true && <>
                                        <div className="meeting-link-desc">
                                            <div className="d-flex event-meet-link moderator-lk">Moderator :</div>
                                            <div className="d-flex align-items-center meeting-link-url-blk">
                                                <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_MODERATOR + this.eventData['event_code']}>{process.env.REACT_APP_MEETING_LINK_MODERATOR + this.eventData['event_code']}</div>
                                                <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_MODERATOR + this.eventData['event_code'])}>
                                                    <img src={require("../img/copy-url.svg")} alt="copy url" />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.eventData['enableSecondaryModerator'] === true && (
                                                <div className="meeting-link-desc">
                                                    <div className="d-flex event-meet-link moderator-lk">Secondary Moderator :</div>
                                                    <div className="d-flex align-items-center meeting-link-url-blk">
                                                        <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_SECONDARY_MODERATOR + this.eventData['event_code']}>{process.env.REACT_APP_MEETING_LINK_SECONDARY_MODERATOR + this.eventData['event_code']}</div>
                                                        <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_SECONDARY_MODERATOR + this.eventData['event_code'])}>
                                                            <img src={require("../img/copy-url.svg")} alt="copy url" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="meeting-link-desc">
                                            <div className="d-flex event-meet-link moderator-lk">Speaker :</div>
                                            <div className="d-flex align-items-center meeting-link-url-blk">
                                                <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_SPEAKER + this.eventData['event_code']}>{process.env.REACT_APP_MEETING_LINK_SPEAKER + this.eventData['event_code']}</div>
                                                <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_SPEAKER + this.eventData['event_code'])}>
                                                    <img src={require("../img/copy-url.svg")} alt="copy url" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="meeting-link-desc">
                                            <div className="d-flex event-meet-link moderator-lk">Interpreter :</div>
                                            <div className="d-flex align-items-center meeting-link-url-blk">
                                                <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_INTERPRETER + this.eventData['event_code']}>{process.env.REACT_APP_MEETING_LINK_INTERPRETER + this.eventData['event_code']}</div>
                                                <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_INTERPRETER + this.eventData['event_code'])}>
                                                    <img src={require("../img/copy-url.svg")} alt="copy url" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="meeting-link-desc">
                                            <div className="d-flex event-meet-link moderator-lk">Viewer :</div>
                                            <div className="d-flex align-items-center meeting-link-url-blk">
                                                <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_LEARNER + this.eventData['event_code']}>{process.env.REACT_APP_MEETING_LINK_LEARNER + this.eventData['event_code']}</div>
                                                <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_LEARNER + this.eventData['event_code'])}>
                                                    <img src={require("../img/copy-url.svg")} alt="copy url" />
                                                </div>
                                            </div>
                                        </div>

                                        {this.eventData['streamOut'] !== undefined && this.eventData['streamOut'] !== null && this.eventData['streamOut'] === true && (
                                            <div className="meeting-link-desc">
                                                <div className="d-flex event-meet-link moderator-lk">Stream Out :</div>
                                                <div className="d-flex align-items-center meeting-link-url-blk">
                                                    <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_STREAMOUT + this.eventData['event_code']}>{process.env.REACT_APP_MEETING_LINK_STREAMOUT + this.eventData['event_code']}</div>
                                                    <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_STREAMOUT + this.eventData['event_code'])}>
                                                        <img src={require("../img/copy-url.svg")} alt="copy url" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {this.eventData['signLanguageMode'] !== undefined && this.eventData['signLanguageMode'] !== null && this.eventData['signLanguageMode'] === true && (
                                            <div className="meeting-link-desc">
                                                <div className="d-flex event-meet-link moderator-lk">Sign Language :</div>
                                                <div className="d-flex align-items-center meeting-link-url-blk">
                                                    <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_SIGNLANGUAGE + this.eventData['event_code']}>{process.env.REACT_APP_MEETING_LINK_SIGNLANGUAGE + this.eventData['event_code']}</div>
                                                    <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_SIGNLANGUAGE + this.eventData['event_code'])}>
                                                        <img src={require("../img/copy-url.svg")} alt="copy url" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                    }
                                </div>
                            </div>
                        }
                        {this.eventData === "" &&
                            <div className="meet-schedule-col  mod-meet-lst-blk">
                                <div className="meet-schedul-caption meet-schedul-underline">Meetings</div>
                                <div className="meeting-whole-lst">
                                    <div className="create-meet-lst">
                                        <button type="button" className="room-join-btn" onClick={() => this.createMeeting()}><span className="room-join-plus">+</span>Create Meeting</button>
                                    </div>
                                    {this.state.fetchEvents && (
                                        <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                                            <Loader type="Bars" color="#00d2a5" height={30} width={30} />
                                            <div className="mt-3" style={{ "fontSize": "13px", "color": "#a9a9a9" }}>Fetching Events. Please wait...</div>
                                        </div>
                                    )}

                                    {!this.state.fetchEvents && (
                                        <React.Fragment>
                                            {this.state.eventList !== "" && this.state.eventList.length > 0
                                                ?
                                                <div className="current-meet-blk">
                                                    {this.state.currentEvents.length > 0 && 
                                                        <React.Fragment>
                                                            <Accordion defaultExpanded expanded={this.state.accordionPanel === 'panel1a'} onChange={this.handleAccordionChange('panel1a')}>
                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="current-meetings">Current meetings</AccordionSummary>
                                                                <AccordionDetails>
                                                                    <div className="meet-lst-wrapper">
                                                                        {this.state.currentEvents.map((event, key) => {
                                                                            return (
                                                                                <div className={"d-flex align-items-center justify-content-between meet-lst-container meeting_list meeting_" + event._id + ""} key={key}>
                                                                                    <div className={"meeting-lst-title "} onClick={() => this.viewRooms(event._id, event.event_code, 1, event.repeatWeekly, true)} title={event.name}>{event.name}</div>
                                                                                    <div className="meeting-info-icon" onClick={() => this.showMeetingInfo(event)}>
                                                                                        <img src={require("../img/info.svg")} alt="info" />
                                                                                    </div>
                                                                                    <div className="meeting-lst-edit-wrapper" onClick={() => this.editEvent(event._id)}>
                                                                                        <img src={require("../img/edit-pencil.svg")} alt="edit" />
                                                                                    </div>
                                                                                    <div className="meeting-lst-delete-wrapper" onClick={() => this.deleteEvent(event._id, key, "current")}>
                                                                                        <img src={require("../img/trash.svg")} alt="edit" />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>                                                
                                                        </React.Fragment>
                                                    }

                                                    {this.state.pastEvents.length > 0 && 
                                                        <React.Fragment>
                                                            <Accordion expanded={this.state.accordionPanel === 'panel2a'} onChange={this.handleAccordionChange('panel2a')}>
                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" className="past-meetings">Past meetings</AccordionSummary>
                                                                <AccordionDetails>
                                                                    <div className="meet-lst-wrapper">
                                                                        {this.state.pastEvents.map((event, key) => {

                                                                            let archives = (event.archives !== undefined && event.archives !== null && event.archives !== "" && event.archives.length > 0) ? event.archives : (event.archive !== undefined && event.archive !== null && event.archive !== "" && event.archive.length > 0) ? event.archive : [];
                                                                            return (
                                                                                <div className={"d-flex align-items-center justify-content-between meet-lst-container meeting_list meeting_" + event._id + ""} key={key}>
                                                                                    <div className={"meeting-lst-title "} onClick={() => this.viewRooms(event._id, event.event_code, 0, event.repeatWeekly, true, archives)} title={event.name}>{event.name}</div>
                                                                                    <div className="meeting-info-icon">
                                                                                        <img src={require("../img/info.svg")} alt="info" onClick={() => this.showMeetingInfo(event)} />
                                                                                    </div>
                                                                                    <div className="meeting-lst-edit-wrapper" onClick={() => this.editEvent(event._id)}>
                                                                                        <img src={require("../img/edit-pencil.svg")} alt="edit" />
                                                                                    </div>
                                                                                    <div className="meeting-lst-delete-wrapper" onClick={() => this.deleteEvent(event._id, key, "past")}>
                                                                                        <img src={require("../img/trash.svg")} alt="edit" />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                                :
                                                <div style={{ "marginTop": "50px" }} className="d-flex align-items-center justify-content-center flex-column h-100">
                                                    <div className="no-resourses-lst">
                                                        <img src={require("../img/no-data.svg")} alt="no-data" />
                                                    </div>
                                                    <div className="empty-list-txt">No Meetings are available</div>
                                                </div>
                                            }
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        }

                        <div className="rooms-listing-tab">
                            {this.state.fetchRooms && (
                                <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                                    <Loader type="Bars" color="#00d2a5" height={30} width={30} />
                                    <div className="mt-3" style={{ "fontSize": "13px", "color": "#a9a9a9" }}>Fetching Rooms. Please wait...</div>
                                </div>
                            )}
                            {!this.state.fetchRooms && (
                                <React.Fragment>
                                    {this.state.eventRooms.length > 0 ? <>
                                        <div className="rooms-listing-caption meet-schedul-underline">rooms</div>
                                        <div className="rooms-listing-whole-blk">
                                            <div className="rooms-listing-wrapper">
                                                {this.state.eventRooms.map((room, key) => {
                                                    return (
                                                        <div className={"rooms-name-wrapper room_list room_" + room._id + ""} key={key} onClick={() => this.viewAgenda(room._id, room.name, this.state.eventRooms.eventCode)}>{room.name}</div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div></>
                                        : <div className="d-flex align-items-center justify-content-center flex-column h-100">
                                            <div className="no-resourses-lst">
                                                <img src={require("../img/no-data.svg")} alt="no-data" />
                                            </div>
                                            <div className="empty-list-txt">No rooms are available</div>
                                        </div>
                                    }
                                </React.Fragment>
                            )}

                            {this.state.allowUserReportDisplay && (
                                <div className="duration-statistics" onClick={() => this.viewUserReport()}>Duration Statistics</div>
                            )}

                            {this.state.allowUserReportDisplay && (
                                <div class="view-recordings-caption" onClick={() => this.viewRecordings()}>View Recordings</div>
                            )}
                            
                            {
                                this.state.pollsList !== undefined && this.state.pollsList !== null && this.state.pollsList !== "" && this.state.pollsList.length > 0 && (
                                    <React.Fragment>
                                        <div className="polls-lst-caption">Polls</div>
                                        <div className="polls-lst-whole-blk">
                                            <div className="polls-lst-wrapper">
                                                {this.state.pollsList.map((poll, index) => {
                                                    return (
                                                        <div className={"polls-name-wrapper poll_list poll_" + poll._id + ""} key={"polls_" + index} onClick={() => this.viewPollReport(poll._id, this.state.pollsList['event_id'], poll.title)}>{poll.title}</div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }

                        </div>

                        <div className="rooms-listing-col">
                            {this.state.fetchSessions && this.state.showSessions && (
                                <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                                    <Loader type="Bars" color="#00d2a5" height={30} width={30} />
                                    <div className="mt-3" style={{ "fontSize": "13px", "color": "#a9a9a9" }}>Fetching Sessions. Please wait...</div>
                                </div>
                            )}
                            {!this.state.fetchSessions && this.state.showSessions && (
                                <React.Fragment>
                                    {this.state.eventAgenda.length > 0
                                        ?
                                        <React.Fragment>
                                            <div className="rooms-listing-caption">Sessions</div>
                                            {this.state.eventAgenda.map((agendas, index) => {
                                                return (<div className="agenda-block" key={index}><div className="rooms-list-name-caption">{agendas.name}</div>
                                                    <div className="rooms-lst-col-desc">
                                                        <div className="rooms-lst-label">Description</div>
                                                        <div className="rooms-lst-event-desc">
                                                            {agendas.description}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex rooms-lst-col-desc">
                                                        <div className="room-assign-start-time">
                                                            <div className="rooms-lst-label">Start Time</div>
                                                            <div className="rooms-lst-val">
                                                                {moment(agendas.session_start_time).format('Do MMM YYYY, hh:mm a')}
                                                            </div>
                                                        </div>
                                                        <div className="room-assign-end-time">
                                                            <div className="rooms-lst-label">End Time</div>
                                                            <div className="rooms-lst-val">
                                                                {moment(agendas.session_end_time).format('Do MMM YYYY, hh:mm a')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rooms-lst-col-desc">
                                                        <div className="room-meet-id">Meeting ID:<span>{this.state.eventCode}</span></div>
                                                    </div>
                                                    {
                                                        this.state.eventStatus !== "" && this.state.eventStatus !== 2 &&
                                                        <div className="room-join-btn-wrapper">
                                                            {agendas.type === "past" && (
                                                                <button type="button" className="view-room-meet-btn">This meeting already expired</button>
                                                            )}
                                                            {agendas.type !== "past" && (
                                                                
                                                                this.state.joiningMeeting
                                                                ?
                                                                <button type="button" className="view-room-meet-btn" disabled={this.state.joiningMeeting}>
                                                                    {this.state.joiningMeeting === true ? this.state.moderator === true ? 'Starting Meeting... ' : 'Joining Meeting... ' : this.state.moderator === true ? 'Start Meeting' : 'Join Meeting'}
                                                                    {this.state.joiningMeeting === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                                                </button>
                                                                :
                                                                <button type="button" className="view-room-meet-btn" onClick={() => this.goForEvents(agendas)}>{this.state.moderator === true ? 'Start Meeting' : 'Join Meeting'}</button>
                                                            )}
                                                        </div>
                                                    } </div>)
                                            })}
                                        </React.Fragment>
                                        :
                                        <div className="d-flex align-items-center justify-content-center flex-column h-100">
                                            <div className="no-resourses-lst">
                                                <img src={require("../img/no-data.svg")} alt="no-data" />
                                            </div>
                                            <div className="empty-list-txt">No agenda available</div>
                                        </div>
                                    }
                                </React.Fragment>
                            )}

                            {this.state.fetchPollReport && this.state.showPollReports && (
                                <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                                    <Loader type="Bars" color="#00d2a5" height={30} width={30} />
                                    <div className="mt-3" style={{ "fontSize": "13px", "color": "#a9a9a9" }}>Fetching Poll Reports. Please wait...</div>
                                </div>
                            )}

                            {!this.state.fetchPollReport && this.state.showPollReports && (
                                <React.Fragment>
                                    {
                                        this.state.pollreports.length > 0
                                        ?
                                            <React.Fragment>
                                                <div className="question-head">
                                                    <div className="rooms-list-name-caption">Questions</div>
                                                    {/* <div className="rooms-list-name-caption" onClick={() => { this.exportsPollsRecords() }}>EXPORT</div> */}
                                                    
                                                    {
                                                        this.state.exportingPollReport
                                                        ?
                                                        <button type="button" className="room-join-btn" disabled={this.state.exportingPollReport}>
                                                            {this.state.exportingPollReport === true ? 'Exporting...' : 'Export'}
                                                            {this.state.exportingPollReport === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                                        </button>
                                                        :
                                                        <button className="room-join-btn" onClick={() => { this.exportsPollsRecords() }}>Export</button>
                                                    }

                                                </div>
                                                <div className="polls-ques-whole-blk">
                                                    
                                                    {this.state.pollreports.map((pollreport, index) => {

                                                        var options = pollreport.options;
                                                        var optionPercentage = pollreport.report;
                                                        var optionResponses = pollreport.responses;
                                                        
                                                        return (
                                                            <React.Fragment key={"pollreport_"+index}>
                                                                <div className="polls-ques-container">
                                                                    <div className="polls-ques-part"><span>{index + 1}.</span>{pollreport.question}</div>
                                                                    <div className="polls-response-blk">
                                                                        {options.map((option, key) => {

                                                                            let updatedPercentage = 0;
                                                                            let updatedTotalResponse = 0;
                                                                            try {
                                                                                let percentage = optionPercentage.filter(percentage => (Object.keys(percentage)[0] === Object.keys(option)[0]));

                                                                                if (percentage !== undefined && percentage !== null && percentage !== "" && percentage.length > 0 && Object.values(percentage[0])[0] !== undefined && Object.values(percentage[0])[0] !== null && Object.values(percentage[0])[0] !== "") {
                                                                                    updatedPercentage = Object.values(percentage[0])[0];
                                                                                }
                                                                            } catch(e) { }

                                                                            try {
                                                                                let responses = optionResponses.filter(response => (response.answer === Object.keys(option)[0]));
                                                                                if (responses !== undefined && responses !== null && responses !== "" && responses.length > 0) {
                                                                                    updatedTotalResponse = responses.length;
                                                                                }
                                                                            } catch (e) { }

                                                                            return (
                                                                                <div className="d-flex align-items-center poll-response-container" key={"optionvalues_"+key}>
                                                                                        <div className="d-flex align-items-center justify-content-between w-100">
                                                                                            <div className="d-flex align-items-center polls-choice-ans">
                                                                                                <div className="polls-choice">{Object.keys(option)[0].toUpperCase()}.</div>
                                                                                                <div className="polls-ans">{Object.values(option)[0]}</div>
                                                                                            </div>
                                                                                            <div className="d-flex align-items-center polls-ans-right-blk">
                                                                                                <div className="d-flex align-items-center polls-attendees-count">{updatedTotalResponse}</div>
                                                                                            <div className="polls-percentage">{this.format_poll_percentage(updatedPercentage)}%</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="polls-progress-indicator-color" style={{ "width": updatedPercentage+"%"}}></div>
                                                                                    </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    <div id="exportPollsRecordsTopDiv">
                                                        <div id="exportPollsRecords" hidden>
                                                            <div className="Even_Details">
                                                                <div className="logo">
                                                                    <img alt="logo" src={require("../img/logo-2-01.png")} />
                                                                </div>
                                                                {this.state.fetchedEventDetails !== "" && this.state.fetchedEventDetails !== 'undefined' &&
                                                                    <>
                                                                        <div className="eventDetails">
                                                                            <div className="eventName d-flex mb-3"> Name : {this.state.fetchedEventDetails.name} </div>
                                                                            <div className="eventDescription d-flex mb-3"> Description :  {this.state.fetchedEventDetails.description} </div>
                                                                            <div className="eventStartTime d-flex mb-3">Start Time :  {moment(this.state.fetchedEventDetails.start_time).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a')} </div>
                                                                            <div className="eventEndTime d-flex mb-3">End Time : {moment(this.state.fetchedEventDetails.end_time).tz(this.state.timezone).format('Do MMM YYYY, hh:mm a')} </div>
                                                                            <div className="pollTitle d-flex mb-3">Poll Name : {this.state.fetchedEventDetails.poll_title}</div>
                                                                        </div>
                                                                        <div className="pollTitle">Questions</div>
                                                                    </>
                                                                }
                                                            </div>


                                                            <div className="">
                                                                {this.state.pollreports.map((pollreport, index) => {

                                                                    var options = pollreport.options;
                                                                    var optionPercentage = pollreport.report;
                                                                    var optionResponses = pollreport.responses;

                                                                    return (
                                                                        <React.Fragment key={"pollreport_" + index}>
                                                                            <div className="polls-ques-container">
                                                                                <div className="polls-ques-part"><span>{index + 1}.</span>{pollreport.question}</div>
                                                                                <div className="polls-response-blk">
                                                                                    {options.map((option, key) => {

                                                                                        let updatedPercentage = 0;
                                                                                        let updatedTotalResponse = 0;
                                                                                        try {
                                                                                            let percentage = optionPercentage.filter(percentage => (Object.keys(percentage)[0] === Object.keys(option)[0]));

                                                                                            if (percentage !== undefined && percentage !== null && percentage !== "" && percentage.length > 0 && Object.values(percentage[0])[0] !== undefined && Object.values(percentage[0])[0] !== null && Object.values(percentage[0])[0] !== "") {
                                                                                                updatedPercentage = Object.values(percentage[0])[0];
                                                                                            }
                                                                                        } catch (e) { }

                                                                                        try {
                                                                                            let responses = optionResponses.filter(response => (response.answer === Object.keys(option)[0]));
                                                                                            if (responses !== undefined && responses !== null && responses !== "" && responses.length > 0) {
                                                                                                updatedTotalResponse = responses.length;
                                                                                            }
                                                                                        } catch (e) { }
                                                                                        return (
                                                                                            <div className="d-flex align-items-center poll-response-container" key={"optionvalues_" + key}>
                                                                                                <div className="d-flex align-items-center justify-content-between w-100">
                                                                                                    <div className="d-flex align-items-center polls-choice-ans">
                                                                                                        <div className="polls-choice">{Object.keys(option)[0].toUpperCase()}.&nbsp;</div>
                                                                                                        <div className="polls-ans1">{Object.values(option)[0]}</div>
                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center polls-ans-right-blk">
                                                                                                        <div className="d-flex align-items-center polls-attendees-count">{updatedTotalResponse}</div>
                                                                                                        <div className="polls-percentage">{this.format_poll_percentage(updatedPercentage)}%</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="polls-progress-indicator-color" style={{ "width": updatedPercentage + "%" }}></div>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        :
                                            <div className="d-flex align-items-center justify-content-center flex-column h-100">
                                                <div className="no-resourses-lst">
                                                    <img src={require("../img/no-data.svg")} alt="no-data" />
                                                </div>
                                                <div className="empty-list-txt">No poll questions available</div>
                                            </div>
                                    }
                                </React.Fragment>
                            )}

                            {this.state.fetchUserReport && this.state.showEventUserStreamReport && (
                                <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                                    <Loader type="Bars" color="#00d2a5" height={30} width={30} />
                                    <div className="mt-3" style={{ "fontSize": "13px", "color": "#a9a9a9" }}>Fetching User Stream Reports. Please wait...</div>
                                </div>
                            )}

                            {!this.state.fetchUserReport && this.state.showEventUserStreamReport && (
                                <React.Fragment>
                                    {
                                        this.state.userstreamreports.length > 0 || true
                                        ?
                                        <React.Fragment>
                                             <div className="rooms-list-name-caption">Streaming Duration</div>
                                             <br/>
                                            <div className="question-head">
                                               
                                                <div className="pickerlabal">Filter Report By Date</div><input type="checkbox"
                                                    name="datepickerneeded"
                                                    value={this.state.dateFilterActive}
                                                    checked={this.state.dateFilterActive === true ? "checked" : ""}
                                                    onChange={() => { this.setDateCheckBox(!this.state.dateFilterActive); }} />
                                                <div className={this.state.dateFilterActive ? "":"hide"}>
                                                <DatePicker
                                                        selected={this.state.selectedDateFilter}
                                                        onChange={(e) => {
                                                            this.filterBasedOnDate(e);
                                                        }}
                                                        onChangeRaw={(e) => { e.preventDefault() }}
                                                        className="custom-date"
                                                        timeIntervals={10}
                                                        dateFormat="MMMM d, yyyy "
                                                        timeCaption="time"
                                                        placeholderText="Select date "
                                                    />
                                                </div>
                                                   
                                            
                                                {
                                                    this.state.exportingStreamReport
                                                        ?
                                                        <button type="button" className="room-join-btn" disabled={this.state.exportingStreamReport}>
                                                            
                                                            {this.state.exportingStreamReport === true ? 'Exporting...' : 'Export'}
                                                            {this.state.exportingStreamReport === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                                        </button>
                                                        :
                                                        <div>
                                                        <button className="room-join-btn" onClick={() => { this.exportsUserStreamRecords() }}>Export Pdf</button>
                                                        
                                                        <button className="room-join-btn csvbtn"><a target="_blank" href={process.env.REACT_APP_API_URL+'event/downloadUserStreamReport/'+this.state.fetchEventId}>Export Excel</a></button>
                                                        
                                                        </div>
                                                }
                                            </div>

                                            <div className="duration-statistics-tbl">
                                                <div className="grid-table-row statistics-tbl-row grid-table-shadow">
                                                    <div className="grid-table-head">
                                                        <div className="d-flex align-items-center">User</div>
                                                    </div>
                                                    <div className="grid-table-head">
                                                        <div className="d-flex align-items-center justify-content-center">User Role</div>
                                                    </div>
                                                    <div className="grid-table-head">
                                                        <div className="d-flex align-items-center justify-content-center">Duration</div>
                                                    </div>
                                                </div>
                                                <div className="duration-tbl-data-wrapper ">
                                                <div className={this.state.userstreamreports.length > 0 ? "hide":"d-flex align-items-center justify-content-center flex-column h-100"}>
                                                    <div className="no-resourses-lst">
                                                        <img src={require("../img/no-data.svg")} alt="no-data" />
                                                    </div>
                                                    <div className="empty-list-txt">No reports available</div>
                                                </div>
                                                    {this.state.userstreamreports.map((userreport, index) => {
                                                        let startTime = (userreport.start_time !== undefined && userreport.start_time !== null && userreport.start_time !== "") ? userreport.start_time : "";
                                                        let endTime = (userreport.end_time !== undefined && userreport.end_time !== null && userreport.end_time !== "") ? userreport.end_time : "";

                                                        let streamedDuration = 0;

                                                        if (startTime !== "" && endTime !== "") {
                                                            let dt1 = new Date(startTime);
                                                            let dt2 = new Date(endTime);

                                                            // streamedDuration = this.diff_minutes(dt1, dt2)
                                                            streamedDuration = Math.abs(dt1 - dt2) / 1000;
                                                        }

                                                        return (
                                                            <div className="grid-td grid-table-row" key={"userreport_"+index}>
                                                                <div className="grid-table-data">
                                                                    <div className="grid-table-user">{userreport.name}</div>
                                                                </div>
                                                                <div className="grid-table-data">
                                                                    <div className="grid-table-user d-flex justify-content-center">{userreport.role[0].toUpperCase() + userreport.role.slice(1)}</div>
                                                                </div>
                                                                <div className="grid-table-data">
                                                                    <div className="grid-table-user d-flex justify-content-center">{this.timeConvert(streamedDuration)}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                        </React.Fragment>
                                        :
                                        <div className="d-flex align-items-center justify-content-center flex-column h-100">
                                            <div className="no-resourses-lst">
                                                <img src={require("../img/no-data.svg")} alt="no-data" />
                                            </div>
                                            <div className="empty-list-txt">No reports available</div>
                                        </div>
                                    }
                                </React.Fragment>
                            )}

                            {this.state.showEventRecordings && (
                                <React.Fragment>
                                    <div class="rooms-list-name-caption">All Recordings</div>
                                    <div class="recordings-wrapper">
                                        {
                                            this.state.archives && this.state.archives.length > 0
                                            ?
                                                this.state.archives.map((archive, index) => {

                                                    let downloadLink = process.env.REACT_APP_S3_UPLOADS_URL + process.env.REACT_APP_OPENTOK_KEY + "/" + archive.id + "/archive.zip";
                                                    return (
                                                        <div class="d-flex align-items-center justify-content-between recordings-container" key={"recordings_"+index}>
                                                            <div class="recordings-filename">{archive.name}</div>
                                                            <a href={downloadLink} download target="_blank" rel="noopener noreferrer">
                                                                <div className="record-download-btn">Download</div>
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            :

                                                <div className="d-flex align-items-center justify-content-center flex-column h-100">
                                                    <div className="no-resourses-lst">
                                                        <img src={require("../img/no-data.svg")} alt="no-data" />
                                                    </div>
                                                    <div className="empty-list-txt">No recordings available</div>
                                                </div>
                                        }
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </section>

                <Modal isOpen={this.state.isModalOpen} fade={true} centered className={'info-modal-dialog'}>
                    <div className="d-flex justify-content-end popup-close-btn" onClick={() => this.toggleInfoModal()}>
                        &times;
                    </div>
                    <div className="popup-info-icon-wrapper">
                        <img src={require("../img/information.svg")} alt="info" />
                    </div>
                    {this.state.eventInfo !== "" &&
                        <div className="popup-info-desc">
                            <div className="meet-schedul-caption meet-schedul-underline">{this.state.eventInfo.name}</div>
                            <div className="meeting-listing-whole-blk">
                                {this.state.eventInfo.description !== "" &&
                                    <div className="meeting-lst-desc-wrap">
                                        <div className="rooms-lst-label">Description</div>
                                        <div className="meeting-event-desc">
                                            {this.state.eventInfo.description}
                                        </div>
                                    </div>
                                }
                                <div className="meeting-link-desc ">
                                    <div className="d-flex event-meet-link moderator-lk">Moderator :</div>
                                    <div className="d-flex align-items-center meeting-link-url-blk info-link">
                                        <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_MODERATOR + this.state.eventInfo.event_code}>{process.env.REACT_APP_MEETING_LINK_MODERATOR + this.state.eventInfo.event_code}</div>
                                        <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_MODERATOR + this.state.eventInfo.event_code)}>
                                            <img src={require("../img/copy-url.svg")} alt="copy url" />
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.eventInfo.enableSecondaryModerator === true && (
                                        <div className="meeting-link-desc ">
                                            <div className="d-flex event-meet-link moderator-lk">Secondary Moderator :</div>
                                            <div className="d-flex align-items-center meeting-link-url-blk info-link">
                                                <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_SECONDARY_MODERATOR + this.state.eventInfo.event_code}>{process.env.REACT_APP_MEETING_LINK_SECONDARY_MODERATOR + this.state.eventInfo.event_code}</div>
                                                <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_SECONDARY_MODERATOR + this.state.eventInfo.event_code)}>
                                                    <img src={require("../img/copy-url.svg")} alt="copy url" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="meeting-link-desc ">
                                    <div className="d-flex event-meet-link moderator-lk">Speaker :</div>
                                    <div className="d-flex align-items-center meeting-link-url-blk info-link">
                                        <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_SPEAKER + this.state.eventInfo.event_code}>{process.env.REACT_APP_MEETING_LINK_SPEAKER + this.state.eventInfo.event_code}</div>
                                        <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_SPEAKER + this.state.eventInfo.event_code)}>
                                            <img src={require("../img/copy-url.svg")} alt="copy url" />
                                        </div>
                                    </div>
                                </div>
                                <div className="meeting-link-desc">
                                    <div className="d-flex event-meet-link moderator-lk">Interpreter :</div>
                                    <div className="d-flex align-items-center meeting-link-url-blk info-link">
                                        <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_INTERPRETER + this.state.eventInfo.event_code}>{process.env.REACT_APP_MEETING_LINK_INTERPRETER + this.state.eventInfo.event_code}</div>
                                        <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_INTERPRETER + this.state.eventInfo.event_code)}>
                                            <img src={require("../img/copy-url.svg")} alt="copy url" />
                                        </div>
                                    </div>
                                </div>
                                <div className="meeting-link-desc">
                                    <div className="d-flex event-meet-link moderator-lk">Viewer :</div>
                                    <div className="d-flex align-items-center meeting-link-url-blk info-link">
                                        <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_LEARNER + this.state.eventInfo.event_code}>{process.env.REACT_APP_MEETING_LINK_LEARNER + this.state.eventInfo.event_code}</div>
                                        <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_LEARNER + this.state.eventInfo.event_code)}>
                                            <img src={require("../img/copy-url.svg")} alt="copy url" />
                                        </div>
                                    </div>
                                </div>

                                {this.state.eventInfo.streamOut !== undefined && this.state.eventInfo.streamOut !== null && this.state.eventInfo.streamOut === true && (
                                    <div className="meeting-link-desc">
                                        <div className="d-flex event-meet-link moderator-lk">Stream Out :</div>
                                        <div className="d-flex align-items-center meeting-link-url-blk info-link">
                                            <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_STREAMOUT + this.state.eventInfo.event_code}>{process.env.REACT_APP_MEETING_LINK_STREAMOUT + this.state.eventInfo.event_code}</div>
                                            <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_STREAMOUT + this.state.eventInfo.event_code)}>
                                                <img src={require("../img/copy-url.svg")} alt="copy url" />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {this.state.eventInfo.signLanguageMode !== undefined && this.state.eventInfo.signLanguageMode !== null && this.state.eventInfo.signLanguageMode === true && (
                                    <div className="meeting-link-desc">
                                        <div className="d-flex event-meet-link moderator-lk">Sign Language Mode :</div>
                                        <div className="d-flex align-items-center meeting-link-url-blk info-link">
                                            <div className="meeting-link-url" title={process.env.REACT_APP_MEETING_LINK_SIGNLANGUAGE + this.state.eventInfo.event_code}>{process.env.REACT_APP_MEETING_LINK_SIGNLANGUAGE + this.state.eventInfo.event_code}</div>
                                            <div className="meet-link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_SIGNLANGUAGE + this.state.eventInfo.event_code)}>
                                                <img src={require("../img/copy-url.svg")} alt="copy url" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </Modal>

            </React.Fragment>
        )
    }
}
