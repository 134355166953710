import React from 'react';
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/custom-vc.css";
import "./assets/css/custom.css";
import "./assets/css/custom-login.css";
import eventmanagement from "./components/eventManagment";
import meetingCodeLogin from "./components/login/loginWithUrl";
import login from "./components/login/login";
import logout from "./components/login/logout";
import events from "./components/events";
import streamreports from "./components/streamreports";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from './lib/PrivateRoute';

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
            <PrivateRoute path="/schedule" exact component={eventmanagement} />
            <Route path="/" exact component={login} />
            <Route path="/events" exact component={events} />
            <Route path="/events/downloadStreamReports" exact component={streamreports} />
            <Route path="/events/:id" exact component={eventmanagement} />
            <PrivateRoute path="/logout" exact component={logout} />
            <Route path="/:id" exact component={meetingCodeLogin} />
        </Switch>
      </Router>
    );
  }
}
