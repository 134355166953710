import React from "react";
import Swal from 'sweetalert2';
import apiEventService from "../../service/api.eventService";
import readXlsxFile from 'read-excel-file';
import ClipLoader from "react-spinners/ClipLoader";
import { debounce } from "debounce";


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export default class Participants extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            eventData: [],
            speakerUsers: [],
            viewerUsers: [],
            speakerLoader: false,
            listnerLoader: false,
            speakerSave: false,
            speakerSaveLoader: false,
            listenerSaveloader: false,
            listenerSave: false,
            callUserFn: false
        }

    }

    componentDidUpdate() {

        if (this.props.eventData._id !== undefined && this.props.activeTab === '7') {
            this.setState({ eventData: this.props.eventData });

            if (this.props.eventData._id !== this.state.eventData._id && this.props.activeTab === '7') {
                this.getImportedSpeakers();
                this.getImportedLearners();
            } else if (this.props.flag !== undefined && this.props.flag === true) {
                if (this.state.callUserFn === false) {
                    this.setState({ callUserFn: true }, () => {
                        this.getImportedSpeakers();
                        this.getImportedLearners();
                    })
                }

            }
        } else if (this.props.eventData._id === undefined && this.props.activeTab === '7') {
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please try again!"
            });
            this.props.stepToInterpreter();
        }

        let password = (this.props.eventData.password !== undefined && this.props.eventData.password !== null && this.props.eventData.password !== "") ? this.props.eventData.password : "";
        document.getElementById("rafiky-user-password").value = password;
    }

    getImportedSpeakers = () => {
        let checkEventJoiningUserDetails = {
            event_id: this.props.eventData._id,
            speaker_status: true,
            back_end_user: true,
            role: 'speaker'
        }
        apiEventService.checkEventUserDetailsExists(checkEventJoiningUserDetails).then((data) => {
            try {

                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        let speakerUsers = data.data.data;
                        if (speakerUsers !== undefined && speakerUsers.length > 0) {

                            let speakerEmail = []
                            speakerUsers.map((users) => {
                                return speakerEmail.push(users.email)
                            })
                            this.setState({ speakerUsers: speakerEmail });
                            document.getElementById('speaker_users_textarea').value = speakerEmail.join(';');
                        } else {
                            document.getElementById('speaker_users_textarea').value = "";
                        }

                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again"
                    })
                }
            } catch (e) {
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. Please try again"
                })
            }
        })
    }


    getImportedLearners = () => {
        let checkEventJoiningUserDetails = {
            event_id: this.props.eventData._id,
            listener_status: true,
            back_end_user: true,
            role: 'listener'
        }
        apiEventService.checkEventUserDetailsExists(checkEventJoiningUserDetails).then((data) => {
            try {

                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        let listenerUsers = data.data.data;
                        if (listenerUsers !== undefined && listenerUsers.length > 0) {

                            let listenerEmail = []
                            listenerUsers.map((users) => {
                                return listenerEmail.push(users.email)
                            })
                            this.setState({ viewerUsers: listenerEmail });
                            document.getElementById('viewer_users_textarea').value = listenerEmail.join(';');
                        } else {
                            document.getElementById('viewer_users_textarea').value = ""
                        }

                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again"
                    })
                }
            } catch (e) {
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. Please try again"
                })
            }
        })
    }

    copyLink = (link) => {
        navigator.clipboard.writeText(link);
        Toast.fire({
            icon: 'success',
            title: 'Link copied successfully'
        })
    }

    finishEvent = () => {
        let password = "";
        password = document.getElementById("rafiky-user-password").value;
        let eventParams = {
            'password': password,
            'finish': true,
            '_id': this.state.eventData._id
        }
        apiEventService.updateEventDetails(eventParams).then((data) => {

            if (data && data !== undefined && data !== null && data !== "") {
                if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        title: "Event Updated Successfully"
                    })
                    this.props.history.push("/events");
                    if (this.coverImageLoaded === false && this.logoImageLoaded === false) {
                        this.props.saveEventDetails(data.data);
                    } else {
                        this.setState({ disableBtn: false });
                    }
                } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Unauthorized Access"
                    })
                } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Please revalidate the form and submit"
                    })
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: "No response from the server. Please try again!"
                })
            }

        });
    }

    uploadExcel = (params) => {

        if (params === 'speaker') {
            this.setState({ speakerLoader: true, speakerSave: false })
            document.getElementById("speaker-email-error").innerHTML = "";
            let input = document.getElementById('speaker_users');
            let speakerEmail = [];
            const ext = ['.xls', '.xlsx'];
            if (input.files[0] !== undefined && input.files[0] !== null) {
                let filextension = ext.some(el => input.files[0].name.endsWith(el));
                if (filextension === true) {
                    try {
                        let speakers = this.state.speakerUsers;
                        let invalidEmail = []
                        readXlsxFile(input.files[0]).then((rows) => {
                            try {
                                rows.map((value, key) => {
                                    if (key !== 0 && rows[0] !== undefined && rows[0][0].toLowerCase() === 'email') {
                                        if (this.validateEmail(value) === true) {
                                            document.getElementById("speaker-email-error").innerHTML = "";
                                            speakerEmail.push(value);
                                            if (key + 1 === rows.length) {
                                                this.setState({ speakerLoader: false, speakerSave: true })
                                                let allspeakers = [...speakerEmail, ...speakers];
                                                let uniqueArray = allspeakers.join(',');
                                                uniqueArray = uniqueArray.split(',')
                                                uniqueArray = uniqueArray.map(v => v.toLowerCase());

                                                let uniqueSpeakers = uniqueArray.filter(function (item, pos) {
                                                    return uniqueArray.indexOf(item) === pos;
                                                })
                                                if (invalidEmail.length > 0) {
                                                    document.getElementById("speaker-email-error").innerHTML = "Invalid email are (not imported. Please enter manually):" + invalidEmail.join(';');
                                                }
                                                document.getElementById('speaker_users_textarea').value = uniqueSpeakers.join(';');
                                            }

                                        } else {
                                            invalidEmail.push(value);
                                            if (key + 1 === rows.length && invalidEmail.length > 0) {
                                                document.getElementById("speaker-email-error").innerHTML = "Invalid email :" + invalidEmail.join(';');
                                            }
                                        }
                                    } else {
                                        this.setState({ speakerLoader: false, speakerSave: false })
                                        document.getElementById("speaker-email-error").innerHTML = "Please upload valid excel";
                                    }
                                    return true;
                                })

                            } catch (error) {
                                console.log(error);
                                this.setState({ speakerLoader: false, speakerSave: false })
                                document.getElementById("speaker-email-error").innerHTML = "Something went wrong. Please try again!";
                            }
                        }).catch(err => {
                            this.setState({ speakerLoader: false, speakerSave: false })
                            document.getElementById("speaker-email-error").innerHTML = "File size exceeds"
                        })

                    } catch (error) {
                        console.log(error);

                        this.setState({ speakerLoader: false, speakerSave: false })
                        document.getElementById("speaker-email-error").innerHTML = "Something went wrong. Please try again!";

                    }
                } else {

                    this.setState({ speakerLoader: false, speakerSave: false })
                    document.getElementById("speaker-email-error").innerHTML = "Please upload excel file";
                }

            } else {
                this.setState({ speakerLoader: false, speakerSave: false });
                document.getElementById("speaker-email-error").innerHTML = "Please upload a valid excel file";
            }
        } else if (params === 'viewer') {
            this.setState({ listnerLoader: true })

            document.getElementById("viewer-email-error").innerHTML = "";
            let input = document.getElementById('viewer_users');
            let viewerEmail = [];
            const ext = ['.xls', '.xlsx'];
            if (input.files[0] !== undefined && input.files[0] !== null) {
                let filextension = ext.some(el => input.files[0].name.endsWith(el));
                if (filextension === true) {
                    try {
                        let invalidEmail = []
                        readXlsxFile(input.files[0]).then((rows) => {
                            try {
                                rows.map((value, key) => {
                                    if (key !== 0 && rows[0] !== undefined && rows[0][0].toLowerCase() === 'email') {
                                        if (this.validateEmail(value) === true) {
                                            document.getElementById("viewer-email-error").innerHTML = "";
                                            viewerEmail.push(value);
                                            if (key + 1 === rows.length) {
                                                this.setState({ listnerLoader: false, listenerSave: true })
                                                let allviewers = [...viewerEmail, ...this.state.viewerUsers];
                                                let uniqueArray = allviewers.join(',');
                                                uniqueArray = uniqueArray.split(',')
                                                uniqueArray = uniqueArray.map(v => v.toLowerCase());

                                                let uniquesViewers = uniqueArray.filter(function (item, pos) {
                                                    return uniqueArray.indexOf(item) === pos;
                                                })
                                                if (invalidEmail.length > 0) {
                                                    document.getElementById("viewer-email-error").innerHTML = "Invalid email are (not imported. Please enter manually):" + invalidEmail.join(';');
                                                }
                                                document.getElementById('viewer_users_textarea').value = uniquesViewers.join(';');
                                            }
                                        } else {
                                            invalidEmail.push(value)
                                            if (key + 1 === rows.length) {
                                                document.getElementById("viewer-email-error").innerHTML = "Invalid email :" + invalidEmail.join(';');
                                            }
                                        }
                                    } else {
                                        this.setState({ listnerLoader: false, listenerSave: false })
                                        document.getElementById("viewer-email-error").innerHTML = "Please upload valid excel";
                                    }
                                    return true;
                                })
                            } catch (error) {
                                this.setState({ listnerLoader: false, listenerSave: false })
                                document.getElementById("viewer-email-error").innerHTML = "Something went wrong. Please try again!";
                            }
                        }).catch(err => {
                            this.setState({ listnerLoader: false, listenerSave: false })
                            document.getElementById("viewer-email-error").innerHTML = "File size exceeds"
                        })
                    } catch (error) {
                        this.setState({ listnerLoader: false, listenerSave: false })
                        document.getElementById("viewer-email-error").innerHTML = "Something went wrong. Please try again!";

                    }
                } else {
                    this.setState({ listnerLoader: false, listenerSave: false })
                    document.getElementById("viewer-email-error").innerHTML = "Please upload excel file";
                }

            } else {
                this.setState({ listnerLoader: false, listenerSave: false });
                document.getElementById("speaker-email-error").innerHTML = "Please upload a valid excel file";
            }
        }
    }

    checkImportedUser = debounce((type) => {
        this.checkImportedUserList(type);
    }, 500)

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    checkImportedUserList = (type, flag = 0) => {
        let users = ""
        if (type === 'speaker') {
            users = document.getElementById('speaker_users_textarea').value;
            document.getElementById("speaker-email-error").innerHTML = "";
        } else {
            users = document.getElementById('viewer_users_textarea').value;
            document.getElementById("viewer-email-error").innerHTML = "";
        }
        let userArrayFilterd = [];
        if (users.trim() !== "") {
            let userArray = users.split(';');

            userArrayFilterd = userArray.filter(function (el) { return el.trim() !== ""; });
            userArrayFilterd = userArrayFilterd.map(v => v.toLowerCase());
            let errorEmail = [];
            if (userArrayFilterd.length > 0) {
                for (let index = 0; index < userArrayFilterd.length; index++) {
                    let userEmail = userArrayFilterd[index].trim();
                    let validation = this.validateEmail(userEmail);

                    if (validation === false) {
                        errorEmail.push(userEmail);
                    }

                    if (userArrayFilterd.length === index + 1) {
                        let listedUsers = userArrayFilterd.filter(function (item, pos) {
                            return userArrayFilterd.indexOf(item) === pos;
                        })
                        if (errorEmail.length > 0) {
                            if (type === 'speaker') {
                                this.setState({ speakerLoader: false, speakerSave: false })
                                document.getElementById("speaker-email-error").innerHTML = "Invalid email " + errorEmail.join(';');
                            } else {
                                this.setState({ listnerLoader: false, listenerSave: false })
                                document.getElementById("viewer-email-error").innerHTML = "Invalid email " + errorEmail.join(';');
                            }
                        } else {
                            if (flag === 1) {
                                return listedUsers;
                            } else {
                                if (type === 'speaker') {
                                    this.setState({ speakerSave: true, speakerUsers: listedUsers })
                                } else {
                                    this.setState({ listenerSave: true, viewerUsers: listedUsers })
                                }
                            }

                        }

                    }
                }
            } else {
                if (flag === 1) {
                    return userArrayFilterd;
                } else {
                    this.setState({ speakerSave: false, listenerSave: false })
                }
            }
        } else {
            if (flag === 1) {
                return userArrayFilterd;
            } else {
                if (type === 'speaker') {
                    if (this.state.speakerUsers.length > 0) {
                        this.setState({ speakerSave: true })
                    } else {
                        this.setState({ speakerSave: false })
                    }
                } else {
                    if (this.state.viewerUsers.length > 0) {
                        this.setState({ listenerSave: true })
                    } else {
                        this.setState({ listenerSave: false })
                    }
                }



            }
        }
    }


    showErrorMsg = (msg) => {

        Swal.fire({
            html: msg,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#00d2a5',
            customClass: {
                confirmButton: 'green-bg-white-f-btn'
            },
            focusConfirm: false,
            focusCancel: true
        }).then(() => {
            this.setState({ speakerSave: false, listenerSave: false })
            document.getElementById('speaker_users_textarea').value = "";
            document.getElementById('viewer_users_textarea').value = "";
            this.getImportedLearners();
            this.getImportedSpeakers();
        });;

    }

    saveImportedUsers = async (type) => {
        if (type === 'speaker') {
            this.setState({ speakerSaveLoader: true, speakerSave: true })
        } else {
            this.setState({ listenerSaveloader: true, listenerSave: true })
        }

        let userList = await this.checkImportedUserList(type, 1);
        if (this.state.viewerUsers.length > 0 || this.state.speakerUsers.length > 0 || userList.length > 0) {

            let eventParams = {
                'user_list': userList,
                'type': type,
                '_id': this.state.eventData._id
            }


            apiEventService.uploadEventUsers(eventParams).then((data) => {
                if (type === 'speaker') {
                    this.setState({ speakerSaveLoader: false })
                } else {
                    this.setState({ listenerSaveloader: false })
                }
                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        let errorMessage = "<div>";
                        let errorMessageStatus = false;
                        if (data.data.interpreterError !== undefined && data.data.interpreterError.length > 0) {
                            errorMessageStatus = true;
                            errorMessage += " <br/> <br/> Email already assigned to interpreter  : " + data.data.interpreterError.join(';');
                        }

                        if (data.data.alreadyExistsUserError !== undefined && data.data.alreadyExistsUserError.length > 0) {
                            let listnersArray = []
                            data.data.alreadyExistsUserError.filter(function (item) {
                                if (item.listener_status === true) {
                                    listnersArray.push(item.email)
                                }
                                return true
                            });
                            let speakerArray = []
                            data.data.alreadyExistsUserError.filter(function (item) {
                                if (item.speaker_status === true) {
                                    speakerArray.push(item.email)
                                }
                                return true
                            });

                            if (type === 'speaker') {
                                if (listnersArray.length > 0) {
                                    errorMessageStatus = true;
                                    errorMessage += " <br/> <br/> Email already assigned to Listener  : " + listnersArray.join(';');
                                }
                            } else {
                                if (speakerArray.length > 0) {
                                    errorMessageStatus = true;
                                    errorMessage += " <br/> <br/> Email already assigned to speaker  : " + speakerArray.join(';');
                                }
                            }

                        }
                        errorMessage += "</div>";
                        if (errorMessageStatus === true) {
                            this.showErrorMsg(errorMessage)
                        } else {

                            document.getElementById('speaker_users_textarea').value = "";
                            document.getElementById('viewer_users_textarea').value = "";
                            this.getImportedLearners();
                            this.getImportedSpeakers();
                            if (type === 'speaker') {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Speaker Updated Successfully"
                                })

                                this.setState({ speakerSave: false })
                            } else {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Listener Updated Successfully"
                                })

                                this.setState({ listenerSave: false })
                            }

                        }


                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }

            });

        } else {
            if (type === 'speaker') {
                this.setState({ speakerSaveLoader: false })
                document.getElementById("speaker-email-error").innerHTML = "No User Added";
            } else {
                this.setState({ listenerSaveloader: false })
                document.getElementById("viewer-email-error").innerHTML = "No User Added";
            }
        }

    }



    render() {
        return (
            <React.Fragment>
                <div className="participants-blk">
                    <div className="meeting-links-header">
                        View Meeting Links
			        </div>
                    <div className="meeting-links-wrapper">
                        <div className="meeting-links-container">
                            <div className="meeting-link-subheader">Moderator Link :</div>
                            <div className="d-flex align-items-center link-url-wrapper">
                                 <div className="link-url-type">{process.env.REACT_APP_MEETING_LINK_MODERATOR + this.state.eventData.event_code}</div>
                                <div className="link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_MODERATOR + this.state.eventData.event_code)}>Copy Link</div>
                            </div>
                            <div className="formgrp-txt-wrapper pass-field">
                                <label className="formgrp-label">Password</label>
                                <input name="eventPassword" id="rafiky-user-password" type="text" maxLength="50" className="form-input" placeholder="Enter Password" />
                            </div>
                            <div className="meeting-link-code">Meeting Code : <span>{this.state.eventData.event_code}</span></div><br/>
                            {
                                this.state.eventData.enableSecondaryModerator === true && (
                                    <span>
                                        <div className="meeting-link-subheader">Secondary Moderator Link :</div>
                                        <div className="d-flex align-items-center link-url-wrapper">
                                            <div className="link-url-type">{process.env.REACT_APP_MEETING_LINK_SECONDARY_MODERATOR + this.state.eventData.event_code}</div>
                                            <div className="link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_SECONDARY_MODERATOR + this.state.eventData.event_code)}>Copy Link</div>
                                        </div>
                                    </span>
                                )
                            }
                            
                           
                        </div>
                        <div className="meeting-links-container speaker">
                            <div className="meeting-link-subheader">Speaker Link :</div>
                            <div className="d-flex align-items-center link-url-wrapper">
                                <div className="link-url-type">{process.env.REACT_APP_MEETING_LINK_SPEAKER + this.state.eventData.event_code}</div>
                                <div className="link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_SPEAKER + this.state.eventData.event_code)}>Copy Link</div>
                            </div>

                            <div className="formgrp-txt-wrapper pass-field">
                                <label className="formgrp-label">Import Speakers (eg: example@gmail.com;)</label>
                                <div className="d-flex justify-content-between import-users-blk">
                                    <textarea className="form-input" id="speaker_users_textarea" onChange={() => { this.checkImportedUser('speaker') }} />
                                    <div className="add-mater-blk">
                                        {this.state.speakerSave ? this.state.speakerSaveLoader ? <label className="add-mat-btn formgrp-label">Saving...  <ClipLoader size={15} color={"#fff"} loading={true} /></label> : <label className="add-mat-btn formgrp-label" onClick={() => this.saveImportedUsers('speaker')}>Save</label> :
                                            this.state.speakerLoader ? <label className="add-mat-btn formgrp-label"> Importing... <ClipLoader size={15} color={"#fff"} loading={true} /></label> :
                                                <label className="add-mat-btn formgrp-label"><input type="file" id="speaker_users" className="d-none" onChange={() => this.uploadExcel('speaker')} />Import</label>
                                        }
                                    </div>
                                </div>
                                <label className="formgrp-label" style={{marginTop:'10px'}}>NOTE: If the textbox for uploading a list is blank or without any xlsx file uploaded,<br /> the events remain "free to join" </label>
                                <span id="speaker-email-error" className="text-danger"></span>
                            </div>
                            <div className="meeting-link-code">Meeting Code : <span>{this.state.eventData.event_code}</span></div>
                        </div>
                        <div className="meeting-links-container">
                            <div className="meeting-link-subheader">Interpreter Link :</div>
                            <div className="d-flex align-items-center link-url-wrapper">
                                <div className="link-url-type">{process.env.REACT_APP_MEETING_LINK_INTERPRETER + this.state.eventData.event_code}</div>
                                <div className="link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_INTERPRETER + this.state.eventData.event_code)}>Copy Link</div>
                            </div>

                            <div className="meeting-link-code">Meeting Code : <span>{this.state.eventData.event_code}</span></div>
                        </div>
                        <div className="meeting-links-container viewer">
                            <div className="meeting-link-subheader">Viewer Link :</div>
                            <div className="d-flex align-items-center link-url-wrapper">
                                <div className="link-url-type">{process.env.REACT_APP_MEETING_LINK_LEARNER + this.state.eventData.event_code}</div>
                                <div className="link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_LEARNER + this.state.eventData.event_code)}>Copy Link</div>
                            </div>
                            <div className="formgrp-txt-wrapper pass-field">
                                <label className="formgrp-label">Import Viewers (eg: example@gmail.com;)</label>
                                <div className="d-flex justify-content-between import-users-blk">
                                    <textarea className="form-input" id="viewer_users_textarea" onChange={() => { this.checkImportedUser('viewer') }} />

                                    <div className="add-mater-blk">
                                        {this.state.listenerSave ? this.state.listenerSaveloader ? <label className="add-mat-btn formgrp-label">Saving...  <ClipLoader size={15} color={"#fff"} loading={true} /></label> : <label className="add-mat-btn formgrp-label" onClick={() => this.saveImportedUsers('viewer')}>Save</label> : this.state.listnerLoader ? <label className="add-mat-btn formgrp-label"> Importing... <ClipLoader size={15} color={"#fff"} loading={true} /></label> :
                                            <label className="add-mat-btn formgrp-label"><input type="file" id="viewer_users" className="d-none" onChange={() => this.uploadExcel('viewer')} />Import</label>
                                        }

                                    </div>
                                </div>
                                <label className="formgrp-label" style={{ marginTop: '10px' }}>NOTE: If the textbox for uploading a list is blank or without any xlsx file uploaded, <br /> the events remain "free to join" </label>
                                <span id="viewer-email-error" className="text-danger"></span>
                            </div>
                            <div className="meeting-link-code">Meeting Code : <span>{this.state.eventData.event_code}</span></div>
                        </div>

                        {this.state.eventData.streamOut !== undefined && this.state.eventData.streamOut !== null && this.state.eventData.streamOut && (
                            <div className="meeting-links-container">
                                <div className="meeting-link-subheader">Stream Out Link :</div>
                                <div className="d-flex align-items-center link-url-wrapper">
                                    <div className="link-url-type">{process.env.REACT_APP_MEETING_LINK_STREAMOUT + this.state.eventData.event_code}</div>
                                    <div className="link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_STREAMOUT + this.state.eventData.event_code)}>Copy Link</div>
                                </div>
                                <div className="meeting-link-code">Meeting Code : <span>{this.state.eventData.event_code}</span></div>
                            </div>
                        )}

                        {this.state.eventData.signLanguageMode !== undefined && this.state.eventData.signLanguageMode !== null && this.state.eventData.signLanguageMode && (
                            <div className="meeting-links-container">
                                <div className="meeting-link-subheader">Sign Language Link :</div>
                                <div className="d-flex align-items-center link-url-wrapper">
                                    <div className="link-url-type">{process.env.REACT_APP_MEETING_LINK_SIGNLANGUAGE + this.state.eventData.event_code}</div>
                                    <div className="link-url-copy" onClick={() => this.copyLink(process.env.REACT_APP_MEETING_LINK_SIGNLANGUAGE + this.state.eventData.event_code)}>Copy Link</div>
                                </div>
                                <div className="meeting-link-code">Meeting Code : <span>{this.state.eventData.event_code}</span></div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex align-items-center btn-acn-blk">
                    <button type="button" onClick={() => this.props.stepToInterpreter()} className="prev-btn">Prev</button>
                    <button type="button" onClick={() => this.finishEvent()} className="next-btn">Finish</button>
                </div>
            </React.Fragment>
        )
    }
}