import Axioslib from "../lib/Axioslib";

const getLanguages = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.get("/interpreter/language")
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getSessionsByEventID = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("agenda/getSessionsByEventID", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const updateAgenda = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("agenda/updateAgenda", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const addAgenda = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("agenda/addAgenda", body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const getAgenda = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.get("agenda/getAgenda")
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const deleteAgenda = (id) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.delete("agenda/deleteAgenda/"+id)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getRoomsByEventID = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("event/getEventRooms", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const addRoom = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("room/addRoom", body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const updateRoom = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("room/updateRoom", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const deleteRoom = (id) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.delete("room/deleteRoom/"+id)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const getLanguagesByEventID = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("language/getLanguagesByEventID", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const addLanguage = (body) => {    
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("language/addLanguage", body)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}
const updateLanguage = (body) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("language/updateLanguage", body)
                .then(response => {
                    if (response) {
                        resolve(response)
                    } else {
                        reject({})
                    }
                }, error => {
                    resolve(error.response);
                });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const deleteLanguage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            Axioslib.delete("language/deleteLanguage/"+id)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const checkEmailAlreadyExists = (email) =>{
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("login/checkEmailAlreadyExists", email)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}


const loginwithPassword = (email) =>{
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("login/loginwithPassword", email)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}

const loginWithMeetingDetails = (email) =>{
    return new Promise((resolve, reject) => {
        try {
            Axioslib.post("login/loginWithMeetingDetails", email)
            .then(response => {
                if (response) {
                    resolve(response)
                } else {
                    reject({})
                }
            }, error => {
                resolve(error.response);
            });
        } catch (e) {
            resolve({ "error": true, "message": "Server can't be reached" });
        }
    })
}


export const api = {
    getLanguages  : getLanguages,
    getSessionsByEventID: getSessionsByEventID,
    addAgenda : addAgenda,
    updateAgenda: updateAgenda,
    getAgenda : getAgenda,
    deleteAgenda : deleteAgenda,
    getRoomsByEventID: getRoomsByEventID,
    addRoom : addRoom,
    updateRoom: updateRoom,
    deleteRoom: deleteRoom,
    getLanguagesByEventID: getLanguagesByEventID,
    addLanguage: addLanguage,
    updateLanguage: updateLanguage,
    deleteLanguage:deleteLanguage,
    checkEmailAlreadyExists:checkEmailAlreadyExists,
    loginwithPassword:loginwithPassword,
    loginWithMeetingDetails:loginWithMeetingDetails
}

export default api;