import React from "react";
import { Modal } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import apiInterPreterService from "../../service/api.interpreterService";
import Swal from 'sweetalert2';
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import helper from '../../service/helper';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default class Interpreters extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            languages: [],
            assignmentDetails: [],
            selectedFile: '',
            eventMaterials: [],
            eventID: '',
            disableBtn: false,
            errorMsg: '',
            isAddEventModalOpen: false,
            isViewEventModalOpen: false,
            disableSaveMaterialBtn: false,
            interpreterId: "",
            sessionErrorMsg: "",
            updateUsers: false
        }
        this.session = [];
    }

    componentDidMount() {
        this.splittedURL = this.props.location.pathname.split("/");
        if (this.splittedURL[2] !== "" && this.splittedURL[2] !== undefined) {
            this.eventID = this.splittedURL[2];
            this.getInterpretersByEventID();
            this.getEventMaterialsByEventID();
        }
    }

    getInterpretersByEventID = () => {
        let params = {
            event_id: this.eventID
        }
        apiInterPreterService.getInterpretersByEventID(params).then((data) => {
            if (data && data !== undefined && data !== null && data !== "" && data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                if (data.data.data !== "" && data.data.data !== undefined) {
                    this.setState({ assignmentDetails: data.data.data });
                }
            }
        });
    }

    getEventMaterialsByEventID = () => {
        let params = {
            event_id: this.eventID
        }
        apiInterPreterService.getEventMaterialsByEventID(params).then((data) => {
            if (data && data !== undefined && data !== null && data !== "" && data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                if (data.data.data !== "" && data.data.data !== undefined) {
                    this.setState({ eventMaterials: data.data.data });
                }
            }
        });
    }

    componentDidUpdate() {
        if (this.props.eventData._id !== undefined && this.props.activeTab === '6' && this.state.eventID !== this.props.eventData._id) {
            this.setState({ eventID: this.props.eventData._id });
        } else if (this.props.eventData._id === undefined && this.props.activeTab === '6') {
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please try again!"
            });
            this.props.stepToAgenda();
        }

        if (this.props.languages !== undefined && this.state.languages !== this.props.languages) {
            this.setState({ languages: this.props.languages }, () => {
                this.updateInterpreterDetails('language');
            });
        }

        if (this.props.session !== undefined && this.props.session.length > 0 && this.session !== this.props.session) {
            this.session = this.props.session;
            this.updateInterpreterDetails('session');
        }
    }

    updateInterpreterDetails = (type) => {
        if (this.state.assignmentDetails.length > 0) {
            if (type === 'session') {
                let newInterpreter = [];
                let interpreterDetails = this.state.assignmentDetails;
                interpreterDetails.map((interpreter) => {
                    if (interpreter.session.length > 1) {
                        let newIntSession = [];
                        interpreter.session.map((sess) => {
                            if (this.session.filter(session => (session.value === sess)) !== undefined
                                && this.session.filter(session => (session.value === sess))[0] !== undefined
                                && this.session.filter(session => (session.value === sess))[0].label !== undefined) {
                                let sessionName = this.session.filter(session => (session.value === sess))[0].label;
                                if (sessionName !== undefined && sessionName !== "") {
                                    newIntSession.push(sess);
                                }
                            }
                            return true;
                        })
                        if (newIntSession.length > 0) {
                            interpreter['session'] = newIntSession;
                            newInterpreter.push(interpreter);
                        }
                    } else {
                        if (this.session.filter(session => (session.value === interpreter.session[0])) !== undefined
                            && this.session.filter(session => (session.value === interpreter.session[0]))[0] !== undefined
                            && this.session.filter(session => (session.value === interpreter.session[0]))[0].label !== undefined) {
                            let sessionName = this.session.filter(session => (session.value === interpreter.session[0]))[0].label;
                            if (sessionName !== undefined && sessionName !== "") {
                                newInterpreter.push(interpreter);
                            }
                        }
                    }
                    return true;
                });
                let newArray = JSON.stringify(newInterpreter);
                let oldArray = JSON.stringify(this.state.assignmentDetails);
                if (newArray !== oldArray) {
                    this.setState({ assignmentDetails: newInterpreter });
                }

            } else {

                let interpreterDetails = this.state.assignmentDetails;
                let newInterpreter = []
                interpreterDetails.map((interpreter) => {
                    let fromExists = true;
                    let toExists = true;
                    if (interpreter.from !== "" && interpreter.from !== undefined
                        && interpreter.to !== "" && interpreter.to !== undefined) {
                        if (this.state.languages.filter(lang => (lang.value === interpreter.from)) !== undefined
                            && this.state.languages.filter(lang => (lang.value === interpreter.from))[0] !== undefined
                            && this.state.languages.filter(lang => (lang.value === interpreter.from))[0].label !== undefined) {
                            let fromLang = this.state.languages.filter(lang => (lang.value === interpreter.from))[0].label;
                            if (fromLang !== undefined && fromLang !== "") {
                                fromExists = false;
                            }
                        }
                        if (this.state.languages.filter(lang => (lang.value === interpreter.to)) !== undefined
                            && this.state.languages.filter(lang => (lang.value === interpreter.to))[0] !== undefined
                            && this.state.languages.filter(lang => (lang.value === interpreter.to))[0].label !== undefined) {
                            let toLang = this.state.languages.filter(lang => (lang.value === interpreter.to))[0].label;
                            if (toLang !== undefined && toLang !== "") {
                                toExists = false;
                            }
                        }
                    } else {
                        fromExists = false;
                        toExists = false;
                    }
                    if (fromExists === false && toExists === false) {
                        newInterpreter.push(interpreter);
                    }
                    return true;
                });

                let newArray = JSON.stringify(newInterpreter);
                let oldArray = JSON.stringify(this.state.assignmentDetails);
                if (newArray !== oldArray) {
                    this.setState({ assignmentDetails: newInterpreter });
                }
            }

        }
    }

    openAssignmentModal = () => {
        if (this.session.length > 0) {
            this.setState({ isModalOpen: true, disableBtn: false });
        } else {
            Toast.fire({
                icon: 'warning',
                title: "No session available. Please refresh page"
            });
        }
    }

    closeAssignmentModal = () => {
        this.setState({ isModalOpen: false, disableBtn: false, interpreterId: "" }, () => {
            this.initialValues.interpreterName = "";
            this.initialValues.emailId = "";
            this.initialValues.fromLanguage = "";
            this.initialValues.toLanguage = "";
            this.initialValues.session = "";
            this.initialValues.twoWayAllowed = false;
        });
    }

    validationSchema = Yup.object().shape({
        interpreterName: Yup.string().trim().required('Name is required!'),
        emailId: Yup.string()
            .trim()
            .email("Email is invalid")
            .required("Email is required"),
        // fromLanguage: Yup.string().trim().required('From is required!'),
        // toLanguage: Yup.string().trim().required('To is required!')
        // session: Yup.string().trim().required('Session is required!')
    });

    initialValues = {
        interpreterName: "",
        emailId: "",
        fromLanguage: "",
        toLanguage: "",
        session: "",
        twoWayAllowed: false,
        interpreterVideo: false
    };

    assignmentForm = ({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        handleReset,
        setFieldValue,
    }) => {
        return (
            <React.Fragment>
                <Form onSubmit={handleSubmit}>
                    <div className="">
                        <div className="intrepreter-popup-modal">
                            <div className="d-flex justify-content-end popup-close-btn" onClick={(e) => { this.closeAssignmentModal(); handleReset(e); }}>&times;</div>
                            <div className="popup-content-assignment">
                                <div className="lang-header mt10">Add Interpreter</div>
                                <div className="d-flex interpre-blk">
                                    <div className="lang-wraper mr15 chip-multiselect">
                                        <div className="room-name-label">Name<span className="star-imp">*</span></div>
                                        <Field type="text" maxLength="200" name="interpreterName" className="form-input modal-form-input" />
                                        <ErrorMessage name="interpreterName" component="div" className="validtxt_msg" />
                                    </div>
                                    <div className="lang-wraper">
                                        <div className="room-name-label">Email<span className="star-imp">*</span></div>
                                        <Field type="text" maxLength="1500" name="emailId" className="form-input modal-form-input" />
                                        <ErrorMessage name="emailId" component="div" className="validtxt_msg" />
                                    </div>
                                </div>
                                {this.state.languages.length > 0 && (
                                    <div className="d-flex interpre-blk">
                                        <div className="lang-wraper mr15">
                                            <div className="room-name-label">From<span className="star-imp">*</span></div>

                                            <Select
                                                name="fromLanguage"
                                                value={values.fromLanguage}
                                                options={this.state.languages}
                                                onChange={e => setFieldValue('fromLanguage', e)}
                                                onBlur={handleBlur}
                                                style={{ display: 'block' }}
                                            />
                                            <ErrorMessage name="fromLanguage" component="div" className="validtxt_msg" />
                                        </div>
                                        <div className="lang-wraper">
                                            <div className="room-name-label">To<span className="star-imp">*</span></div>
                                            <Select
                                                name="toLanguage"
                                                value={values.toLanguage}
                                                options={this.state.languages}
                                                onChange={e => setFieldValue('toLanguage', e)}
                                                onBlur={handleBlur}
                                                style={{ display: 'block' }}
                                            />
                                            <ErrorMessage name="toLanguage" component="div" className="validtxt_msg" />
                                        </div>
                                    </div>
                                )}
                                <div className="validtxt_msg" >{this.state.errorMsg} </div>
                                <div className="d-flex align-items-center interpre-blk">
                                    <div className="lang-wraper mr15 select-sess">
                                        <div className="room-name-label">Session<span className="star-imp">*</span></div>
                                        <Select
                                            name="session"
                                            value={values.session}
                                            onChange={e => setFieldValue('session', e)}
                                            options={this.session}
                                            onBlur={handleBlur}
                                            isSearchable={false}
                                            isMulti={true}
                                            style={{ display: 'block' }}
                                        />

                                        <div className="validtxt_msg" >{this.state.sessionErrorMsg} </div>
                                    </div>
                                    <div className="lang-wraper">
                                        <div className="d-flex twoway-selector">
                                            <label className="custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="twoWayAllowed"
                                                    value={values.twoWayAllowed}
                                                    checked={values.twoWayAllowed === true ? "checked" : ""}
                                                    onChange={() => { setFieldValue('twoWayAllowed', !values.twoWayAllowed); }}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className="checkbox-label">Two-Way Allowed</div>
                                        </div>
                                    </div>
                                    <div className="lang-wraper">
                                        <div className="d-flex twoway-selector">
                                            <label className="custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="interpreterVideo"
                                                    value={values.interpreterVideo}
                                                    checked={values.interpreterVideo === true ? "checked" : ""}
                                                    onChange={() => { setFieldValue('interpreterVideo', !values.interpreterVideo); }}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className="checkbox-label">Show Interpreter Video</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center modal-btn-blk room-btn-blk">
                                    <button type="button" className="modal-cancel-btn" onClick={(e) => { this.closeAssignmentModal(); handleReset(e) }}>Cancel</button>
                                    {
                                        this.state.interpreterId !== undefined && this.state.interpreterId !== null && this.state.interpreterId !== ""
                                            ?
                                            <button type="submit" className="modal-save-btn" disabled={this.state.disableBtn}>
                                                {this.state.disableBtn === true ? 'Updating...' : 'Update'}
                                                {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                            </button>
                                            :
                                            <button type="submit" className="modal-save-btn" disabled={this.state.disableBtn}>
                                                {this.state.disableBtn === true ? 'Submitting...' : 'Submit'}
                                                {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }

    submitAssignment = async (values) => {

        let sessionData = [];
        if (values.session !== "" && values.session !== null) {
            values.session.map((data) => {
                return sessionData.push(data.value);
            })
        }

        if (sessionData.length <= 0) {
            this.setState({ sessionErrorMsg: 'Session is required' });
            return false;
        }
        this.setState({ errorMsg: '', sessionErrorMsg: '' });

        let assignmentDetails = {
            'name': values.interpreterName.trim(),
            'email': values.emailId.trim(),
            'from': values.fromLanguage.value,
            'to': values.toLanguage.value,
            'session': sessionData,
            'two_way': values.twoWayAllowed,
            'show_video': values.interpreterVideo,
            'event_id': this.state.eventID
        }
        if (this.state.languages.length > 0 && values.fromLanguage.value === undefined) {
            this.setState({ errorMsg: 'Please select from lanuage' });
        } else if (this.state.languages.length > 0 && values.toLanguage.value === undefined) {
            this.setState({ errorMsg: 'Please select To lanuage' });
        } else if (this.state.languages.length > 0 && assignmentDetails.to === assignmentDetails.from) {
            this.setState({ errorMsg: 'Please select different lanuage' });
        } else if (this.state.interpreterId !== undefined && this.state.interpreterId !== null && this.state.interpreterId !== "") {

            assignmentDetails._id = this.state.interpreterId;

            apiInterPreterService.updateAssignment(assignmentDetails).then((data) => {
                this.setState({ disableBtn: false });
                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: "Interpreter updated successfully"
                        })
                        this.setState({ updateUsers: true })
                        var index = this.state.assignmentDetails.findIndex(
                            interpreters => interpreters._id === this.state.interpreterId
                        );

                        var interpreters = this.state.assignmentDetails;
                        interpreters[index] = data.data.result;

                        this.setState({ assignmentDetails: interpreters, disableBtn: false });
                        this.closeAssignmentModal();

                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }

            });

        } else if (this.state.eventID !== "") {

            this.setState({ disableBtn: true });
            apiInterPreterService.addAssignment(assignmentDetails).then((data) => {
                this.setState({ disableBtn: false });
                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: "Interpreter Added Successfully"
                        })
                        this.setState({
                            assignmentDetails: [...this.state.assignmentDetails, data.data.result],
                            disableBtn: false,
                            updateUsers: true
                        })
                        this.closeAssignmentModal();
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }

            });
        } else {
            Toast.fire({
                icon: 'warning',
                title: "Something went wrong. Please refresh page!"
            });
            this.closeAssignmentModal();
        }


    }



    deleteMaterial = (id, index) => {
        Swal.fire({
            title: 'Delete Event Material',
            text: "Are you sure you want to delete?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes, Proceed",
            cancelButtonText: 'No, cancel',
            confirmButtonColor: '#00d2a5',
            customClass: {
                confirmButton: 'green-bg-white-f-btn'
            },
            focusConfirm: false,
            focusCancel: true
        }).then((result) => {
            if (result.value) {
                try {
                    apiInterPreterService.deleteEventMaterial(id).then((data) => {

                        if (data && data !== undefined && data !== null && data !== "") {
                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Event Material deleted successfully"
                                })
                                this.state.eventMaterials.splice(index, 1);
                                this.setState({ eventMaterials: [...this.state.eventMaterials] });

                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Unauthorized Access"
                                })
                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Please revalidate the form and submit"
                                })
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            }
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "No response from the server. Please try again!"
                            })
                        }
                    });
                } catch (e) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            }
        })
    }

    // initialValues = {
    //     interpreterName: "",
    //     emailId: "",
    //     fromLanguage: "",
    //     toLanguage: "",
    //     session: "",
    //     twoWayAllowed: false
    // };

    editInterpreter = (val) => {

        let Fromlanguage = ""
        if (this.state.languages.filter(lng => (lng.value === val.from)) !== undefined && this.state.languages.filter(lng => (lng.value === val.from))[0] !== undefined && this.state.languages.filter(lng => (lng.value === val.from))[0].label !== undefined) {
            Fromlanguage = this.state.languages.filter(lng => (lng.value === val.from))[0]
        }

        let Tolanguage = ""
        if (this.state.languages.filter(lng => (lng.value === val.to)) !== undefined && this.state.languages.filter(lng => (lng.value === val.to))[0] !== undefined && this.state.languages.filter(lng => (lng.value === val.to))[0].label !== undefined) {
            Tolanguage = this.state.languages.filter(lng => (lng.value === val.to))[0]
        }

        let selectedSessionValue = val.session;
        let selectedSessionData = [];

        for (let sv = 0; sv < selectedSessionValue.length; sv++) {
            if (this.session.filter(session => (session.value === selectedSessionValue[sv])) !== undefined && this.session.filter(session => (session.value === selectedSessionValue[sv]))[0] !== undefined && this.session.filter(session => (session.value === selectedSessionValue[sv]))[0].label !== undefined) {
                selectedSessionData.push(this.session.filter(session => (session.value === selectedSessionValue[sv]))[0])
            }
        }

        this.initialValues.interpreterName = val.name;
        this.initialValues.emailId = val.email;
        this.initialValues.fromLanguage = Fromlanguage;
        this.initialValues.toLanguage = Tolanguage;
        this.initialValues.session = selectedSessionData;
        this.initialValues.twoWayAllowed = val.two_way;
        this.initialValues.interpreterVideo = val.show_video;

        this.setState({ interpreterId: val._id });

        this.openAssignmentModal();

    }

    deleteAssignment = (id, index) => {
        Swal.fire({
            title: 'Delete Interpreter',
            text: "Are you sure you want to delete?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes, Proceed",
            cancelButtonText: 'No, cancel',
            confirmButtonColor: '#00d2a5',
            customClass: {
                confirmButton: 'green-bg-white-f-btn'
            },
            focusConfirm: false,
            focusCancel: true
        }).then((result) => {
            if (result.value) {
                try {
                    apiInterPreterService.deleteAssignment(id).then((data) => {

                        if (data && data !== undefined && data !== null && data !== "") {
                            if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                                Toast.fire({
                                    icon: 'success',
                                    title: "Interpreter deleted successfully"
                                })
                                this.state.assignmentDetails.splice(index, 1);
                                this.setState({ assignmentDetails: [...this.state.assignmentDetails] });

                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Unauthorized Access"
                                })
                            } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Please revalidate the form and submit"
                                })
                            } else {
                                Toast.fire({
                                    icon: 'warning',
                                    title: "Something went wrong. Please try again!"
                                })
                            }
                        } else {
                            Toast.fire({
                                icon: 'warning',
                                title: "No response from the server. Please try again!"
                            })
                        }
                    });
                } catch (e) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            }
        })

    }
    onChangeHandler = event => {
        this.setState({
            selectedFile: ''
        })
        this.validateUploadedFile(event.target.files[0]);
    }

    saveUploadedFileDetails = (data) => {
        if (data && data !== undefined && data !== null && data !== "") {
            let extension = data.originalname.substr(data.originalname.lastIndexOf(".") + 1);
            let uploadedFileDetails = {
                filename: data.filename,
                originalFilename: data.originalname,
                mime: data.mimetype,
                path: data.path,
                extension: extension,
                status: 1,
                size: data.size,
                event_id: this.state.eventID
            }

            apiInterPreterService.addEventMaterials(uploadedFileDetails).then((data) => {
                this.addEventToggleModal();
                this.setState({ disableSaveMaterialBtn: false });

                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: "Event Materials Added Successfully"
                        })

                        this.setState({
                            eventMaterials: [data.data.result, ...this.state.eventMaterials]
                        });
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }

            });

        }
    }

    validateUploadedFile = async (file) => {
        this.setState({ fileFormatErrorMsg: '' })
        const types = [
            // "image/png",
            // "image/jpeg",
            // "image/jpg",
            // "image/webp",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/msword",
            // "video/x-flv",
            // "video/mp4",
            // "audio/mp3",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.ms-powerpoint",
            "application/pdf",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ];
        if (file !== "") {
            if (types.every(type => file.type !== type)) {
                this.setState({
                    fileFormatErrorMsg: file.name + " File format not supported"
                });
            } else if (file['size'] > 5368709120) {
                this.setState({ fileFormatErrorMsg: '5 GB file size exceeds' })
            } else {
                this.setState({
                    selectedFile: file
                })
            }

        } else {
            this.setState({
                fileFormatErrorMsg: "Please upload file"
            })
        }
    };

    onClickHandler = () => {
        if (this.state.selectedFile !== "") {
            const data = new FormData()
            data.append('file', this.state.selectedFile);
            this.setState({ disableSaveMaterialBtn: true });
            apiInterPreterService.uploadFile(data, this.state.eventID).then((data) => {

                this.setState({ disableSaveMaterialBtn: false });

                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: "File Uploaded Successfully"
                        });
                        this.setState({
                            selectedFile: ''
                        })
                        this.saveUploadedFileDetails(data.data);
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }

            });
        } else {
            this.setState({ fileFormatErrorMsg: 'Please upload a file' })
        }

    }
    addEventToggleModal = () => {
        this.setState({ isAddEventModalOpen: !this.state.isAddEventModalOpen, disableSaveMaterialBtn: false, selectedFile: '', fileFormatErrorMsg: '' });
    }
    viewEventToggleModal = () => {
        this.setState({ isViewEventModalOpen: !this.state.isViewEventModalOpen });
    }

    viewFiles = (materialDetails) => {
        window.open(
            process.env.REACT_APP_API_IMAGE_URL + materialDetails.path,
            '_blank'
        );
    }

    render() {

        return (
            <React.Fragment>
                <div className="interpreter-blk">

                    <div className="d-flex interpre-advan-set">
                        <div className="add-room-wrapper assignment-wrap" style={{ 'width': '190px', 'height': '40px' }} onClick={() => this.openAssignmentModal()}>
                            <div className="d-flex align-items-center add-room-txt"><span className="add-room-plus">+</span>Add Interpreter</div>
                        </div>
                        <div className="add-room-wrapper add-event-interpre" style={{ 'height': '40px' }} onClick={() => this.addEventToggleModal()}>
                            <div className="d-flex align-items-center add-room-txt"><span className="add-room-plus">+</span>Add Event Material</div>
                        </div>
                        <div className="view-event-mat" style={{ 'width': '190px', 'height': '40px' }} onClick={() => this.viewEventToggleModal()}>
                            View Event Material
                        </div>
                    </div>

                    {this.state.assignmentDetails.map((value, key) => {
                        let sessionsData = '';
                        let sessionValue = value.session;

                        this.session !== undefined && this.session !== null && this.session !== "" && this.session.length > 0 && (
                            sessionValue.map((sessions, key) => {
                                if (key === 0) {
                                    sessionsData += this.session.filter(sess => (sess.value === sessions))[0].label;
                                } else {
                                    sessionsData += ' , ' + this.session.filter(sess => (sess.value === sessions))[0].label;
                                }
                                return true;
                            })
                        )
                        let Fromlanguage = ""
                        if (this.state.languages.filter(lng => (lng.value === value.from)) !== undefined &&
                            this.state.languages.filter(lng => (lng.value === value.from))[0] !== undefined
                            && this.state.languages.filter(lng => (lng.value === value.from))[0].label !== undefined) {
                            Fromlanguage = this.state.languages.filter(lng => (lng.value === value.from))[0].label

                        }
                        let Tolanguage = ""
                        if (this.state.languages.filter(lng => (lng.value === value.to)) !== undefined &&
                            this.state.languages.filter(lng => (lng.value === value.to))[0] !== undefined
                            && this.state.languages.filter(lng => (lng.value === value.to))[0].label !== undefined) {
                            Tolanguage = this.state.languages.filter(lng => (lng.value === value.to))[0].label

                        }
                        return (
                            <div className="d-flex interpre-wraper" key={key}>
                                <div className="interpre-container">
                                    <div className="interpre-label">Name</div>
                                    <div className="intrepre-val" title={value.name}>{value.name}</div>
                                </div>
                                <div className="interpre-container">
                                    <div className="interpre-label">Email</div>
                                    <div className="intrepre-val" title={value.email}>{value.email}</div>
                                </div>
                                {Fromlanguage !== undefined && Fromlanguage !== null && Fromlanguage !== "" &&
                                    <div className="interpre-container">
                                        <div className="interpre-label">From</div>
                                        <div className="intrepre-val">{Fromlanguage}</div>
                                    </div>
                                }
                                {Tolanguage !== undefined && Tolanguage !== null && Tolanguage !== "" &&
                                    <div className="interpre-container  session-col">
                                        <div className="interpre-label">To</div>
                                        <div className="intrepre-val">{Tolanguage}</div>
                                    </div>
                                }
                                <div className="interpre-container">
                                    <div className="interpre-label">Session</div>
                                    <div className="intrepre-val" title={sessionsData}>{sessionsData}</div>
                                </div>
                                <div className="interpre-container">
                                    <div className="d-flex aganta-type">
                                        <label className="custom-checkbox">
                                            <input type="checkbox" checked={value.two_way} readOnly />
                                            <span className="checkmark"></span>
                                        </label>
                                        <div className="checkbox-label">Two way allowed</div>
                                    </div>
                                </div>
                                <div className="interpre-container">
                                    <div className="d-flex aganta-type">
                                        <label className="custom-checkbox">
                                            <input type="checkbox" checked={value.show_video} readOnly />
                                            <span className="checkmark"></span>
                                        </label>
                                        <div className="checkbox-label">Show Interpreter Video</div>
                                    </div>
                                </div>
                                {this.eventID !== undefined && this.eventID !== null && this.eventID !== "" && (
                                    <div className="interpre-del-blk">
                                        <div className="d-flex align-items-center justify-content-center room-del-btn" onClick={() => this.editInterpreter(value)}><img src={require("../../img/edit-pencil.svg")} alt="edit interpreter" /></div>
                                    </div>
                                )}
                                <div className="interpre-del-blk">
                                    <div className="d-flex align-items-center justify-content-center room-del-btn" onClick={() => this.deleteAssignment(value._id, key)}><img src={require("../../img/trash.svg")} alt="delete interpreter" /></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="d-flex align-items-center btn-acn-blk">
                    <button type="button" onClick={() => this.props.stepToAgenda()} className="prev-btn">Prev</button>
                    <button type="button" disabled={this.state.assignmentDetails.length > 0 ? false : true} onClick={() => this.props.stepToParticipants(this.state.updateUsers)} className="next-btn">Next</button>
                </div>
                <Modal isOpen={this.state.isModalOpen} fade={true} centered className={'interpreter-modal-dialog'}>
                    <Formik
                        initialValues={this.initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.submitAssignment}
                    >
                        {this.assignmentForm}
                    </Formik>
                </Modal>
                <Modal isOpen={this.state.isViewEventModalOpen} fade={true} centered className={'interpreter-event-view-modal-dialog'}>
                    <div className="">
                        <div className="intrepreter-popup">
                            <div className="d-flex justify-content-end popup-close-btn" onClick={() => this.viewEventToggleModal()}>
                                &times;
		                    </div>
                            <div className="popup-content">
                                <div className="lang-header mt10">View Event Material</div>
                                <div className="material-content-wrapper">
                                    {this.state.eventMaterials.length > 0 ?
                                        this.state.eventMaterials.map((material, key) => {
                                            console.log('material', material);

                                            return (
                                                <div key={key} className="d-flex align-items-center justify-content-between material-content-container">
                                                    <div className="mat-icon-blk">
                                                        <img src={helper.renderResourceIcon(material.extension)} alt="resourceIcon" />
                                                    </div>
                                                    <div className="mat-file-name" onClick={() => this.viewFiles(material)}>{material.originalFilename}</div>
                                                    <div className="d-flex align-items-center justify-content-center material-del-btn">
                                                        <img src={require("../../img/trash.svg")} alt="trash" onClick={() => this.deleteMaterial(material._id, key)} />
                                                    </div>
                                                </div>
                                            )
                                        })

                                        : <div className="norecordsEvent">No records available</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.isAddEventModalOpen} fade={true} centered className={'single-modal-content'}>
                    <div className="">
                        <div className="">
                            <div className="d-flex justify-content-end popup-close-btn" onClick={() => this.addEventToggleModal()}>
                                &times;
                            </div>
                            <div className="popup-content">
                                <div className="lang-header mt10">Add Event Material</div>
                                <div className="d-flex justify-content-center align-items-center flex-column material-upload-session">
                                    <div className="mat-upload-icon">
                                        <img src={require("../../img/upload.svg")} alt="upload" />
                                    </div>
                                    <div className="seperator-mat">or</div>
                                    <div className="add-mater-blk">
                                        <label className="add-mat-btn"><input type="file" className="d-none" onChange={this.onChangeHandler} />Browse</label>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="mat-info">!</div>
                                    <div className="mat-file-types">File formats should be .pdf, .doc, .docx, .xls, .xlsx or .ppt</div>
                                </div>
                                {this.state.selectedFile !== "" &&
                                    <div className="d-flex align-items-center">
                                        <div className="mat-file-types"> {this.state.selectedFile.name}</div>
                                    </div>}
                                <span className="mat-file-types text-danger">{this.state.fileFormatErrorMsg}</span>
                                <div className="d-flex justify-content-center modal-btn-blk interpre-btn-blk">
                                    <button type="button" className="modal-cancel-btn" onClick={() => this.addEventToggleModal()}>Cancel</button>
                                    <button type="button" className="modal-save-btn" onClick={this.onClickHandler} disabled={this.state.disableSaveMaterialBtn}>
                                        {this.state.disableSaveMaterialBtn === true ? 'Saving...' : 'Save'}
                                        {this.state.disableSaveMaterialBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
