import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import api from "../../service/api.service";
import Swal from 'sweetalert2';
import jwt from "jwt-simple";
import Header from "../includes/header";
import { Box, Container } from '@material-ui/core';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordSection: false,
            emailSection: true,
            emailID: '',
            errorMessage: '',
            disableBtn: false,
        }
    }

    componentDidMount() {
        if (localStorage.getItem('userDetails') !== null && localStorage.getItem('userDetails') !== "") {
            this.props.history.push('/events');
        } else {
            this.props.history.push('/');
        }
        console.log('####### == BUILD ID jan 19 435435 ==###')
    }


    validateUserEmail = (email) => {
        let emailDetails = {
            email: email.toLowerCase()
        }
        api.checkEmailAlreadyExists(emailDetails).then((data) => {
            if (data && data !== undefined && data !== null && data !== "") {
                if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                    if (data.data && data.data !== undefined) {
                        if (data.data.alreadyExists && data.data.alreadyExists === 1) {
                            // this.setState({ emailID: email, disableBtn: false, passwordSection: true, emailSection: false });
                            this.setState({ emailID: email }, () => {
                                let password = document.getElementById("rafiky-password").value;
                                this.validatePassword(password)
                            });

                        } else {
                            this.setState({ disableBtn: false });
                            this.setState({ errorMessage: 'You are not a registered user!' })
                        }
                    }
                } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Unauthorized Access"
                    })
                } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                    Toast.fire({
                        icon: 'warning',
                        title: "Please revalidate the form and submit"
                    })
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something went wrong. Please try again!"
                    })
                }
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: "No response from the server. Please try again!"
                })
            }

        });
    }

    validatePassword = (password) => {

        if (this.state.emailID !== '' && password !== "") {
            let passwordDetails = {
                password: password,
                email: this.state.emailID
            }
            api.loginwithPassword(passwordDetails).then((data) => {
                if (data && data !== undefined && data !== null && data !== "") {
                    if (data.status && data.status !== undefined && data.status !== null && data.status === 200) {
                        this.setState({ disableBtn: false });
                        if (data.data.status === true) {
                            this.setState({ errorMessage: 'Logged in' });
                            let loggedinUserDetails = data.data.data;
                            loggedinUserDetails = jwt.encode(loggedinUserDetails, process.env.REACT_APP_JWT_SECRET, 'HS512');
                            window.localStorage.setItem('userDetails', JSON.stringify(loggedinUserDetails));
                            localStorage.removeItem("eventCodeUser");
                            this.props.history.push("/events");
                        } else {
                            this.setState({ errorMessage: 'Invalid Password' });
                        }
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 401) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Unauthorized Access"
                        })
                    } else if (data.status && data.status !== undefined && data.status !== null && data.status === 422) {
                        Toast.fire({
                            icon: 'warning',
                            title: "Please revalidate the form and submit"
                        })
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something went wrong. Please try again!"
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: "No response from the server. Please try again!"
                    })
                }
            });
        } else {
            this.setState({ errorMessage: 'Something went wrong.Please try again' });
        }
    }

    submitLogin = () => {
        this.setState({ errorMessage: '' })
        if (this.state.emailSection === true && this.state.passwordSection === false) {
            let email = document.getElementById("rafiky-email").value;
            let password = document.getElementById("rafiky-password").value;
            email = email.trim();
            if (email !== undefined && email !== null && email !== "" && password !== undefined && password !== null && password !== "") {
                let lastAtPos = email.lastIndexOf('@');
                let lastDotPos = email.lastIndexOf('.');
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                    this.setState({
                        errorMessage: 'Please enter valid email id'
                    })
                } else {
                    this.setState({ disableBtn: true, errorMessage: '' });
                    this.validateUserEmail(email);
                }
            } else {
                if (email === undefined || email === null || email === "") {
                    this.setState({ errorMessage: 'Email field is required' })
                } else if (password === undefined || password === null || password === "") {
                    this.setState({ errorMessage: 'Password field is required' })
                }

            }
        } else if (this.state.passwordSection === true) {
            let password = document.getElementById("rafiky-password").value;
            password = password.trim();
            if (password !== null && password !== "" && password !== undefined) {
                this.setState({ disableBtn: true, errorMessage: '' });
                this.validatePassword(password);

            } else {
                this.setState({ errorMessage: 'Password is mandatory' })
            }
        }
    }

    render() {
        return (
            <section className="scheduller-session">
                <Header />
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    justifyContent="center"
                >
                    <Container maxWidth="sm">
                        <div style={{ 'padding': '35px' }}>
                            <div className="d-flex event-gen-set flex-column">
                                <div className="formgrp-txt-wrapper">
                                    <label htmlFor="email" className="formgrp-label">Email</label>
                                    <input name="email" id="rafiky-email" type="text" className='form-input' />
                                </div>
                                <div className="formgrp-txt-wrapper">
                                    <label htmlFor="password" className="formgrp-label">Password</label>
                                    <input name="password" id="rafiky-password" type="password" className='form-input' />
                                </div>
                            </div>
                            {this.state.errorMessage !== "" &&
                                <div className="validtxt_msg">{this.state.errorMessage}</div>
                            }
                            <div className="form-group" style={{ 'paddingTop': '35px' }}>
                                <button type="button" onClick={() => this.submitLogin()} className="next-btn" disabled={this.state.disableBtn}>
                                    {this.state.disableBtn === true ? 'Logging In...' : 'LOGIN'}
                                    {this.state.disableBtn === true ? <ClipLoader size={15} color={"#fff"} loading={true} /> : ''}
                                </button>
                            </div>
                        </div>
                    </Container>
                </Box>
            </section>
        )
    }
}